import React, { useEffect, useState, useContext } from "react";
import CouponAdd from "./Form";
import CouponList from "./List";
import { resetFunction } from "../../store/coupon/couponState";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DispatchContext } from "../../context/AppProvider";

const CouponIndex = () => {
  const stateData = useLocation();
  const [pageType, setPageType] = useState("list");
  const dispatch = useDispatch();

  const createSuccess = useSelector((state) => state.Coupon.createCouponStatus);
  const createError = useSelector(
    (state) => state.Coupon.createCouponStatusError
  );
  const updateSuccess = useSelector((state) => state.Coupon.updateCouponStatus);
  const updateError = useSelector(
    (state) => state.Coupon.updateCouponStatusError
  );

  const contextDispatch = useContext(DispatchContext);

  useEffect(() => {
    contextDispatch({
      type: "header_text",
      payload: {
        title: "Discount Management",
      },
    });
  }, []);

  useEffect(() => {
    if (stateData.state !== null) {
      if (stateData.state.pageFrom === "sidebar") {
        setPageType("list");
      } else if (stateData.state.pageFrom === "dashOrderId") {
        setPageType("view");
      }
    }
  }, [stateData]);

  useEffect(() => {
    let msg = "";
    if (createSuccess && createSuccess.success === true) {
      msg = createSuccess.message;
    }
    if (updateSuccess && updateSuccess.success === true) {
      msg = updateSuccess.message;
    }
    if (msg !== "") {
      toast(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
      
    }
    setPageType("list");
  }, [createSuccess, dispatch, updateSuccess]);

  useEffect(() => {
    let msg = "";
    if (createError && createError.data && createError.data.success === false) {
      msg = createError.data.message;
    }
    if (updateError && updateError.data && updateError.data.success === false) {
      msg = updateError.data.message;
    }
    if (msg !== "") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [createError, dispatch, updateError]);

  const renderPages = () => {
    switch (pageType) {
      case "list":
        return <CouponList setPageType={setPageType} />;
      case "add":
        return <CouponAdd setPageType={setPageType} />;
      case "edit":
        return <CouponAdd setPageType={setPageType} />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <div className="page">{renderPages()}</div>
      <ToastContainer />
    </React.Fragment>
  );
};
export default CouponIndex;
