import { Col, Dropdown, Layout, Row, Space, theme } from "antd";

import { StateContext } from "../context/AppProvider";
import { useContext, useState } from "react";
import { userLogout } from "../store/auth/login/loginState";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "./MenuItems";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { getCurrentDate } from "../utils";
import Translate from "../pages/Translate/select";// for google translate
import { useTranslation } from 'react-i18next';
const { Header, Content } = Layout;

const App = () => {
  
const { t } = useTranslation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const dispatch = useDispatch();
  let history = useNavigate();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [mediaSize, setMediaSize] = useState("big");
  const login = useSelector((state) => state.Login.logData);
  const data = useSelector((state) => state.Login.logData);
  const contData = useContext(StateContext);
  const header = contData.headerText;

  let token = false;
  if (data && data.success === true) {
    token = data.vendor.token;
  }
  let auth = { token: token };

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      dispatch(userLogout());
      history("/");
    }
  };

  const items = [
    {
      label: t('Logout'),
      key: "1",
    },
  ];    
  
  return auth.token ? (
    <Layout hasSider>
    
      <Sidebar
        menuCollapse={menuCollapse}
        setMenuCollapse={setMenuCollapse}
        mediaSize={mediaSize}
        setMediaSize={setMediaSize}
      />

      <Layout
        className={
          mediaSize === "small" && menuCollapse === false ? "site-display site-layout" : "site-layout"
        }
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        ><div id="google_translate_element"></div>
          <Row className="header-page">
            <Col xs={0} sm={0} md={12} lg={12} xl={12}>
              <h1>{t(header.title)}</h1>
              <p>{getCurrentDate()}</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="compage-items">
                <Translate></Translate>{/*  for google translate */}
                <Dropdown
                  menu={{
                    items,
                    onClick: handleMenuClick,
                  }}
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <p className="user-name">
                        Hi {login.vendor ? login.vendor.name : ""}
                      </p>
                      <figure className="src-avatar"></figure>
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            // margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              textAlign: "center",
              background: colorBgContainer,
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};
export default App;
