import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardListOrder,
  resetFunction,
} from "../../store/order/orderState";
//Toastify
import { toast } from "react-toastify";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { DispatchContext } from "../../context/AppProvider";
import { Fragment } from "react";
import { Card, Col, Row } from "antd";
import { Table } from "reactstrap";
import { useTranslation } from 'react-i18next';

const Dashboard = ({ setPageType }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const contextDispatch = useContext(DispatchContext);

  const [customerName, setCustomerName] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [date, setDate] = useState("");
  const orderList = useSelector((state) => state.Order.dashboardOrderList);
  const deleteOrderSuccess = useSelector(
    (state) => state.Order.deleteOrderSuccess
  );
  const listDriverSuccess = useSelector((state) => state.Driver.driverList);
  const deleteOrderError = useSelector((state) => state.Order.deleteOrderError);
  const { t } = useTranslation();
  useEffect(() => {
    if (deleteOrderSuccess && deleteOrderSuccess.success === true) {
      toast(deleteOrderSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteOrderSuccess, dispatch]);

  useEffect(() => {
    if (
      deleteOrderError &&
      deleteOrderError.data &&
      deleteOrderError.data.success === false
    ) {
      toast.error(deleteOrderError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteOrderError, dispatch]);

  useEffect(() => {
    dispatch(dashboardListOrder());
  }, [customerName, date, deleteOrderSuccess, dispatch, orderNumber]);

  useEffect(() => {
    dispatch(dashboardListOrder());
  }, []);

  const createSuccess = useSelector((state) => state.Order.createOrderStatus);
  const createError = useSelector(
    (state) => state.Order.createOrderStatusError
  );
  const updateStatusSuccess = useSelector(
    (state) => state.Order.updateOrderStatus
  );
  const updateStatusError = useSelector(
    (state) => state.Order.updateOrderStatusError
  );
  const updateDriverSuccess = useSelector(
    (state) => state.Order.updateOrderDriver
  );
  const updateDriverError = useSelector(
    (state) => state.Order.updateOrderDriverError
  );

  useEffect(() => {
    let msg = "";
    if (createSuccess && createSuccess.success === true) {
      msg = createSuccess.message;
    }
    if (updateStatusSuccess && updateStatusSuccess.success === true) {
      msg = updateStatusSuccess.message;
    }
    if (updateDriverSuccess && updateDriverSuccess.success === true) {
      msg = updateDriverSuccess.message;
    }
    if (msg !== "") {
      toast(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
      dispatch(dashboardListOrder());
      setPageType("list");
    }
  }, [
    createSuccess,
    updateStatusSuccess,
    updateDriverSuccess,
    dispatch,
    customerName,
    orderNumber,
    date,
    setPageType,
  ]);

  useEffect(() => {
    let msg = "";
    if (createError && createError.data && createError.data.success === false) {
      msg = createError.data.message;
    }
    if (
      updateStatusError &&
      updateStatusError.data &&
      updateStatusError.data.success === false
    ) {
      msg = updateStatusError.data.message;
    }
    if (
      updateDriverError &&
      updateDriverError.data &&
      updateDriverError.data.success === false
    ) {
      msg = updateDriverError.data.message;
    }
    if (msg !== "") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [createError, dispatch, updateStatusError]);

  const [selectDriverOptions, setSelectDriverOptions] = useState([]);
  useEffect(() => {
    if (
      listDriverSuccess &&
      listDriverSuccess &&
      listDriverSuccess.success === true
    ) {
      let data = listDriverSuccess.data;
      const driverOptions = data.map((item) => ({
        label: item.name, // <-- input values you are matching
        value: item._id,
      }));
      setSelectDriverOptions(driverOptions);
    }
  }, [dispatch, listDriverSuccess]);
  return (
    <Fragment>
      <div className="hd-sec">
        <Row className="dashboard-page">
          <Row className="dashboard-head">
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              onClick={() => {
                contextDispatch({
                  type: "order_filter",
                  payload: {
                    customActiveTab: "1",
                    orderStatus: [
                      "pending",
                      "packed",
                      "outForDelivery",
                      "delivered",
                      "cancelled",
                    ],
                    customerName: "",
                    fromDate: null,
                    month: null,
                    orderNumber: "",
                    toDate: null,
                    driver: { label: "Select Driver", value: "" },
                  },
                });
                contextDispatch({
                  type: "dashboard_order",
                  payload: {
                    isLoad: true,
                  },
                });
                history("../order", { replace: true });
              }}
            >
              <div className="ech-count total-orders-bg-color">
                <p className="count-no total-orders-color">
                  {orderList && orderList.data && orderList.data.totalCount}
                </p>
                <p className="count-name total-orders-color">{t('TOTAL ORDERS')}</p>
              </div>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              onClick={() => {
                contextDispatch({
                  type: "order_filter",
                  payload: {
                    customActiveTab: "2",
                    orderStatus: ["packed", "outForDelivery"],
                    customerName: "",
                    fromDate: null,
                    month: null,
                    orderNumber: "",
                    toDate: null,
                    driver: { label: "Select Driver", value: "" },
                  },
                });
                contextDispatch({
                  type: "dashboard_order",
                  payload: {
                    isLoad: true,
                  },
                });
                history("../order", { replace: true });
              }}
            >
              <div className="ech-count On-going-bg-clr">
                <p className="count-no othr-crds-color">
                  {orderList && orderList.data && orderList.data.ongoingCount}
                </p>
                <p className="count-name othr-crds-color">{t('ON-GOING ORDERS')}</p>
              </div>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              onClick={() => {
                contextDispatch({
                  type: "order_filter",
                  payload: {
                    customActiveTab: "3",
                    orderStatus: ["pending"],
                    customerName: "",
                    fromDate: null,
                    month: null,
                    orderNumber: "",
                    toDate: null,
                    driver: { label: "Select Driver", value: "" },
                  },
                });
                contextDispatch({
                  type: "dashboard_order",
                  payload: {
                    isLoad: true,
                  },
                });
                history("../order", { replace: true });
              }}
            >
              <div className="ech-count Pending-ordr-bg-clr">
                <p className="count-no othr-crds-color">
                  {orderList && orderList.data && orderList.data.pendingCount}
                </p>
                <p className="count-name othr-crds-color">{t('PENDING ORDERS')}</p>
              </div>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              onClick={() => {
                contextDispatch({
                  type: "order_filter",
                  payload: {
                    customActiveTab: "4",
                    orderStatus: ["delivered"],
                    customerName: "",
                    fromDate: null,
                    month: null,
                    orderNumber: "",
                    toDate: null,
                    driver: { label: "Select Driver", value: "" },
                  },
                });
                contextDispatch({
                  type: "dashboard_order",
                  payload: {
                    isLoad: true,
                  },
                });
                history("../order", { replace: true });
              }}
            >
              <div className="ech-count orders-cmplted-bg-clr">
                <p className="count-no othr-crds-color">
                  {orderList && orderList.data && orderList.data.completedCount}
                </p>
                <p className="count-name othr-crds-color">{t('COMPLETED ORDERS')}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Table borderless>
              <thead>
                <tr>
                  <th>{t('Sl No')}</th>
                  <th>{t('Customer Name')}</th>
                  <th>{t('Vendor Name')}</th>
                  {/* <th>Delivery Time</th> */}
                  <th>{t('Delivery Date')}</th>
                  <th>{t('Delivery Area')}</th>
                  <th>{t('Driver')}</th>
                  <th>{t('Order ID')}</th>
                  <th>{t('Delivery Status')}</th>
                </tr>
              </thead>
              <tbody>
                {orderList &&
                orderList.data &&
                orderList.data.order &&
                Object.keys(orderList.data.order).length > 0 ? (
                  orderList.data.order.map((sec, i) => {
                    let v = "";
                    if (sec.status.toLowerCase() === "pending")
                      v = "st-pending";
                    else if (sec.status.toLowerCase() === "packed")
                      v = "st-on-going";
                    else if (sec.status.toLowerCase() === "outfordelivery")
                      v = "st-on-going";
                    else if (sec.status.toLowerCase() === "delivered")
                      v = "st-completed";
                    else if (sec.status.toLowerCase() === "cancelled")
                      v = "st-cancelled";
                    return (
                      <tr key={i}>
                        <td className="td-white">{i + 1}</td>
                        <td className="c-table__cell td-white">
                          {sec.customerName}
                        </td>
                        <td>{sec.vendor && sec.vendor.name}</td>
                        {/* <td className="c-table__cell td-white">
                          {sec.deliverySlot}
                        </td> */}
                        <td className="c-table__cell td-white">
                          {moment(sec.deliveryDate).format("DD-MM-YYYY")}
                        </td>
                        <td className="c-table__cell td-white">
                          {sec.dropoffAddress &&
                            sec.dropoffAddress.locationName + ","}
                          {sec.dropoffAddress &&
                            sec.dropoffAddress.buildingNumber}
                          <br />
                          {sec.dropoffAddress &&
                            sec.dropoffAddress.streetNumber + ","}
                          {/* {sec.dropoffAddress &&
                            sec.dropoffAddress.municipality + ","} */}
                          {sec.dropoffAddress && sec.dropoffAddress.zoneNumber}
                        </td>
                        <td>{sec && sec.driver && sec.driver.name}</td>
                        <td className="td-white">
                          <Link
                            to={"/order"}
                            state={{ pageFrom: "dashOrderId" }}
                            onClick={() => {
                              contextDispatch({
                                type: "order_edit",
                                payload: sec,
                              });
                              contextDispatch({
                                type: "dashboard_order",
                                payload: {
                                  isLoad: true,
                                },
                              });
                            }}
                          >
                            {sec.orderNumber}
                          </Link>
                        </td>
                        <td>
                          <div className={`${v} status-box`}>{sec.status}</div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>{t("No Records Found")}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
        </Row>
      </div>
    </Fragment>
  );
};

export default Dashboard;
