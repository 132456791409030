import React, { useContext, useEffect, useState } from "react";
import OrderAdd from "./Form";
import OrderList from "./List";
import OrderView from "./View";
import { resetFunction } from "../../store/order/orderState";
import { useDispatch, useSelector } from "react-redux";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetPaymentStatus } from "../../store/common/commonState";
import { useLocation } from "react-router";
import { DispatchContext, StateContext } from "../../context/AppProvider";
import { useTranslation } from 'react-i18next';

const OrderIndex = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateData = useLocation();
  const contextDispatch = useContext(DispatchContext);
  const [pageType, setPageType] = useState("");
  const [urlFrom, setUrlFrom] = useState(
    stateData.pathname === "/order"
      ? "main"
      : stateData.pathname === "/driver/order" && "sub"
  );
  const paymentSts = useSelector((state) => state.Common.paymentStatus);

  const orderSuccess = useSelector((state) => state.Order.createOrderStatus);
  const orderError = useSelector((state) => state.Order.createOrderStatusError);

  const orderUpdateSuccess = useSelector(
    (state) => state.Order.updateOrderSuccess
  );
  const orderUpdateError = useSelector((state) => state.Order.updateOrderError);
  const contData = useContext(StateContext);
  const orderEditData = contData.orderEditData;

  useEffect(() => {
    dispatch(resetPaymentStatus());
    dispatch(resetFunction());
    contextDispatch({
      type: "dashboard_order",
      payload: {
        isLoad: false,
      },
    });
    contextDispatch({
      type: "header_text",
      payload: {
        title: "Order Management",
      },
    });
  }, []);

  useEffect(() => {
    if (stateData.state !== null) {
      if (stateData.state.pageFrom === "sidebar") {
        setPageType("list");
      } else if (stateData.state.pageFrom === "dashOrderId") {
        if (Object.keys(orderEditData).length > 0) {
          setPageType("view");
        } else {
          setPageType("list");
        }
      }
    } else {
      setPageType("list");
    }

    if (stateData.pathname === "/driver/order") {
      setUrlFrom("sub");
    } else if (stateData.pathname === "/order") {
      setUrlFrom("main");
    }
  }, [stateData]);

  useEffect(() => {
    if (Object.keys(paymentSts).length > 0) {
      let msg = "Payment Failed";
      if (paymentSts.data && Object.keys(paymentSts.data).length > 0) {
        let sts = paymentSts.data.status;
        msg =
          "Payment " + sts.charAt(0).toUpperCase() + sts.slice(1).toLowerCase();
      }
      toast(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [paymentSts]);

  useEffect(() => {
    if (
      orderSuccess &&
      orderSuccess.success === true &&
      orderSuccess.message === "Payment url"
    ) {
      window.location.assign(orderSuccess.url);
    } else if (orderSuccess && orderSuccess.success === true) {
      toast(orderSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
      setPageType("list");
    } else if (orderUpdateSuccess && orderUpdateSuccess.success === true) {
      toast(orderUpdateSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
      setPageType("list");
    }
  }, [orderSuccess, orderUpdateSuccess]);

  useEffect(() => {
    if (orderError && orderError.data && orderError.data.success === false) {
      toast.error(orderError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    } else if (
      orderUpdateError &&
      orderUpdateError.data &&
      orderUpdateError.data.success === false
    ) {
      toast.error(orderUpdateError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [orderError, orderUpdateError]);

  const renderPages = () => {
    switch (pageType) {
      case "list":
        return <OrderList setPageType={setPageType} urlFrom={urlFrom} />;
      case "add":
        return <OrderAdd setPageType={setPageType} urlFrom={urlFrom} />;
      case "edit":
        return <OrderAdd setPageType={setPageType} urlFrom={urlFrom} />;
      case "view":
        return <OrderView setPageType={setPageType} urlFrom={urlFrom} />;
      default:
        return null;
    }
  };
  return (
    <React.Fragment>
      <div className="page">{renderPages()}</div>
      <ToastContainer />
    </React.Fragment>
  );
};
export default OrderIndex;
