// import axios from "axios";
// const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

import axiosInstance from "../ApiHelper";

class walletService {
  constructor(callback) {
    this.callback = callback;
  }

  async walletRechargeOnline(payload) {
    // const headers = {
    //   Authorization:
    //     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVU0VSX0lEIjoiNjNiMmRlOGE2NDdiYjVmZTY3ZWVmZjY1IiwiaWF0IjoxNjc1MDY1NjU4fQ.LcsuEcjzxAiQqVENe-UTm2TG-3g5YhbJ0Ks7OlAR5dQ",
    // };
    const response = await axiosInstance
      .post(`vendor/charge`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async walletStatementHistory(payload) {
    // const headers = {
    //   Authorization:
    //     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVU0VSX0lEIjoiNjNiMmRlOGE2NDdiYjVmZTY3ZWVmZjY1IiwiaWF0IjoxNjc1MDY1NjU4fQ.LcsuEcjzxAiQqVENe-UTm2TG-3g5YhbJ0Ks7OlAR5dQ",
    // };
    const response = await axiosInstance
      .post(`vendor/wallet/history`,payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }
}

export default new walletService();
