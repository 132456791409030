import { all, fork } from "redux-saga/effects";
import AuthSaga from "./auth/login/loginSaga";
import VendorSaga from "./vendor/vendorSaga";
import DriverSaga from "./driver/driverSaga";
import CommonSaga from "./common/commonSaga";
import CustomerSaga from "./customer/customerSaga";
import CouponSaga from "./coupon/couponSaga";
import OrderSaga from "./order/orderSaga";
import WalletSaga from "./wallet/walletSaga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    VendorSaga(),
    CommonSaga(),
    CustomerSaga(),
    CouponSaga(),
    DriverSaga(),
    OrderSaga(),
    WalletSaga(),
  ]);
}
