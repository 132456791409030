import React, { useEffect, Fragment, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import filterImg from "../../assets/images/filter-icon.png";
import deleteImg from "../../assets/images/Delete.svg";
import editImg from "../../assets/images/Edit.svg";
import searchImg from "../../assets/images/search.svg";
import {
  listCustomer,
  deleteCustomer,
  updateCustomer,
  resetFunction,
  listCustomerByVendor,
} from "../../store/customer/customerState";
//Toastify
import { toast } from "react-toastify";
import { DispatchContext } from "../../context/AppProvider";
import { Button, Col, Row } from "antd";
import { Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import AsyncSelect from "react-select/async";
import { useTranslation } from 'react-i18next';

const CustomerList = ({ setPageType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch(),
    contextDispatch = useContext(DispatchContext);
  const [custSearch, setCustSearch] = useState("");
  const [vendorSelect, setVendorSelect] = useState({
    label: t("Select Vendor"),
    value: "",
  });
  const [selectVendorOptions, setSelectVendorOptions] = useState([]);
  const customerList = useSelector((state) => state.Customer.customerList);
  const customerError = useSelector((state) => state.Customer.customerError);
  const customerVendorList = useSelector(
    (state) => state.Customer.customerByVendorList
  );
  const deleteCustomerSuccess = useSelector(
    (state) => state.Customer.deleteCustomerSuccess
  );
  const deleteCustomerError = useSelector(
    (state) => state.Customer.deleteCustomerError
  );
  const vendorLists = useSelector((state) => state.Vendor.vendorList);
  const login = useSelector((state) => state.Login.logData);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;

  let currentItems = [];
  let pageCount = 0;
  let slCount = 0;
  if (login.vendor && login.vendor.is_admin === true) {
    if (
      customerList &&
      customerList.data !== undefined &&
      Object.keys(customerList.data).length > 0
    ) {
      currentItems = customerList.data.slice(itemOffset, endOffset);

      pageCount = Math.ceil(customerList.data.length / itemsPerPage);
    }
  } else if (login.vendor && login.vendor.is_admin === false) {
    if (
      customerVendorList &&
      customerVendorList.data !== undefined &&
      Object.keys(customerVendorList.data).length > 0
    ) {
      currentItems = customerVendorList.data.slice(itemOffset, endOffset);
      pageCount = Math.ceil(customerVendorList.data.length / itemsPerPage);
    }
  }
  if (Object.keys(currentItems).length > 0) {
    const index = customerList.length>0 && customerList.data.findIndex((itm) => {
      return itm._id === currentItems[0]._id;
    });
    slCount = index;
  }

  useEffect(() => {
    if (deleteCustomerSuccess && deleteCustomerSuccess.success === true) {
      toast(deleteCustomerSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteCustomerSuccess, dispatch]);

  useEffect(() => {
    if (
      deleteCustomerError &&
      deleteCustomerError.data &&
      deleteCustomerError.data.success === false
    ) {
      toast.error(deleteCustomerError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteCustomerError, dispatch]);

  useEffect(() => {
    {
      login.vendor && login.vendor.is_admin === true
        ? dispatch(listCustomer())
        : dispatch(listCustomerByVendor({ vendor: login.vendor.id }));
    }
  }, [deleteCustomerSuccess, dispatch]);

  useEffect(() => {
    if (
      customerError &&
      customerError.data &&
      customerError.data.success === false
    ) {
      toast.error(customerError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [dispatch, customerError]);

  useEffect(() => {
    if (vendorLists && vendorLists && vendorLists.success === true) {
      let data = vendorLists.data;
      const filteredData = data.filter((item) => item.status === true);
      const vendorOptions = filteredData.map((item) => ({
        label: item.name, // <-- input values you are matching
        value: item._id,
      }));
      setSelectVendorOptions(vendorOptions);
    }
  }, [dispatch, vendorLists]);

  const handleSearchOnChange = (search, type) => {
    if (type === "search") {
      setCustSearch(search);
      if (search !== "") {
        if (search.length >= 0) {
          let payload = { keyword: search, vendor: vendorSelect.value };
          dispatch(listCustomer(payload));
        }
      } else {
        dispatch(listCustomer({ keyword: "", vendor: vendorSelect.value }));
      }
    } else if (type === "vendor") {
      setVendorSelect(search);
      let payload = { keyword: custSearch, vendor: search.value };
      dispatch(listCustomer(payload));
    }
  };

  const deleteCustomerAction = (id) => {
    dispatch(deleteCustomer(id));
  };

  const handleStatusChange = (id, status) => {
    let pLoad = { status: status };
    let payload = { customerId: id, data: pLoad };
    dispatch(updateCustomer(payload));
  };

  const handlePageClick = (event) => {
    let newOffset = 0;
    if (
      customerList &&
      customerList.data !== undefined &&
      Object.keys(customerList.data).length > 0
    ) {
      newOffset = (event.selected * itemsPerPage) % customerList.data.length;
    }
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const loadVendorOptions = (inputValue) => {
    var promise = new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve(filterVendors(inputValue));
      }, 1000);
    });
    return promise;
  };

  const filterVendors = (inputValue) => {
    return selectVendorOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleBtnReset = () => {
    setCustSearch("");
    setVendorSelect({
      label: t("Select Vendor"),
      value: "",
    });
    let payload = { keyword: "", vendor: "" };
    dispatch(listCustomer(payload));
  };

  let tblCount = 0;

  return (
    <Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          <Row className="common-head">
            <p className="head-p">{t('All Customers')}</p>
            <Button
              onClick={() => {
                contextDispatch({
                  type: "customer_edit",
                  payload: {},
                });
                setPageType("add");
              }}
              type="button"
              className="top-btn btn form-btn bgcolor1 ripple1"
            >
              {t('ADD NEW CUSTOMER')}
            </Button>
          </Row>
          <Row className="common-filter">
            <Col span={24}>
              <Row className="cntnt-top">
                <Col xs={4} sm={4} md={2} lg={2} xl={2}>
                  <div className="filter-by">
                    <img src={filterImg} alt="" />
                    <p>{t('Filter by')}</p>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div className="filter-coulmn big-srch">
                    <img alt="" className="srch-icn" src={searchImg} />
                    <input
                      className="filter-input"
                      type="text"
                      placeholder={t('Search')}
                      value={custSearch}
                      onChange={(e) =>
                        handleSearchOnChange(e.target.value, "search")
                      }
                    />
                  </div>
                </Col>
                {login.vendor && login.vendor.is_admin === true && (
                  <>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div className="filter-coulmn select-driver">
                        <AsyncSelect
                          value={vendorSelect}
                          onChange={(selected) =>
                            handleSearchOnChange(selected, "vendor")
                          }
                          loadOptions={loadVendorOptions}
                        />
                      </div>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                      <div className="flex">
                        <Button
                          onClick={() => {
                            handleBtnReset();
                          }}
                          type="button"
                          className="ins-btn btn form-btn bgcolor1 ripple1"
                        >
                          {t('Reset')}
                        </Button>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            <Table borderless>
              <thead>
                <tr>
                  <th>{t('Sl No')}</th>
                  <th>{t('Name')}</th>
                  <th>{t('Phone')}</th>
                  {login.vendor && login.vendor.is_admin === true && (
                    <th>{t('Vendor')}</th>
                  )}
                  <th>{t('Address')}</th>
                  <th className="action-head">{t('Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {currentItems ? (
                  currentItems.map((sec, i) => {
                    slCount += 1;
                    return (
                      <tr key={i}>
                        <td className="td-white">{slCount}</td>
                        <td className="c-table__cell td-white">{sec.name}</td>
                        <td className="c-table__cell td-white">{sec.phone}</td>
                        {login.vendor && login.vendor.is_admin === true && (
                          <td className="c-table__cell td-white">
                            {sec.vendor && sec.vendor.name}
                          </td>
                        )}
                        <td className="c-table__cell td-white">
                          {/* Location : {sec.address && sec.address.locationName}
                          <br />
                          Building Num :{" "}
                          {sec.address && sec.address.buildingNumber}
                          <br />
                          Street Num : {sec.address && sec.address.streetNumber}
                          <br />
                          Municipality:{" "}
                          {sec.address && sec.address.municipality}
                          <br />
                          Zone Num: {sec.address && sec.address.zoneNumber} */}
                          {sec.address && sec.address.locationName + ","}
                          {sec.address && sec.address.buildingNumber}
                          <br />
                          {sec.address && sec.address.streetNumber + ","}
                          {/* {sec.address && sec.address.municipality + ","} */}
                          {sec.address && sec.address.zoneNumber}
                        </td>
                        <td className="c-table__cell td-white">
                          <div className="actions">
                            {/* <span className="blue-eye material-icons">
                        visibility
                      </span> */}
                            <span className="black-edit material-icons">
                              <img
                                src={editImg}
                                alt=""
                                onClick={() => {
                                  contextDispatch({
                                    type: "customer_edit",
                                    payload: sec,
                                  });
                                  setPageType("edit");
                                }}
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                            {/* <span className="black-edit material-icons">
                                {sec.status === true ? (
                                  <img
                                    src={cancelImg}
                                    alt=""
                                    onClick={() => {
                                      handleStatusChange(sec._id, false);
                                    }}
                                    title="{t('DeActivate')}"
                                    style={{
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={tickImg}
                                    alt=""
                                    onClick={() => {
                                      handleStatusChange(sec._id, true);
                                    }}
                                    title="Activate"
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                              </span> */}
                            <span className="black-edit material-icons">
                              <img
                                alt=""
                                src={deleteImg}
                                onClick={() => {
                                  if (window.confirm("Delete the item?")) {
                                    deleteCustomerAction(sec._id);
                                  }
                                }}
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>{t("No Records Found")}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
          <Row>
            <div className="pagination-div">
              <ReactPaginate
                activeClassName={"item active "}
                breakClassName={"item break-me "}
                breakLabel={"..."}
                containerClassName={"pagination"}
                disabledClassName={"disabled-page"}
                marginPagesDisplayed={2}
                nextClassName={"item next "}
                nextLabel={">>"}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                pageClassName={"item pagination-page "}
                pageRangeDisplayed={5}
                previousClassName={"item previous"}
                previousLabel={"<<"}
                renderOnZeroPageCount={null}
              />
            </div>
          </Row>
        </Row>
      </div>
    </Fragment>
  );
};

export default CustomerList;
