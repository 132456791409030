import React, { Fragment, useEffect, useRef, useState } from "react";
import { Table } from "reactstrap";
import excelImg from "../../assets/images/excel-file.png";
import moment from "moment";
import { downloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import { Button, Col, Row } from "antd";
import { useTranslation } from 'react-i18next';

const StatementView = ({ wallet, login }) => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const onBeforeGetContentResolve = useRef(null);
  const [printTitle, setPrintTitle] = useState(0);
  let header = [];
  if (login.vendor && login.vendor.is_admin === true) {
    header = ["Vendor", "Amount", "Type", "Date"];
  } else {
    header = ["Amount", "Type", "Date"];
  }
  let excel = [];
  const handleDownloadExcel = () => {
    if (wallet && wallet.success === true) {
      let data = wallet.data;
      if (login.vendor && login.vendor.is_admin === true) {
        excel = data.map((item) => ({
          vendor: item.vendor && item.vendor.name,
          amount: item.amount,
          actionType: item.actionType,
          createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
        }));
      } else {
        excel = data.map((item) => ({
          amount: item.amount,
          actionType: item.actionType,
          createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
        }));
      }
      downloadExcel({
        fileName: "wallet",
        sheet: "react-export-table-to-excel",
        tablePayload: {
          header,
          // accept two different data structures
          body: excel,
        },
      });
    }
  };

  useEffect(() => {
    if (
      printTitle === 1 &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, printTitle]);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setPrintTitle(1);
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, []);

  const handleAfterPrint = React.useCallback(() => {
    setPrintTitle(0);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <Fragment>
      <Col span={24} ref={componentRef} className="mb-20">
        {printTitle == 1 && <h5 className="text-center">GODRIWELL</h5>}
        <Table borderless>
          <thead>
            <tr>
              <th>{t("Sl No")}</th>
              {login.vendor && login.vendor.is_admin === true ? (
                <th>{t('Vendor')}</th>
              ) : (
                ""
              )}
              <th>{t("Amount")}</th>
              <th>{t("Type")}</th>
              <th>{t("Date")}</th>
            </tr>
          </thead>
          <tbody>
            {wallet &&
            wallet.data !== undefined &&
            Object.keys(wallet.data).length > 0 ? (
              wallet.data.map((sec, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    {login.vendor && login.vendor.is_admin === true ? (
                      <td>{sec.vendor && sec.vendor.name}</td>
                    ) : (
                      ""
                    )}
                    <td>QAR {sec.amount}</td>
                    <td>{sec.actionType}</td>
                    <td>{moment(sec.createdAt).format("DD-MM-YYYY")}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3}>{t("No Records Found")}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>

      <Col span={24}>
        <div className="page-header">
          <Button className="bgcolor1 mr-25" onClick={handlePrint}>
            {t("Print")}
          </Button>
          <Button
            className="flex outline-btn"
            color="info"
            onClick={handleDownloadExcel}
          >
            <p>
              {t("Download As Excel")}
              <img src={excelImg} alt="" className="ml-5" />
            </p>
          </Button>
        </div>
      </Col>
    </Fragment>
  );
};
export default StatementView;
