import { call, put, takeEvery } from "redux-saga/effects";
import walletService from "../../service/wallet/WalletService";
import {
  walletRechargeOnlineSuccess,
  walletRechargeOnlineError,
  walletStatementHistorySuccess,
  walletStatementHistoryError,
} from "./walletState";

function* walletRechargeOnline(action) {
  const data = yield call(walletService.walletRechargeOnline, action.payload);
  if (data.msg === "success") {
    yield put(walletRechargeOnlineSuccess(data.response));
  } else {
    yield put(walletRechargeOnlineError(data.response));
  }
}

function* walletStatementHistory(action) {
  const data = yield call(walletService.walletStatementHistory, action.payload);
  if (data.msg === "success") {
    yield put(walletStatementHistorySuccess(data.response));
  } else {
    yield put(walletStatementHistoryError(data.response));
  }
}

function* walletSaga() {
  yield takeEvery("wallet/walletRechargeOnline", walletRechargeOnline);
  yield takeEvery("wallet/walletStatementHistory", walletStatementHistory);
}

export default walletSaga;
