import React, { Fragment, useEffect } from "react";
import { Button, Col, Row } from "antd";

//React Hook Form cleanly grabs the input values and returns them in a handleSubmit function.
import { useForm } from "react-hook-form";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Redux
import { getLoginFetch, resetData } from "../../store/auth/login/loginState";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";

import logo from "../../assets/images/logo-login.png";
import { ErrorMessage } from "@hookform/error-message";
import Translate from "../Translate/select"; //for google translate
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  let history = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const dispatch = useDispatch();
  const submitForm = (data) => {
    dispatch(getLoginFetch({ data, history }));
  };

  const apiError = useSelector((state) => state.Login.error);

  useEffect(() => {
    if (apiError && apiError.data && apiError.data.error) {
      toast.error("Invalid credentials!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetData());
    }
  }, [apiError]);

  return (
    <Fragment>
      {/* for google translate
    <div id="google_translate_element"></div> */}
      <Row className="login-main">
        <Col
          xs={24}
          sm={24}
          md={18}
          lg={12}
          xl={12}
          className="login-banner"
        ></Col>
        <Col xs={24} sm={24} md={18} lg={12} xl={12}>
          <Translate></Translate>
          <Row className="inn">
            <figure>
              <img src={logo} alt="logo" />
            </figure>
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="form-group">
                <label>{t("Email")}</label>

                <span className=" src-dashboard"></span>
                <input
                  required
                  type="text"
                  placeholder={t("Email")}
                  {...register("email", {
                    required: "Please enter email.",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <p className="val-error">{message}</p>
                  )}
                />
              </div>
              <div className="form-group">
                <span className=" src-dashboard"></span>
                <label>{t("Password")}</label>
                <input
                  required
                  type="password"
                  placeholder={t("Password")}
                  {...register("password", {
                    required: "Please enter password.",
                  })}
                  autoComplete="on"
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => (
                    <p className="val-error">{message}</p>
                  )}
                />
              </div>
              <div className="terms-and-conditions">
                <input
                  type="checkbox"
                  className="mr-5"
                  {...register("terms", {
                    required:
                      "Accept the Terms and Conditions in order to continue.",
                  })}
                />{" "}
                <NavLink exact="true" to="/termsandconditions">
                  {t("Terms and conditions")}
                </NavLink>
                <br />
              </div>
              <ErrorMessage
                errors={errors}
                name="terms"
                render={({ message }) => <p className="val-error">{message}</p>}
              />
              <Button
                htmlType="submit"
                block
                className="btn login-btn bgcolor1 ripple1"
              >
                {t("Sign In")}
              </Button>
            </form>
          </Row>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default Login;
