import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    createOrderStatus: {},
    createOrderStatusError: {},
    isLoading: false,
    orderList: {},
    orderListError: {},
    updateOrderSuccess: {},
    updateOrderError: {},
    updateOrderStatus: {},
    updateOrderStatusError: {},
    updateOrderDriver: {},
    updateOrderDriverError: {},
    deleteOrderError: {},
    deleteOrderSuccess: {},
    dashboardOrderList: {},
    dashboardOrderListError: {},
    customerLocation: {},
    customerLocationError: {},
    checkCustLocUpdated: {},
    checkCustLocUpdatedError: {},
  },
  reducers: {
    createOrder: (state, payload) => {
      state.isLoading = true;
      state.createOrderStatus = {};
      state.createOrderStatusError = {};
    },
    createOrderSuccess: (state, action) => {
      state.isLoading = false;
      state.createOrderStatus = action.payload;
      state.createOrderStatusError = {};
    },
    createOrderError: (state, action) => {
      state.isLoading = false;
      state.createOrderStatusError = action.payload;
      state.createOrderStatus = {};
    },
    updateOrder: (state, action) => {
      state.updateOrderSuccess = {};
      state.updateOrderError = {};
    },
    updateOrderSuccess: (state, action) => {
      state.updateOrderSuccess = action.payload;
      state.updateOrderError = {};
    },
    updateOrderError: (state, action) => {
      state.updateOrderSuccess = {};
      state.updateOrderError = action.payload;
    },
    updateOrderStatus: (state, action) => {
      state.updateOrderStatus = {};
      state.updateOrderStatusError = {};
    },
    updateOrderStatusSuccess: (state, action) => {
      state.updateOrderStatus = action.payload;
      state.updateOrderStatusError = {};
    },
    updateOrderStatusError: (state, action) => {
      state.updateOrderStatus = {};
      state.updateOrderStatusError = action.payload;
    },
    updateOrderDriver: (state, action) => {
      state.updateOrderDriver = {};
      state.updateOrderDriverError = {};
    },
    updateOrderDriverSuccess: (state, action) => {
      state.updateOrderDriver = action.payload;
      state.updateOrderDriverError = {};
    },
    updateOrderDriverError: (state, action) => {
      state.updateOrderDriver = {};
      state.updateOrderDriverError = action.payload;
    },
    deleteOrder: (state, action) => {
      state.deleteOrderError = {};
      state.deleteOrderSuccess = {};
    },
    deleteOrderSuccess: (state, action) => {
      state.deleteOrderError = {};
      state.deleteOrderSuccess = action.payload;
    },
    deleteOrderError: (state, action) => {
      state.deleteOrderError = action.payload;
      state.deleteOrderSuccess = {};
    },
    listOrder: (state, payload) => {
      state.isLoading = true;
      state.orderList = {};
      state.orderError = {};
    },
    listOrderSuccess: (state, action) => {
      state.isLoading = false;
      state.orderList = action.payload;
      state.orderError = {};
    },
    listOrderError: (state, action) => {
      state.isLoading = false;
      state.orderError = action.payload;
      state.orderList = {};
    },
    dashboardListOrder: (state, action) => {
      state.isLoading = false;
      state.dashboardOrderList = {};
      state.dashboardOrderListError = {};
    },
    dashboardListOrderSuccess: (state, action) => {
      state.isLoading = false;
      state.dashboardOrderList = action.payload;
      state.dashboardOrderListError = {};
    },
    dashboardListOrderError: (state, action) => {
      state.isLoading = false;
      state.dashboardOrderListError = action.payload;
      state.dashboardOrderList = {};
    },
    updateCustomerLocation: (state, action) => {
      state.isLoading = false;
      state.customerLocation = {};
      state.customerLocationError = {};
    },
    updateCustomerLocationSuccess: (state, action) => {
      state.isLoading = false;
      state.customerLocation = action.payload;
      state.customerLocationError = {};
    },
    updateCustomerLocationError: (state, action) => {
      state.isLoading = false;
      state.customerLocation = {};
      state.customerLocationError = action.payload;
    },
    checkIfCustLocUpdated: (state, payload) => {
      state.isLoading = true;
      state.checkCustLocUpdated = {};
      state.checkCustLocUpdatedError = {};
    },
    checkIfCustLocUpdatedSuccess: (state, action) => {
      state.isLoading = false;
      state.checkCustLocUpdated = action.payload;
      state.checkCustLocUpdatedError = {};
    },
    checkIfCustLocUpdatedError: (state, action) => {
      state.isLoading = false;
      state.checkCustLocUpdatedError = action.payload;
      state.checkCustLocUpdated = {};
    },
    resetFunction: (state, action) => {
      state.createOrderStatus = {};
      state.createOrderStatusError = {};
      state.isLoading = false;
      state.orderList = {};
      state.orderListError = {};
      state.updateOrderStatus = {};
      state.updateOrderStatusError = {};
      state.updateOrderDriver = {};
      state.updateOrderDriverError = {};
      state.updateOrderSuccess = {};
      state.updateOrderError = {};
      state.deleteOrderError = {};
      state.deleteOrderSuccess = {};
      state.customerLocation = {};
      state.customerLocationError = {};
      state.checkCustLocUpdated = {};
      state.checkCustLocUpdatedError = {};
    },
  },
});

export const {
  createOrder,
  createOrderSuccess,
  createOrderError,
  updateOrder,
  updateOrderSuccess,
  updateOrderError,
  updateOrderStatus,
  updateOrderStatusSuccess,
  updateOrderStatusError,
  updateOrderDriver,
  updateOrderDriverSuccess,
  updateOrderDriverError,
  deleteOrder,
  deleteOrderSuccess,
  deleteOrderError,
  listOrder,
  listOrderSuccess,
  listOrderError,
  resetFunction,
  dashboardListOrder,
  dashboardListOrderSuccess,
  dashboardListOrderError,
  updateCustomerLocation,
  updateCustomerLocationSuccess,
  updateCustomerLocationError,
  checkIfCustLocUpdated,
  checkIfCustLocUpdatedSuccess,
  checkIfCustLocUpdatedError,
} = orderSlice.actions;
export default orderSlice.reducer;
