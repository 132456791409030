import React, { useEffect, useState } from "react";
import moment from "moment";
import { Steps } from "antd";

const OrderTrackLine = ({ transitDetails }) => {
  const [itemData, setItemData] = useState({
    firstItemTitle: "",
    firstItemDesc: "",
    secondItemTitle: "",
    secondItemDesc: "",
    thirdItemTitle: "",
    thirdItemDesc: "",
    fourthItemTitle: "",
    fourthItemDesc: "",
  });
  const [currentSelect, setCurrentSelect] = useState(0);

  useEffect(() => {
    const isEmpty =
      transitDetails &&
      Object.values(transitDetails).every((x) => x === null || x === "");
    let iData = { ...itemData };
    let crntSlct = 0;
    if (isEmpty === true) {
      iData.firstItemTitle = "Not Yet Packed";
      iData.firstItemDesc = "";
      setItemData(iData);
    } else {
      if (transitDetails.packed !== null) {
        iData.firstItemTitle =
          moment(transitDetails.packed).format("DD-MM-YYYY") +
          " | " +
          moment(transitDetails.packed).format("LT");
        iData.firstItemDesc = "Order Packed";
        crntSlct = 1;
      } else if (
        transitDetails.outForDelivery !== null ||
        transitDetails.delivered !== null ||
        transitDetails.cancelled !== null
      ) {
        iData.firstItemTitle = "";
        iData.firstItemDesc = "Order Packed";
        crntSlct = 1;
      }

      // if (transitDetails.shipped !== null) {
      //   iData.secondItemTitle =
      //     moment(transitDetails.shipped).format("DD-MM-YYYY") +
      //     " | " +
      //     moment(transitDetails.shipped).format("LT");
      //   iData.secondItemDesc = "Your order is shipped";
      //   crntSlct = 2;
      // } else if (
      //   transitDetails.outForDelivery !== null ||
      //   transitDetails.delivered !== null ||
      //   transitDetails.cancelled !== null
      // ) {
      //   iData.secondItemTitle = "";
      //   iData.secondItemDesc = "Your order is shipped";
      //   crntSlct = 2;
      // }

      if (transitDetails.outForDelivery !== null) {
        iData.thirdItemTitle =
          moment(transitDetails.outForDelivery).format("DD-MM-YYYY") +
          " | " +
          moment(transitDetails.outForDelivery).format("LT");
        iData.thirdItemDesc = "Your order is out for delivery";
        crntSlct = 2;
      } else if (
        transitDetails.outForDelivery !== null ||
        transitDetails.delivered !== null ||
        transitDetails.cancelled !== null
      ) {
        iData.thirdItemTitle = "";
        iData.thirdItemDesc = "Your order is out for delivery";
        crntSlct = 2;
      }

      if (transitDetails.delivered !== null) {
        iData.fourthItemTitle =
          moment(transitDetails.delivered).format("DD-MM-YYYY") +
          " | " +
          moment(transitDetails.delivered).format("LT");
        iData.fourthItemDesc = "Your order is delivered";
        crntSlct = 3;
      }

      if (transitDetails.cancelled !== null) {
        iData.fourthItemTitle =
          moment(transitDetails.cancelled).format("DD-MM-YYYY") +
          " | " +
          moment(transitDetails.cancelled).format("LT");
        iData.fourthItemDesc = "Your order is cancelled";
        crntSlct = 3;
      }

      setItemData(iData);
      setCurrentSelect(crntSlct);
    }
  }, [transitDetails]);

  return (
    <Steps
      progressDot
      current={currentSelect}
      direction="vertical"
      items={[
        {
          title: itemData.firstItemTitle,
          description: itemData.firstItemDesc,
        },
        // {
        //   title: itemData.secondItemTitle,
        //   description: itemData.secondItemDesc,
        // },
        {
          title: itemData.thirdItemTitle,
          description: itemData.thirdItemDesc,
        },
        {
          title: itemData.fourthItemTitle,
          description: itemData.fourthItemDesc,
        },
      ]}
    />
  );
};
export default OrderTrackLine;
