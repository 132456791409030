import React, { useState, useEffect, useContext } from "react";
//React Hook Form cleanly grabs the input values and returns them in a handleSubmit function.
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Redux
import {
  createDriver,
  resetFunction,
  updateDriver,
} from "../../store/driver/driverState";
import { uploadImage, resetImage } from "../../store/common/commonState";
import { useDispatch, useSelector } from "react-redux";
import { StateContext } from "../../context/AppProvider";
import { Input } from "reactstrap";
import cancelImg from "../../assets/images/cancel.svg";
import plusImg from "../../assets/images/plus.svg";
import downloadSmallImg from "../../assets/images/download-small.svg";
import { Button, Card, Col, Row } from "antd";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';

const DriverAdd = ({ pageType, setPageType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contData = useContext(StateContext);
  const editData = contData.driverEditData;
  const [phoneNumber, setPhoneNumber] = useState(
    Object.keys(editData).length > 0 ? editData.phone
      : ""
  );
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };
  const [uploadDoc, setUploadDoc] = useState([
    {
      docName: "",
      filePath: "",
      docType: "",
      fileUrl: "",
    },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const fileUploadSucs = useSelector((state) => state.Common.uploadData);
  const fileUploadError = useSelector((state) => state.Common.uploadError);

  const [errorValidation, setErrorValidation] = useState({
    phoneNumber:0,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      vehicleType:
        Object.keys(editData).length > 0 ? editData.vehicleType : "car",
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  useEffect(() => {
    dispatch(resetImage());
  }, []);

  useEffect(() => {
    if (
      Object.keys(fileUploadSucs).length > 0 &&
      fileUploadSucs.success === true
    ) {
      let docData = [...uploadDoc];
      docData[currentIndex].filePath = fileUploadSucs.result.Location;
      setUploadDoc(docData);
    }
  }, [fileUploadSucs]);

  useEffect(() => {
    if (Object.keys(fileUploadError).length > 0) {
      toast(fileUploadError.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [fileUploadError]);

  const submitForm = (data) => {
    let valid = 0;
    let validPhone = 0;

    if(phoneNumber.length < 6)
    {
      valid = 1;
      validPhone = 1;
    }
    if(valid === 0)
    {
      let upDocs = uploadDoc.map((itm, i) => {
        return {
          docName: itm.docName,
          filePath: itm.filePath,
          docType: itm.docType,
        };
      });
      data.docDetails = upDocs;
      data.phone=phoneNumber;
      if (Object.keys(editData).length === 0) {
        dispatch(createDriver(data));
      } else {
        let payload = { driverId: editData._id, data: data };
        dispatch(updateDriver(payload));
      }
    }
    setErrorValidation({
      ...errorValidation,
      phoneNumber: validPhone,
    });
  };

  const driver = useSelector((state) => state.Driver.createDriverStatus);
  const error = useSelector((state) => state.Driver.createDriverStatusError);

  useEffect(() => {
    if (driver && driver.success === true) {
      toast(driver.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
      setPageType("list");
    }
  }, [driver]);

  useEffect(() => {
    if (error && error.data && error.data.success === false) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      let upDoc = [...uploadDoc];
      editData.docDetails.length > 0 &&
        editData.docDetails.map((itm, i) => {
          return (upDoc[i] = {
            docName: itm.docName,
            filePath: itm.filePath,
            docType: itm.docType,
            fileUrl: itm.filePath,
          });
        });
      setUploadDoc(upDoc);
    }
  }, [editData]);

  const handelUploadFiles = (e, index) => {
    let docImg = [...uploadDoc];
    const up = new FormData();
    if (e) {
      docImg[index].fileUrl = URL.createObjectURL(e);
      setUploadDoc(docImg);
      up.append("files", e);
    }
    setCurrentIndex(index);
    dispatch(uploadImage(up));
  };

  const addDocument = (index) => {
    return (
      <img
        src={plusImg}
        alt=""
        onClick={() => {
          let newDoc = [...uploadDoc];
          if (newDoc[index].fileUrl === "") {
            alert("Please add a document");
          } else {
            newDoc.push({
              docName: "",
              filePath: "",
              docType: "",
              fileUrl: "",
            });
            setUploadDoc(newDoc);
          }
        }}
        style={{
          color: "blue",
          cursor: "pointer",
        }}
      />
    );
  };

  const removeDocument = (index) => {
    return (
      <img
        src={cancelImg}
        alt=""
        onClick={() => {
          let fname = "file" + index;
          document.getElementById(fname).value = "";
          let newDoc = [...uploadDoc];
          if (newDoc.length === 1) {
            newDoc = [
              {
                docName: "",
                filePath: "",
                docType: "",
                fileUrl: "",
              },
            ];
          } else {
            newDoc.splice(index, 1);
          }
          setUploadDoc(newDoc);
        }}
        style={{
          color: "blue",
          cursor: "pointer",
        }}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          <Row className="common-head">
            <p className="head-p">
              {Object.keys(editData).length > 0 ? t("Edit Driver"): t("Add Driver")}
            </p>
            <Button
              onClick={() => setPageType("list")}
              type="button"
              className="top-btn btn form-btn bgcolor1 ripple1"
            >
              {t("List Drivers")}
            </Button>
          </Row>

          <Row className="com-cntnt">
            <form onSubmit={handleSubmit(submitForm)}>
              <Card className="form-card" bordered={false}>
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="row new-field">
                        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                          <p className="label-text">{t('Name')}</p>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                          <div className="field-coulmn">
                            <input
                              className="field-input"
                              type="text"
                              placeholder={t("Name")}
                              defaultValue={
                                Object.keys(editData).length > 0
                                  ? editData.name
                                  : ""
                              }
                              {...register("name", {
                                required: "Please enter name.",
                                onChange: (e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^a-z\u0600-\u06FF@~`!@#$%^&*()_ =+\\\\';:\"\\/?>.<,-]/gi,
                                    ""
                                  );
                                },
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="name"
                              render={({ message }) => (
                                <p className="val-error">{message}</p>
                              )}
                            />
                          </div>
                        </Col>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="row new-field">
                        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                          <p className="label-text">{t('License Plate No.')}</p>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                          <div className="field-coulmn">
                            <input
                              className="field-input"
                              type="text"
                              placeholder={t('License Plate No.')}
                              defaultValue={
                                Object.keys(editData).length > 0
                                  ? editData.licencePlateNo
                                  : ""
                              }
                              {...register("licencePlateNo", {
                                required: "Please enter License Plate Number.",
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="licencePlateNo"
                              render={({ message }) => (
                                <p className="val-error">{message}</p>
                              )}
                            />
                          </div>
                        </Col>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="row new-field">
                        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                          <p className="label-text">
                            {t("Mobile Number (For OTP Login)")}
                          </p>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                          <div className="field-coulmn">
                          <PhoneInput
                            country={'qa'}
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                          />
                          {errorValidation.phoneNumber === 1 && (
                            <p className="val-error">
                              Please enter phone number.
                            </p>
                          )}
                            {/* <input
                              className="field-input"
                              type="text"
                              maxLength={11}
                              placeholder="with country code(eg:974xxxxxxxx)"
                              defaultValue={
                                Object.keys(editData).length > 0
                                  ? editData.phone
                                  : ""
                              }
                              {...register("phone", {
                                required: "Please enter Mobile Number.",
                                onChange: (e) => {
                                  e.target.value = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                },
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="phone"
                              render={({ message }) => (
                                <p className="val-error">{message}</p>
                              )}
                            /> */}
                          </div>
                        </Col>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="row new-field">
                        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                          <p className="label-text">{t('ID Number')}</p>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                          <div className="field-coulmn">
                            <input
                              className="field-input"
                              type="text"
                              placeholder={t('ID Number')}
                              checked
                              defaultValue={
                                Object.keys(editData).length > 0
                                  ? editData.IDNumber
                                  : ""
                              }
                              {...register("IDNumber", {
                                required: "Please enter Mobile Number.",
                              })}
                            />
                          </div>
                        </Col>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="row new-field">
                        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                          <p className="label-text">{t("Vehicle")}</p>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                          <div className="vehicle-driver">
                            <div className="radio">
                              <input
                                type="radio"
                                id="html"
                                name="vehicleType"
                                value="car"
                                {...register("vehicleType")}
                              />
                              <label htmlFor="html">
                                <span className="field-icn material-icons">
                                  directions_car
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="vehicle-driver">
                            <div className="radio">
                              <input
                                type="radio"
                                id="html"
                                name="vehicleType"
                                value="pickup"
                                {...register("vehicleType")}
                              />
                              <label htmlFor="html">
                                <span className="field-icn material-icons">
                                  local_shipping
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="vehicle-driver">
                            <div className="radio">
                              <input
                                type="radio"
                                id="html"
                                name="vehicleType"
                                value="twowheeler"
                                {...register("vehicleType")}
                              />
                              <label htmlFor="html">
                                <span className="field-icn material-icons">
                                  moped
                                </span>
                              </label>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    {uploadDoc.map((item, i) => {
                      return (
                        <div key={i} className="row new-field mb-20">
                          {i === 0 && (
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <p className="label-text">{t("Upload Documents")}</p>
                            </Col>
                          )}
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row>
                              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <div className="field-coulmn mr-5 mb-2">
                                  <Input
                                    id="docName"
                                    name="docName"
                                    type="text"
                                    value={uploadDoc[i].docName}
                                    onChange={(e) => {
                                      let docImg = [...uploadDoc];
                                      docImg[i].docName = e.target.value;
                                      setUploadDoc(docImg);
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <div className="field-coulmn mb-2">
                                  <Input
                                    id={`file${i}`}
                                    name="file"
                                    type="file"
                                    onChange={(e) => {
                                      if (e.target.files) {
                                        if (e.target.files.length) {
                                          handelUploadFiles(
                                            e.target.files[0],
                                            i
                                          );
                                        } else {
                                          handelUploadFiles(false, i);
                                        }
                                      }
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} sm={4} md={4} lg={2} xl={2}>
                                {uploadDoc[i].fileUrl ? (
                                  <a
                                    href={uploadDoc[i].fileUrl}
                                    target="_blank"
                                    download={false}
                                    rel="noreferrer"
                                  >
                                    <img
                                      alt=""
                                      className="download-icon"
                                      src={downloadSmallImg}
                                    />
                                  </a>
                                ) : null}
                              </Col>
                              <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                                <div className="field-coulmn">
                                  {uploadDoc.length - 1 === i ? (
                                    <Row>
                                      <Col span={12}>{addDocument(i)}</Col>
                                      <Col span={12}>{removeDocument(i)}</Col>
                                    </Row>
                                  ) : (
                                    <Col span={12}>{removeDocument(i)}</Col>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      );
                    })}
                  </Col>
                </Row>

                <div className="row btn-float-right">
                  <div className=" btn-field">
                    <button
                      type="submit"
                      className="btn form-btn bgcolor1 ripple1 mr-5"
                    >
                      {t("Save")}
                    </button>
                    <button
                      onClick={() => setPageType("list")}
                      type="button"
                      className="btn  form-btn bgcolor3 ripple3"
                    >
                      {t('Cancel')}
                    </button>
                  </div>
                </div>
              </Card>
            </form>
          </Row>
        </Row>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default DriverAdd;
