import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import driverService from "../../service/driver/DriverService";
import {
  createDriverSuccess,
  createDriverError,
  updateDriverSuccess,
  updateDriverError,
  deleteDriverSuccess,
  deleteDriverError,
  resetDriverAmountSuccess,
  resetDriverAmountError,
  listDriverSuccess,
  listDriverError,
} from "./driverState";

function* createDriver(action) {
  const data = yield call(driverService.createDriver, action.payload);
  if (data.msg === "success") {
    yield put(createDriverSuccess(data.response));
  } else {
    yield put(createDriverError(data.response));
  }
}

function* updateDriver(action) {
  const data = yield call(driverService.updateDriver, action.payload);
  if (data.msg === "success") {
    yield put(updateDriverSuccess(data.response));
  } else {
    yield put(updateDriverError(data.response));
  }
}

function* deleteDriver(action) {
  const data = yield call(driverService.deleteDriver, action.payload);
  if (data.msg === "success") {
    yield put(deleteDriverSuccess(data.response));
  } else {
    yield put(deleteDriverError(data.response));
  }
}

function* resetDriverAmount(action) {
  const data = yield call(driverService.resetDriverAmt, action.payload);
  if (data.msg === "success") {
    yield put(resetDriverAmountSuccess(data.response));
  } else {
    yield put(resetDriverAmountError(data.response));
  }
}

function* listDriver(action) {
  const data = yield call(driverService.listDriver, action.payload);
  if (data.msg === "success") {
    yield put(listDriverSuccess(data.response));
  } else {
    yield put(listDriverError(data.response));
  }
}
function* driverSaga() {
  yield takeEvery("driver/createDriver", createDriver);
  yield takeLatest("driver/listDriver", listDriver);
  yield takeEvery("driver/updateDriver", updateDriver);
  yield takeEvery("driver/deleteDriver", deleteDriver);
  yield takeEvery("driver/resetDriverAmount", resetDriverAmount);
}

export default driverSaga;
