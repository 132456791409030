import { createSlice } from "@reduxjs/toolkit";

export const driverSlice = createSlice({
  name: "driver",
  initialState: {
    createDriverStatus: {},
    createDriverStatusError: {},
    isLoading: false,
    driverList: {},
    driverListError: {},
    updateDriverStatus: {},
    updateDriverStatusError: {},
    deleteDriverError: {},
    deleteDriverSuccess: {},
    resetDriverAmountSuccess: {},
    resetDriverAmountError: {},
  },
  reducers: {
    createDriver: (state, payload) => {
      state.isLoading = true;
      state.createDriverStatus = {};
      state.createDriverStatusError = {};
    },
    createDriverSuccess: (state, action) => {
      state.isLoading = false;
      state.createDriverStatus = action.payload;
      state.createDriverStatusError = {};
    },
    createDriverError: (state, action) => {
      state.isLoading = false;
      state.createDriverStatusError = action.payload;
      state.createDriverStatus = {};
    },
    updateDriver: (state, action) => {
      state.updateDriverStatus = {};
      state.updateDriverStatusError = {};
    },
    updateDriverSuccess: (state, action) => {
      state.updateDriverStatus = action.payload;
      state.updateDriverStatusError = {};
    },
    updateDriverError: (state, action) => {
      state.updateDriverStatus = {};
      state.updateDriverStatusError = action.payload;
    },
    deleteDriver: (state, action) => {
      state.deleteDriverError = {};
      state.deleteDriverSuccess = {};
    },
    deleteDriverSuccess: (state, action) => {
      state.deleteDriverError = {};
      state.deleteDriverSuccess = action.payload;
    },
    deleteDriverError: (state, action) => {
      state.deleteDriverError = action.payload;
      state.deleteDriverSuccess = {};
    },
    resetDriverAmount: (state, action) => {
      state.resetDriverAmountError = {};
      state.resetDriverAmountSuccess = {};
    },
    resetDriverAmountSuccess: (state, action) => {
      state.resetDriverAmountError = {};
      state.resetDriverAmountSuccess = action.payload;
    },
    resetDriverAmountError: (state, action) => {
      state.resetDriverAmountError = action.payload;
      state.resetDriverAmountSuccess = {};
    },
    listDriver: (state, payload) => {
      state.isLoading = true;
      state.driverList = {};
    },
    listDriverSuccess: (state, action) => {
      state.isLoading = false;
      state.driverList = action.payload;
      state.driverError = {};
    },
    listDriverError: (state, action) => {
      state.isLoading = false;
      state.driverError = action.payload;
      state.driverList = {};
    },
    resetFunction: (state, action) => {
      state.createDriverStatus = {};
      state.createDriverStatusError = {};
      state.isLoading = false;
      state.driverList = {};
      state.driverListError = {};
      state.updateDriverStatus = {};
      state.updateDriverStatusError = {};
      state.deleteDriverError = {};
      state.deleteDriverSuccess = {};
      state.resetDriverAmountError = {};
      state.resetDriverAmountSuccess = {};
    },
  },
});

export const {
  createDriver,
  createDriverSuccess,
  createDriverError,
  updateDriver,
  updateDriverSuccess,
  updateDriverError,
  deleteDriver,
  deleteDriverSuccess,
  deleteDriverError,
  resetDriverAmount,
  resetDriverAmountSuccess,
  resetDriverAmountError,
  listDriver,
  listDriverSuccess,
  listDriverError,
  resetFunction,
} = driverSlice.actions;
export default driverSlice.reducer;
