import { call, put, takeEvery } from "redux-saga/effects";
import authService from "../../../service/auth/AuthService";
import { getLoginSuccess, getLoginError } from "./loginState";
// import axios from "axios";

function* fetchLoginData({ type, payload }) {
  let user = payload.data;
  let history = payload.history;
  const data = yield call(authService.loginUser, user);
  if (data.msg === "success") {
    yield put(getLoginSuccess(data.response));
    history("/dashboard");
  } else {
    yield put(getLoginError(data.response));
  }
}

function* loginSaga() {
  yield takeEvery("login/getLoginFetch", fetchLoginData);
}

export default loginSaga;
