import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    uploadData: {},
    uploadError: {},
    isLoading: false,
    paymentStatus: {},
    paymentStatusError: {},
    settingsData: {},
    settingsDataError: {},
    updatePickupTimeData: {},
    updatePickupTimeDataError: {},
    docMaster: {},
    docMasterError: {},
    weightLimitSuccess: {},
    weightLimitError: {},
  },
  reducers: {
    uploadImage: (state, payload) => {
      state.isLoading = true;
      state.uploadData = {};
      state.uploadError = {};
    },
    uploadImageSuccess: (state, action) => {
      state.isLoading = false;
      state.uploadData = action.payload;
      state.uploadError = {};
    },
    uploadImageError: (state, action) => {
      state.isLoading = false;
      state.uploadError = action.payload;
      state.uploadData = {};
    },
    paymentReturnStatus: (state, action) => {
      state.paymentStatus = {};
      state.paymentStatusError = {};
    },
    paymentReturnStatusSuccess: (state, action) => {
      state.paymentStatus = action.payload;
      state.paymentStatusError = {};
    },
    paymentReturnStatusError: (state, action) => {
      state.paymentStatus = {};
      state.paymentStatusError = action.payload;
    },
    fetchSettingsData: (state, action) => {
      state.settingsData = {};
      state.settingsDataError = {};
    },
    fetchSettingsDataSuccess: (state, action) => {
      state.settingsData = action.payload;
      state.settingsDataError = {};
    },
    fetchSettingsDataError: (state, action) => {
      state.settingsData = {};
      state.settingsDataError = action.payload;
    },
    updatePickupTime: (state, action) => {
      state.updatePickupTimeData = {};
      state.updatePickupTimeDataError = {};
    },
    updatePickupTimeSuccess: (state, action) => {
      state.updatePickupTimeData = action.payload;
      state.updatePickupTimeDataError = {};
    },
    updatePickupTimeError: (state, action) => {
      state.updatePickupTimeData = {};
      state.updatePickupTimeDataError = action.payload;
    },
    resetImage: (state, action) => {
      state.uploadError = {};
      state.uploadData = {};
    },
    resetPaymentStatus: (state, action) => {
      state.paymentStatus = {};
      state.paymentStatusError = {};
    },
    resetPickupUpdate: (state, action) => {
      state.updatePickupTimeData = {};
      state.updatePickupTimeDataError = {};
      state.weightLimitSuccess = {};
      state.weightLimitError = {};
    },
    fetchAllDocMaster: (state, action) => {
      state.docMaster = {};
      state.docMasterError = {};
    },
    fetchAllDocMasterSuccess: (state, action) => {
      state.docMaster = action.payload;
      state.docMasterError = {};
    },
    fetchAllDocMasterError: (state, action) => {
      state.docMaster = {};
      state.docMasterError = action.payload;
    },
    updateWeightLimit: (state, action) => {
      state.weightLimitSuccess = {};
      state.weightLimitError = {};
    },
    updateWeightLimitSuccess: (state, action) => {
      state.weightLimitSuccess = action.payload;
      state.weightLimitError = {};
    },
    updateWeightLimitError: (state, action) => {
      state.weightLimitSuccess = {};
      state.weightLimitError = action.payload;
    },
  },
});

export const {
  uploadImage,
  uploadImageSuccess,
  uploadImageError,
  resetImage,
  paymentReturnStatus,
  paymentReturnStatusSuccess,
  paymentReturnStatusError,
  fetchSettingsData,
  fetchSettingsDataSuccess,
  fetchSettingsDataError,
  updatePickupTime,
  updatePickupTimeSuccess,
  updatePickupTimeError,
  resetPickupUpdate,
  resetPaymentStatus,
  fetchAllDocMaster,
  fetchAllDocMasterSuccess,
  fetchAllDocMasterError,
  updateWeightLimit,
  updateWeightLimitSuccess,
  updateWeightLimitError,
} = commonSlice.actions;
export default commonSlice.reducer;
