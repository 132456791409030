import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import customerService from "../../service/customer/CustomerService";
import {
  createCustomerSuccess,
  createCustomerError,
  updateCustomerSuccess,
  updateCustomerError,
  deleteCustomerSuccess,
  deleteCustomerError,
  listCustomerSuccess,
  listCustomerError,
  listCustomerByVendorSuccess,
  listCustomerByVendorError,
} from "./customerState";

function* createCustomer(action) {
  const data = yield call(customerService.createCustomer, action.payload);
  if (data.msg === "success") {
    yield put(createCustomerSuccess(data.response));
  } else {
    yield put(createCustomerError(data.response));
  }
}

function* updateCustomer(action) {
  const data = yield call(customerService.updateCustomer, action.payload);
  if (data.msg === "success") {
    yield put(updateCustomerSuccess(data.response));
  } else {
    yield put(updateCustomerError(data.response));
  }
}

function* deleteCustomer(action) {
  const data = yield call(customerService.deleteCustomer, action.payload);
  if (data.msg === "success") {
    yield put(deleteCustomerSuccess(data.response));
  } else {
    yield put(deleteCustomerError(data.response));
  }
}

function* listCustomer(action) {
  const data = yield call(customerService.listCustomer, action.payload);
  if (data.msg === "success") {
    yield put(listCustomerSuccess(data.response));
  } else {
    yield put(listCustomerError(data.response));
  }
}

function* listCustomerByVendor(action) {
  const data = yield call(
    customerService.listCustomerByVendorId,
    action.payload
  );
  if (data.msg === "success") {
    yield put(listCustomerByVendorSuccess(data.response));
  } else {
    yield put(listCustomerByVendorError(data.response));
  }
}

function* customerSaga() {
  yield takeEvery("customer/createCustomer", createCustomer);
  yield takeLatest("customer/listCustomer", listCustomer);
  yield takeEvery("customer/updateCustomer", updateCustomer);
  yield takeEvery("customer/deleteCustomer", deleteCustomer);
  yield takeEvery("customer/listCustomerByVendor", listCustomerByVendor);
}

export default customerSaga;
