import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import GenericPdfDownloader from "../Common/GenericPdfDownloader";
import logoImg from "../../assets/images/logo.png";
import moment from "moment";
import packageImg from "../../assets/images/package.png";
import calendarImg from "../../assets/images/calendar-v.png";
import addressImg from "../../assets/images/address.png";
import clockImg from "../../assets/images/clock.png";
import { useTranslation } from 'react-i18next';

const ViewPDF = ({ viewModal, setViewModal, orderEditData }) => {
  const { t } = useTranslation();
  const toggle = () => setViewModal(!viewModal);

  return (
    <>
      <Modal
        size="lg"
        style={{ maxWidth: "50%", width: "100%" }}
        isOpen={viewModal}
        className="custom-modal1 modalCenter-OrderPdf"
        toggle={toggle}
      >
        <div id="testId">
          <ModalHeader toggle={toggle}>
            <img src={logoImg} alt="" className="img-orderPdf" />
          </ModalHeader>
          <ModalBody>
            <div className="row pdf-modal">
              <div className="col-sm-12 col-md-12">
                <div className="white-box">
                  <div className="wt-bx-hd">
                    <img src={addressImg} alt="" />
                    <p>Customer details</p>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="txt-hd">{t('Customer Name')}</p>
                      <p>{orderEditData && orderEditData.customerName}</p>
                    </div>
                    <div className="col">
                      <p className="txt-hd">{t("Phone Number")}</p>
                      <p>{orderEditData && orderEditData.customerPhone}</p>
                    </div>
                    <div className="col">
                      <p className="txt-hd">Area</p>
                      <p>
                        {orderEditData.dropoffAddress &&
                          orderEditData.dropoffAddress.locationName}
                      </p>
                    </div>
                    <div className="col-12">
                      <p className="txt-hd">{t('Address')}</p>
                      <p>
                        {orderEditData.dropoffAddress &&
                          orderEditData.dropoffAddress.buildingNumber}
                        ,
                        {orderEditData.dropoffAddress &&
                          orderEditData.dropoffAddress.streetNumber}
                        ,
                        {orderEditData.dropoffAddress &&
                          orderEditData.dropoffAddress.zoneNumber}
                        ,
                        {orderEditData.dropoffAddress &&
                          orderEditData.dropoffAddress.locationName}
                        ,
                        {orderEditData.dropoffAddress &&
                          orderEditData.dropoffAddress.municipality}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="white-box">
                  <div className="wt-bx-hd">
                    <img src={packageImg} alt="" />
                    <p>Package details</p>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <p className="txt-hd txt-hd-spl">
                        {t('Amount to be collected')}
                      </p>
                      <p>QAR {orderEditData.amountToBeCollected}</p>
                    </div>

                    <div className="col-4">
                      <p className="txt-hd">Weight</p>
                      <p>{orderEditData.packageWeight} Kg</p>
                    </div>
                    <div className="col-4"></div>
                    <div className="col-4">
                      <p className="txt-hd txt-hd-spl">{t('Vendor Name')}</p>
                      <p>
                        {orderEditData &&
                          orderEditData.vendor &&
                          orderEditData.vendor.name}
                      </p>
                    </div>
                    <div className="col-4">
                      <p className="txt-hd txt-hd-spl">Delivery Charge</p>
                      <h4>QAR {orderEditData.deliveryCharge}</h4>
                    </div>
                  </div>
                </div>
                <div className="white-box">
                  <div className="row">
                    <div className="row">
                      <div className="col-4">
                        <p className="txt-hd">Delivery On</p>
                        <div className="dat">
                          <img src={calendarImg} alt="" />
                          <p>
                            {moment(orderEditData.deliveryDate).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-4">
                        <p className="txt-hd">{t('Delivery Status')}</p>
                        <div className="tim">
                          <img src={clockImg} alt="" />
                          <div>
                            <p>{orderEditData.status}</p>
                            {/* <p className="tim-desc">Tomorrow</p> */}
                          </div>
                        </div>
                      </div>

                      <div className="col-4"></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="txt-fld">
                          <span className="txt-hd-icn material-icons">
                            local_shipping
                          </span>
                          <p className="txt-hd-label">Pickup Vehicle</p>
                        </div>
                        <p>{orderEditData.pickUpVehicle}</p>
                      </div>
                      <div className="col">
                        <div className="txt-fld">
                          <span className="txt-hd-icn material-icons">
                            room_service
                          </span>
                          <p className="txt-hd-label">Delivery Type</p>
                        </div>
                        <p>{orderEditData.deliveryType}</p>
                      </div>
                      <div className="col">
                        <div className="txt-fld">
                          <span className="txt-hd-icn material-icons">
                            credit_card
                          </span>
                          <p className="txt-hd-label">{t('Payment Type')}</p>
                        </div>
                        <p>{orderEditData.paymentType}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="row">
                        <div className="col-4">
                          <p className="txt-hd">{t('Driver Name')}</p>
                          <p>
                            {orderEditData &&
                              orderEditData.driver &&
                              orderEditData.driver.name}
                          </p>
                        </div>
                        <div className="col-4">
                          <p className="txt-hd">Driver Phone</p>
                          <p>
                            {orderEditData &&
                              orderEditData.driver &&
                              orderEditData.driver.phone}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="txt-fld">
                        <span className="txt-hd-icn material-icons">
                          bubble_chart
                        </span>
                        <p className="txt-hd-label">Additional info</p>
                      </div>
                      <div>
                        <p className="adtl-txt">{orderEditData.addInfo}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-8"></div>
            </div>
          </ModalBody>
        </div>
        <ModalFooter>
          <GenericPdfDownloader
            downloadFileName={orderEditData.orderNumber}
            rootElementId="testId"
          />
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ViewPDF;
