import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import vendorService from "../../service/vendor/VendorService";
import {
  createVendorSuccess,
  createVendorError,
  updateVendorSuccess,
  updateVendorError,
  listVendorSuccess,
  listVendorError,
  deleteVendorSuccess,
  deleteVendorError,
  changePasswordSuccess,
  changePasswordError,
  profileVendorSuccess,
  profileVendorError,
  resetPackageAmountSuccess,
  resetPackageAmountError,
} from "./vendorState";

function* createVendor(action) {
  const data = yield call(vendorService.createVendor, action.payload);
  if (data.msg === "success") {
    yield put(createVendorSuccess(data.response));
  } else {
    yield put(createVendorError(data.response));
  }
}

function* updateVendor(action) {
  const data = yield call(vendorService.updateVendor, action.payload);
  if (data.msg === "success") {
    yield put(updateVendorSuccess(data.response));
  } else {
    yield put(updateVendorError(data.response));
  }
}

function* deleteVendor(action) {
  const data = yield call(vendorService.deleteVendor, action.payload);
  if (data.msg === "success") {
    yield put(deleteVendorSuccess(data.response));
  } else {
    yield put(deleteVendorError(data.response));
  }
}

function* listVendor(action) {
  const data = yield call(vendorService.listVendor, action.payload);
  if (data.msg === "success") {
    yield put(listVendorSuccess(data.response));
  } else {
    yield put(listVendorError(data.response));
  }
}

function* changePassword(action) {
  const data = yield call(vendorService.changePassword, action.payload);
  if (data.msg === "success") {
    yield put(changePasswordSuccess(data.response));
  } else {
    yield put(changePasswordError(data.response));
  }
}

function* profileVendor() {
  const data = yield call(vendorService.profileVendor);
  if (data.msg === "success") {
    yield put(profileVendorSuccess(data.response));
  } else {
    yield put(profileVendorError(data.response));
  }
}

function* resetPackageAmount(action) {
  const data = yield call(vendorService.resetPackageAmount, action.payload);
  if (data.msg === "success") {
    yield put(resetPackageAmountSuccess(data.response));
  } else {
    yield put(resetPackageAmountError(data.response));
  }
}

function* vendorSaga() {
  yield takeEvery("vendor/createVendor", createVendor);
  yield takeLatest("vendor/listVendor", listVendor);
  yield takeEvery("vendor/updateVendor", updateVendor);
  yield takeEvery("vendor/deleteVendor", deleteVendor);
  yield takeEvery("vendor/changePassword", changePassword);
  yield takeEvery("vendor/profileVendor", profileVendor);
  yield takeEvery("vendor/resetPackageAmount", resetPackageAmount);
}

export default vendorSaga;
