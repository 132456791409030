import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Col, Row } from "antd";
import { updateCustomerLocation } from "../../store/order/orderState";
import { useDispatch } from "react-redux";

const DefaultZoom = 15;
// Fix for default marker icons not showing
const DefaultIcon = L.icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  shadowSize: [41, 41],
});
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: DefaultIcon.options.iconUrl,
  iconSize: DefaultIcon.options.iconSize,
  iconAnchor: DefaultIcon.options.iconAnchor,
  popupAnchor: DefaultIcon.options.popupAnchor,
  shadowUrl: DefaultIcon.options.shadowUrl,
  shadowSize: DefaultIcon.options.shadowSize,
});

const LocationMarker = ({ position, setLocation }) => {
  const map = useMap();

  useEffect(() => {
    if (position) {
      map.setView(position, DefaultZoom); // Zoom level 15, adjust as needed
    }
  }, [position, map]);

  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      setLocation({ lat, lng });
    },
  });

  return position === null ? null : (
    <Marker position={position} icon={DefaultIcon}>
      <Popup>You clicked here!</Popup>
    </Marker>
  );
};

const MapContainerComponent = ({
  DefaultLoc,
  defaultLocation,
  setDefaultLocation,
  orderId,
}) => {
  const dispatch = useDispatch();
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [locationDetails, setLocationDetails] = useState({
    houseno: "",
    addressLine1: "",
    addressLine2: "",
  });

  const [errorValidation, setErrorValidation] = useState({
    houseno: 0,
    addressLine1: 0,
  });

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  const handleResetLocation = () => {
    setDefaultLocation(DefaultLoc);
    setZoom(DefaultZoom);
  };

  const handleSubmit = () => {
    let valid = 0;
    let validhouseno = 0;
    let validaddressLine1 = 0;
    if (locationDetails.houseno === "") {
      valid = 1;
      validhouseno = 1;
    }
    if (locationDetails.addressLine1 === "") {
      valid = 1;
      validaddressLine1 = 1;
    }
    if (valid === 0) {
      let payLoad = {};
      let mapLnk = `http://maps.google.com/maps?z=15&t=m&q=loc:${location.lat}+${location.lng}`;
      payLoad.order = orderId;
      payLoad.dropoffAddress = {
        locationName: "",
        latitude: location.lat.toString(),
        longitude: location.lng.toString(),
        buildingNumber: locationDetails.houseno,
        streetNumber: locationDetails.addressLine1,
        municipality: "",
        zoneNumber: locationDetails.addressLine2,
        mapLink: mapLnk,
        isBlueBoard: false,
      };
      dispatch(updateCustomerLocation(payLoad));
    }
    setErrorValidation({
      ...errorValidation,
      houseno: validhouseno,
      addressLine1: validaddressLine1,
    });
  };
  console.log(errorValidation);
  return (
    <div className="terms">
      <header className="appie-header-area appie-sticky">
        <div className="container">
          <div className="header-nav-box">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-4 col-sm-5 col-4 order-1 order-sm-1">
                <div className="appie-logo-box">
                  <span className="logo-bground-map"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="appie-hero-area-map">
        <div className="container">
          <h4
            style={{
              textAlign: "center",
              padding: "2rem 0rem"
            }}
          >
            Pick Your Location
          </h4>
          <MapContainer
            center={defaultLocation}
            zoom={zoom}
            style={{ height: "500px" }}
            attributionControl={false} // Disable attribution control
            maxZoom={17} // Set maximum zoom level
          >
            <TileLayer
              url={`https://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
              attribution="" // Remove the default attribution
              detectRetina={true}
            />
            <LocationMarker position={location} setLocation={setLocation} />
          </MapContainer>
          <Row>
            <Col span={24}>
              <div className="row new-field">
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <p className="label-text">{"House/Flat/Block No"}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                  <div className="field-coulmn">
                    <input
                      className="field-input"
                      type="text"
                      placeholder={"House/Flat/Block No"}
                      value={locationDetails.houseno}
                      onChange={(e) => {
                        setLocationDetails({
                          ...locationDetails,
                          houseno: e.target.value,
                        });
                      }}
                    />
                    {errorValidation.houseno === 1 && (
                      <span className="val-error">
                        Please enter House/Flat/Block No.
                      </span>
                    )}
                  </div>
                </Col>
              </div>
            </Col>
            <Col span={24}>
              <div className="row new-field">
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <p className="label-text">{"Address Line 1"}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                  <div className="field-coulmn">
                    <input
                      className="field-input"
                      type="text"
                      placeholder={"Address Line 1"}
                      value={locationDetails.addressLine1}
                      onChange={(e) => {
                        setLocationDetails({
                          ...locationDetails,
                          addressLine1: e.target.value,
                        });
                      }}
                    />
                    {errorValidation.addressLine1 === 1 && (
                      <span className="val-error">
                        Please enter address line 1.
                      </span>
                    )}
                  </div>
                </Col>
              </div>
            </Col>
            <Col span={24}>
              <div className="row new-field">
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <p className="label-text">{"Address Line 2 (optional)"}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                  <div className="field-coulmn">
                    <input
                      className="field-input"
                      type="text"
                      placeholder={"Address Line 2 (optional)"}
                      value={locationDetails.addressLine2}
                      onChange={(e) => {
                        setLocationDetails({
                          ...locationDetails,
                          addressLine2: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
          <Row>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn form-btn-map bgcolor1 ripple1 mr-25"
            >
              Submit
            </button>
            <button
              onClick={handleResetLocation}
              type="button"
              className="btn form-btn-map bgcolor3 ripple3"
            >
              Reset Location
            </button>
          </Row>
        </div>
      </section>
    </div>
  );
};

export default MapContainerComponent;
