import React, { Fragment, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "antd";
import ReactPaginate from "react-paginate";

import filterImg from "../../assets/images/filter-icon.png";
import editImg from "../../assets/images/Edit.svg";
import cancelImg from "../../assets/images/cancel.svg";
import searchImg from "../../assets/images/search.svg";
import viewImg from "../../assets/images/eye.svg";
import calendarImg from "../../assets/images/VectorCalendar.svg";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  updateOrderDriver,
  updateOrderStatus,
  listOrder,
} from "../../store/order/orderState";
import moment from "moment";
import { DispatchContext, StateContext } from "../../context/AppProvider";
import { Table } from "reactstrap";
import { useTranslation } from 'react-i18next';

const OrderTableData = ({
  customerName,
  setCustomerName,
  orderNumber,
  setOrderNumber,
  driver,
  setDriver,
  selectDriverOptions,
  selectVendorOptions,
  month,
  setMonth,
  vendorSelect,
  setVendorSelect,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  setPageType,
  orderStatus,
  urlFrom,
}) => {
  const { t } = useTranslation();
  const orderList = useSelector((state) => state.Order.orderList);
  const contextDispatch = useContext(DispatchContext);
  const dispatch = useDispatch();
  const contData = useContext(StateContext);
  const filterData = contData.orderFilterData;
  const login = useSelector((state) => state.Login.logData);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;

  let currentItems = [];
  let pageCount = 0;
  let slCount = 0;
  if (
    orderList &&
    orderList.data !== undefined &&
    Object.keys(orderList.data).length > 0
  ) {
    currentItems = orderList.data.slice(itemOffset, endOffset);
    pageCount = Math.ceil(orderList.data.length / itemsPerPage);
  }

  if (Object.keys(currentItems).length > 0) {
    const index = orderList.data.findIndex((itm) => {
      return itm._id === currentItems[0]._id;
    });
    slCount = index;
  }

  const selectStatusOptions = [
    {
      options: [
        { label: "Pending", value: "pending" },
        { label: "Packed", value: "packed" },
        // { label: "Shipped", value: "shipped" },
        { label: "Out For Delivery", value: "outForDelivery" },
        { label: "Delivered", value: "delivered" },
        { label: "Cancelled", value: "cancelled" },
      ],
    },
  ];

  const handleSearchOnChange = (search, key) => {
    if (key === "customerName") {
      setCustomerName(search);
    } else if (key === "orderNumber") {
      setOrderNumber(search);
    }
  };

  const handleDriverChange = (id, driver) => {
    let payload = { order: id, driver: driver };
    dispatch(updateOrderDriver(payload));
  };
  const handleStatusChange = (id, status) => {
    let payload = { order: id, status: status };
    dispatch(updateOrderStatus(payload));
  };

  const handleBtnSearch = () => {
    if (
      toDate !== null ||
      fromDate !== null ||
      month !== null ||
      customerName !== "" ||
      driver !== ""
    ) {
      let payload = {
        customerName: customerName,
        orderNumber: orderNumber,
        driver: driver ? driver.value : "",
        toDate:
          toDate !== null
            ? moment.utc(toDate).local().format("YYYY-MM-DD")
            : null,
        fromDate:
          fromDate !== null
            ? moment.utc(fromDate).local().format("YYYY-MM-DD")
            : null,
        month:
          month !== null ? moment.utc(month).local().format("YYYY-MM") : null,
        orderStatus: orderStatus,
        vendor: vendorSelect ? vendorSelect.value : "",
        urlFrom: urlFrom,
      };
      dispatch(listOrder(payload));
    }
  };

  const handleBtnReset = () => {
    setFromDate(null);
    setMonth(null);
    setToDate(null);
    setCustomerName("");
    setOrderNumber("");
    setDriver({ label: t("Select Driver"), value: "" });
    setVendorSelect({ label: t("Select Vendor"), value: "" });
    let payload = {
      customerName: "",
      orderNumber: "",
      driver: "",
      toDate: null,
      fromDate: null,
      month: null,
      orderStatus: orderStatus,
      urlFrom: urlFrom,
      vendor: "",
    };
    dispatch(listOrder(payload));
  };

  const loadOptions = (inputValue) => {
    var promise = new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve(filterDrivers(inputValue));
      }, 1000);
    });
    return promise;
  };

  const filterDrivers = (inputValue) => {
    return selectDriverOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadVendorOptions = (inputValue) => {
    var promise = new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve(filterVendors(inputValue));
      }, 1000);
    });
    return promise;
  };

  const filterVendors = (inputValue) => {
    return selectVendorOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handlePageClick = (event) => {
    let newOffset = 0;
    if (
      orderList &&
      orderList.data !== undefined &&
      Object.keys(orderList.data).length > 0
    ) {
      newOffset = (event.selected * itemsPerPage) % orderList.data.length;
    }
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  let tblCount = 0;
  const tblItems = (currentItems) => {
    return (
      <>
        <Table borderless>
          <thead>
            <tr>
              <th>{t('Sl No')}</th>
              {urlFrom === "sub" && <th>{t('Driver Name')}</th>}
              <th>{t('Customer Name')}</th>
              <th>{t('Vendor Name')}</th>
              <th>{t('D Charge')}</th>
              {/* {urlFrom === "sub" &&  */}
              <th>{t('P Charge')}</th>
              {/* } */}
              <th>{t('Delivery Date')}</th>
              {urlFrom === "main" && (
                <>
                  {/* <th>{t('Delivery Area')}</th> */}
                  <th>{t('Driver')}</th>
                </>
              )}
              <th>{t('Order ID')}</th>
              <th>{t('Delivery Status')}</th>
              <th className="action-head">{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {currentItems ? (
              currentItems.map((sec, i) => {
                slCount += 1;
                let v = "";
                if (sec.status === "pending") v = "Pending";
                // else if (sec.status === "shipped") v = "Shipped";
                else if (sec.status === "packed") v = "Packed";
                else if (sec.status === "outForDelivery")
                  v = "Out For Delivery";
                else if (sec.status === "delivered") v = "Delivered";
                else if (sec.status === "cancelled") v = "Cancelled";
                let secStat = { label: v, value: sec.status };
                let driverAss = {};
                if (sec && sec.driver) {
                  driverAss = {
                    label: sec.driver.name,
                    value: sec.driver._id,
                  };
                }
                return (
                  <tr key={i}>
                    <td className="td-white">{slCount}</td>
                    {urlFrom === "sub" && (
                      <td className="c-table__cell td-white">
                        {driverAss.label}
                      </td>
                    )}
                    <td className="c-table__cell td-white">
                      {sec.customerName}
                    </td>
                    <td>{sec.vendor && sec.vendor.name}</td>
                    <td className="c-table__cell td-white">
                      {sec.deliveryCharge} {sec.paymentType}
                    </td>
                    {/* {urlFrom === "sub" && ( */}
                      <td className="c-table__cell td-white">
                        {sec.packagePaymentType === "COD" && sec.amountToBeCollected} {sec.packagePaymentType}
                      </td>
                    {/* )} */}
                    <td className="c-table__cell td-white">
                      {moment(sec.deliveryDate).format("DD-MM-YYYY")}
                    </td>

                    {urlFrom === "main" && (
                      <>
                        {/* <td className="c-table__cell td-white">
                          {sec.dropoffAddress &&
                            sec.dropoffAddress.locationName + ","}
                          {sec.dropoffAddress &&
                            sec.dropoffAddress.buildingNumber}
                          <br />
                          {sec.dropoffAddress &&
                            sec.dropoffAddress.streetNumber + ","}
                          {sec.dropoffAddress &&
                            sec.dropoffAddress.municipality + ","}
                          {sec.dropoffAddress && sec.dropoffAddress.zoneNumber}
                        </td> */}

                        <td>
                          {login.vendor && login.vendor.is_admin === true ? (
                            <Select
                              value={driverAss}
                              onChange={(selected) =>
                                handleDriverChange(sec._id, selected.value)
                              }
                              options={selectDriverOptions}
                              classNamePrefix="select2-selection"
                            />
                          ) : (
                            driverAss.label
                          )}
                        </td>
                      </>
                    )}
                    <td className="td-white">{sec.orderNumber}</td>
                    <td>
                      {login.vendor && login.vendor.is_admin === true ? (
                        <Select
                          value={secStat}
                          onChange={(selected) =>
                            handleStatusChange(sec._id, selected.value)
                          }
                          options={selectStatusOptions}
                          classNamePrefix="select2-selection"
                          // menuIsOpen={i===0?true:false}
                        />
                      ) : (
                        secStat.label
                      )}
                    </td>
                    <td className="c-table__cell td-white">
                      <div className="actions">
                        <span className="black-edit material-icons">
                          <img
                            src={editImg}
                            alt=""
                            onClick={() => {
                              contextDispatch({
                                type: "order_edit",
                                payload: sec,
                              });
                              contextDispatch({
                                type: "order_filter",
                                payload: {
                                  customerName: customerName,
                                  orderNumber: orderNumber,
                                  // date: date,
                                  month: month,
                                  fromDate: fromDate,
                                  toDate: toDate,
                                  customActiveTab:
                                    filterData.customActiveTab !== undefined
                                      ? filterData.customActiveTab
                                      : "1",
                                  driver: driver,
                                  vendorSelect: vendorSelect,
                                },
                              });
                              setPageType("edit");
                            }}
                            style={{
                              color: "black",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                        <span className="black-edit material-icons">
                          <img
                            className="view-eye"
                            src={viewImg}
                            alt=""
                            title="Details"
                            onClick={() => {
                              contextDispatch({
                                type: "order_edit",
                                payload: sec,
                              });
                              contextDispatch({
                                type: "order_filter",
                                payload: {
                                  customerName: customerName,
                                  orderNumber: orderNumber,
                                  // date: date,
                                  month: month,
                                  fromDate: fromDate,
                                  toDate: toDate,
                                  customActiveTab:
                                    filterData.customActiveTab !== undefined
                                      ? filterData.customActiveTab
                                      : "1",
                                  driver: driver,
                                  vendorSelect: vendorSelect,
                                },
                              });
                              setPageType("view");
                            }}
                            style={{
                              color: "black",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                        <span className="black-edit material-icons">
                          <img
                            src={cancelImg}
                            alt=""
                            onClick={() => {
                              if (window.confirm("Cancel the order?")) {
                                handleStatusChange(sec._id, "cancelled");
                              }
                            }}
                            title="Cancel"
                            style={{
                              color: "blue",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3}>{t('No Records Found')}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <Fragment>
      <Row className="order-tabdata">
        <Col span={24}>
          <Row className="cntnt-top">
            <Col span={2}>
              <div className="filter-by">
                <img src={filterImg} alt="" />
                <p>{t('Filter by')}</p>
              </div>
            </Col>
            <Col xs={10} sm={10} md={10} lg={5} xl={4}>
              <div className="select-driver">
                <AsyncSelect
                  value={driver}
                  onChange={(selected) => setDriver(selected)}
                  loadOptions={loadOptions}
                />
              </div>
            </Col>
            {/* <Col xs={24} sm={6} md={6} lg={3} xl={2}>
              <div className="filter-coulmn">
                <img alt="" className="black-clendar" src={calendarImg} />
                <DatePicker
                  selected={month}
                  onChange={(month) => {
                    setMonth(month);
                    setFromDate(null);
                    setToDate(null);
                  }}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  placeholderText="This Month"
                />
              </div>
            </Col> */}
            {login.vendor && login.vendor.is_admin === true && (
              <Col xs={10} sm={10} md={10} lg={5} xl={4}>
                <div className="select-driver">
                  <AsyncSelect
                    value={vendorSelect}
                    onChange={(selected) => setVendorSelect(selected)}
                    loadOptions={loadVendorOptions}
                  />
                </div>
              </Col>
            )}
            <Col xs={24} sm={6} md={6} lg={6} xl={3}>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <p className="fromto">{t('From')}</p>
                </Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  <div className="filter-coulmn sizer-1">
                    <img alt="" className="black-clendar" src={calendarImg} />
                    <DatePicker
                      selected={fromDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setFromDate(date);
                        setMonth(null);
                        toDate === null && setToDate(date);
                      }}
                      placeholderText={t('Select Date')}
                      maxDate={toDate}
                    />
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={6} md={6} lg={6} xl={3}>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <p className="fromto">{t('To')}</p>
                </Col>
                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  <div className="filter-coulmn sizer-1">
                    <img alt="" className="black-clendar" src={calendarImg} />
                    <DatePicker
                      selected={toDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setToDate(date);
                        setMonth(null);
                        fromDate === null && setFromDate(date);
                      }}
                      placeholderText={t('Select Date')}
                      minDate={fromDate}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={6} md={6} lg={5} xl={4}>
              <div className="filter-coulmn big-srch">
                <img alt="" className="srch-icn" src={searchImg} />
                <input
                  className="filter-input"
                  type="text"
                  placeholder={t("Customer name/Order Number")}
                  value={customerName}
                  onChange={(e) =>
                    handleSearchOnChange(e.target.value, "customerName")
                  }
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} xl={2}>
              <div className="flex">
                <Button
                  onClick={() => {
                    handleBtnSearch();
                  }}
                  type="button"
                  className="ins-btn btn form-btn bgcolor1 ripple1"
                >
                  {t('Search')}
                </Button>
                <Button
                  onClick={() => {
                    handleBtnReset();
                  }}
                  type="button"
                  className="ins-btn btn form-btn bgcolor1 ripple1"
                >
                  {t('Reset')}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>{tblItems(currentItems)}</Row>
      <Row>
        <div className="pagination-div">
          <ReactPaginate
            activeClassName={"item active "}
            breakClassName={"item break-me "}
            breakLabel={"..."}
            containerClassName={"pagination"}
            disabledClassName={"disabled-page"}
            marginPagesDisplayed={2}
            nextClassName={"item next "}
            nextLabel={">>"}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            pageClassName={"item pagination-page "}
            pageRangeDisplayed={5}
            previousClassName={"item previous"}
            previousLabel={"<<"}
            renderOnZeroPageCount={null}
          />
        </div>
      </Row>
    </Fragment>
  );
};
export default OrderTableData;
