import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import couponService from "../../service/coupon/CouponService";
import {
  createCouponSuccess,
  createCouponError,
  updateCouponSuccess,
  updateCouponError,
  deleteCouponSuccess,
  deleteCouponError,
  listCouponSuccess,
  listCouponError,
  listCouponByVendorSuccess,
  listCouponByVendorError,
} from "./couponState";

function* createCoupon(action) {
  const data = yield call(couponService.createCoupon, action.payload);
  if (data.msg === "success") {
    yield put(createCouponSuccess(data.response));
  } else {
    yield put(createCouponError(data.response));
  }
}

function* updateCoupon(action) {
  const data = yield call(couponService.updateCoupon, action.payload);
  if (data.msg === "success") {
    yield put(updateCouponSuccess(data.response));
  } else {
    yield put(updateCouponError(data.response));
  }
}

function* deleteCoupon(action) {
  const data = yield call(couponService.deleteCoupon, action.payload);
  if (data.msg === "success") {
    yield put(deleteCouponSuccess(data.response));
  } else {
    yield put(deleteCouponError(data.response));
  }
}

function* listCoupon(action) {
  const data = yield call(couponService.listCoupon, action.payload);
  if (data.msg === "success") {
    yield put(listCouponSuccess(data.response));
  } else {
    yield put(listCouponError(data.response));
  }
}

function* listCouponByVendor(action) {
  const data = yield call(couponService.listCouponByVendorId, action.payload);
  if (data.msg === "success") {
    yield put(listCouponByVendorSuccess(data.response));
  } else {
    yield put(listCouponByVendorError(data.response));
  }
}

function* couponSaga() {
  yield takeEvery("coupon/createCoupon", createCoupon);
  yield takeLatest("coupon/listCoupon", listCoupon);
  yield takeEvery("coupon/updateCoupon", updateCoupon);
  yield takeEvery("coupon/deleteCoupon", deleteCoupon);
  yield takeEvery("coupon/listCouponByVendor", listCouponByVendor);
}

export default couponSaga;
