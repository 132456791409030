import React, { useEffect, Fragment, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import filterImg from "../../assets/images/filter-icon.png";
import deleteImg from "../../assets/images/Delete.svg";
import editImg from "../../assets/images/Edit.svg";
import cancelImg from "../../assets/images/cancel.svg";
import searchImg from "../../assets/images/search.svg";
import tickImg from "../../assets/images/tick.svg";
import {
  listDriver,
  deleteDriver,
  updateDriver,
  resetDriverAmount,
  resetFunction,
} from "../../store/driver/driverState";
//Toastify
import { toast } from "react-toastify";
import { DispatchContext } from "../../context/AppProvider";
import { Button, Col, Row } from "antd";
import { Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';

const DriverList = ({ setPageType }) => {
const { t } = useTranslation();
  const dispatch = useDispatch(),
    contextDispatch = useContext(DispatchContext);
  const driverList = useSelector((state) => state.Driver.driverList);
  const driverError = useSelector((state) => state.Driver.driverError);
  const login = useSelector((state) => state.Login.logData);
  const deleteDriverSuccess = useSelector(
    (state) => state.Driver.deleteDriverSuccess
  );
  const deleteDriverError = useSelector(
    (state) => state.Driver.deleteDriverError
  );
  
  const resetDriverAmountSuccess = useSelector(
    (state) => state.Driver.resetDriverAmountSuccess
  );
  const resetDriverAmountError = useSelector(
    (state) => state.Driver.resetDriverAmountError
  );

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;
  const endOffset = itemOffset + itemsPerPage;

  let currentItems = [];
  let pageCount = 0;
  let slCount = 0;
  if (
    driverList &&
    driverList.data !== undefined &&
    Object.keys(driverList.data).length > 0
  ) {
    currentItems = driverList.data.slice(itemOffset, endOffset);
    pageCount = Math.ceil(driverList.data.length / itemsPerPage);
  }

  if (Object.keys(currentItems).length > 0) {
    const index = driverList.data.findIndex((itm) => {
      return itm._id === currentItems[0]._id;
    });
    slCount = index;
  }

  useEffect(() => {
    if (deleteDriverSuccess && deleteDriverSuccess.success === true) {
      toast(deleteDriverSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteDriverSuccess, dispatch]);

  useEffect(() => {
    if (
      deleteDriverError &&
      deleteDriverError.data &&
      deleteDriverError.data.success === false
    ) {
      toast.error(deleteDriverError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteDriverError, dispatch]);

  useEffect(() => {
    if (resetDriverAmountSuccess && resetDriverAmountSuccess.success === true) {
      toast(resetDriverAmountSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [resetDriverAmountSuccess, dispatch]);

  useEffect(() => {
    if (
      resetDriverAmountError &&
      resetDriverAmountError.data &&
      resetDriverAmountError.data.success === false
    ) {
      toast.error(resetDriverAmountError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [resetDriverAmountError, dispatch]);

  useEffect(() => {
    dispatch(listDriver());
  }, [deleteDriverSuccess, dispatch]);

  useEffect(() => {
    if (driverError && driverError.data && driverError.data.success === false) {
      toast.error(driverError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [dispatch, driverError]);

  const handleSearchOnChange = (search) => {
    if (search !== "") {
      if (search.length >= 0) {
        let payload = { keyword: search };
        dispatch(listDriver(payload));
      }
    } else {
      dispatch(listDriver({ keyword: "" }));
    }
  };

  const deleteDriverAction = (id) => {
    dispatch(deleteDriver(id));
  };

  const resetDriverAmountFunction = (id) => {
    let payload = { id: id };
    dispatch(resetDriverAmount(payload));
  };

  const handleStatusChange = (id, status) => {
    let pLoad = { status: status };
    let payload = { driverId: id, data: pLoad };
    dispatch(updateDriver(payload));
  };

  const handlePageClick = (event) => {
    let newOffset = 0;
    if (
      driverList &&
      driverList.data !== undefined &&
      Object.keys(driverList.data).length > 0
    ) {
      newOffset = (event.selected * itemsPerPage) % driverList.data.length;
    }
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  let tblCount = 0;

  return (
    <Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          <Row className="common-head">
            <p className="head-p">{t('All Drivers')}</p>
            <Button
              onClick={() => {
                contextDispatch({
                  type: "driver_edit",
                  payload: {},
                });
                setPageType("add");
              }}
              type="button"
              className="top-btn btn form-btn bgcolor1 ripple1"
            >
              {t('ADD NEW DRIVER')}
            </Button>           

          </Row>
          <Row className="common-filter">
            <Col span={24}>
              <Row className="cntnt-top">
                <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                  <div className="filter-by">
                    <img src={filterImg} alt="" />
                    <p>{t('Filter by')}</p>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className="filter-coulmn big-srch">
                    <img alt="" className="srch-icn" src={searchImg} />
                    <input
                      className="filter-input"
                      type="text"
                      placeholder={t('Search')}
                      onChange={(e) => handleSearchOnChange(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Table borderless>
              <thead>
                <tr>
                  <th>{t('Sl No')}</th>
                  <th data-breakpoint="xs">{t('Name')}</th>
                  <th data-breakpoint="sm">{t('Phone')}</th>
                  <th data-breakpoint="xs">{t('License Plate No.')}</th>
                  <th data-breakpoint="md">{t('ID Number')}</th>
                  <th data-breakpoint="lg">{t('Vehicle Type')}</th>
                  {login.vendor && login.vendor.is_admin === true ? (
                    <>
                      <th data-breakpoint="md">{t('Amount Collected')}</th>
                      <th className="action-head" data-breakpoint="xs">
                        {t('Actions')}
                      </th>
                    </>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {currentItems ? (
                  currentItems.map((sec, i) => {
                    slCount += 1;
                    return (
                      <tr key={i}>
                        <td className="td-white">{slCount}</td>
                        <td className="c-table__cell td-white">{sec.name}</td>
                        <td className="c-table__cell td-white">{sec.phone}</td>
                        <td className="c-table__cell td-white">
                          {sec.licencePlateNo}
                        </td>
                        <td className="c-table__cell td-white">
                          {sec.IDNumber}
                        </td>
                        <td className="c-table__cell td-white">
                          {sec.vehicleType}
                        </td>
                        {login.vendor && login.vendor.is_admin === true ? (
                          <>
                            <td className="c-table__cell td-white">
                              {sec.amountCollected}
                              {sec.amountCollected > 0 ? (
                              <Button
                                onClick={() => {
                                  if (window.confirm("Reset amount to 0 ?")) {
                                    resetDriverAmountFunction(sec._id);
                                  }
                                }}
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  marginLeft:"10px",
                                }}
                                type="button"
                                className="top-btn btn form-btn bgcolor1 ripple1"
                              >
                                {t('Reset')}
                              </Button>
                              ):""}
                            </td>
                              <td className="c-table__cell td-white">
                                <div className="actions">
                                  {/* <span className="blue-eye material-icons">
                            visibility
                          </span> */}

                                  <span className="black-edit material-icons">
                                    <img
                                      src={editImg}
                                      alt=""
                                      onClick={() => {
                                        contextDispatch({
                                          type: "driver_edit",
                                          payload: sec,
                                        });
                                        setPageType("edit");
                                      }}
                                      style={{
                                        color: "black",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>

                                  <span className="black-edit material-icons">
                                    {sec.status === true ? (
                                      <img
                                        src={cancelImg}
                                        alt=""
                                        onClick={() => {
                                          handleStatusChange(sec._id, false);
                                        }}
                                        title="{t('DeActivate')}"
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={tickImg}
                                        alt=""
                                        onClick={() => {
                                          handleStatusChange(sec._id, true);
                                        }}
                                        title="Activate"
                                      />
                                    )}
                                  </span>

                                  <span className="black-edit material-icons">
                                    <img
                                      alt=""
                                      src={deleteImg}
                                      onClick={() => {
                                        if (window.confirm("Delete the item?")) {
                                          deleteDriverAction(sec._id);
                                        }
                                      }}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </div>
                              </td></>
                            ) : (
                              ""
                            )}
                          </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>{t("No Records Found")}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
          <Row>
            <div className="pagination-div">
              <ReactPaginate
                activeClassName={"item active "}
                breakClassName={"item break-me "}
                breakLabel={"..."}
                containerClassName={"pagination"}
                disabledClassName={"disabled-page"}
                marginPagesDisplayed={2}
                nextClassName={"item next "}
                nextLabel={">>"}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                pageClassName={"item pagination-page "}
                pageRangeDisplayed={5}
                previousClassName={"item previous"}
                previousLabel={"<<"}
                renderOnZeroPageCount={null}
              />
            </div>
          </Row>
        </Row>
      </div>
    </Fragment>
  );
};

export default DriverList;
