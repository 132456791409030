import React, { useState, useEffect, useContext } from "react";
//React Hook Form cleanly grabs the input values and returns them in a handleSubmit function.
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Redux
import { createVendor, updateVendor } from "../../store/vendor/vendorState";
import { uploadImage, resetImage } from "../../store/common/commonState";
import { useDispatch, useSelector } from "react-redux";
import { StateContext } from "../../context/AppProvider";
import axios from "axios";
import cancelImg from "../../assets/images/cancel.svg";
import plusImg from "../../assets/images/plus.svg";
import { Input } from "reactstrap";
import downloadSmallImg from "../../assets/images/download-small.svg";
import { Button, Card, Col, Row, Radio } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from 'react-i18next';

const VendorAdd = ({ pageType, setPageType }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });
  const dispatch = useDispatch();
  const contData = useContext(StateContext);
  const editVendorData = contData.vendorEditData;
  const [phoneNumber, setPhoneNumber] = useState(
    Object.keys(editVendorData).length > 0 ? editVendorData.phone : ""
  );
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };
  const [lat, setLat] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? ""
        : editVendorData.address.latitude
      : ""
  );
  const [long, setLong] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? ""
        : editVendorData.address.longitude
      : ""
  );
  const [buildingNo, setBuildingNo] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? ""
        : editVendorData.address.buildingNumber
      : ""
  );
  const [streetNo, setStreetNo] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? ""
        : editVendorData.address.streetNumber
      : ""
  );
  const [zoneNo, setZoneNo] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? ""
        : editVendorData.address.zoneNumber
      : ""
  );
  const [mapLink, setMapLink] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? ""
        : editVendorData.address.mapLink
      : ""
  );
  const [mapLat, setMapLat] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? editVendorData.address.latitude
        : ""
      : ""
  );
  const [mapLong, setMapLong] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? editVendorData.address.longitude
        : ""
      : ""
  );
  const [mapBuildingNo, setMapBuildingNo] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? editVendorData.address.buildingNumber
        : ""
      : ""
  );
  const [mapStreetNo, setMapStreetNo] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? editVendorData.address.streetNumber
        : ""
      : ""
  );
  const [mapZoneNo, setMapZoneNo] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? editVendorData.address.zoneNumber
        : ""
      : ""
  );
  const [mapLinkUrl, setMapLinkUrl] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? editVendorData.address.mapLink
        : ""
      : ""
  );
  const [errorValidation, setErrorValidation] = useState({
    phoneNumber: 0,
    buildingNumber: 0,
    streetNumber: 0,
    zoneNumber: 0,
    email: 0,
    latLngAdrs: 0,
    maplatLngAdrs: 0,
    mapBuildingNumber: 0,
    mapStreetNumber: 0,
    mapLinkUrl: 0,
  });
  const [uploadDoc, setUploadDoc] = useState([
    {
      docName: "",
      filePath: "",
      docType: "",
      fileUrl: "",
    },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [radioVal, setRadioVal] = useState(
    Object.keys(editVendorData).length > 0 && editVendorData.address
      ? editVendorData.address.hasOwnProperty("isBlueBoard") === true &&
        editVendorData.address.isBlueBoard === false
        ? "map"
        : "blueboard"
      : "blueboard"
  );
  const fileUploadSucs = useSelector((state) => state.Common.uploadData);
  const fileUploadError = useSelector((state) => state.Common.uploadError);

  useEffect(() => {
    dispatch(resetImage());
  }, []);

  useEffect(() => {
    if (
      Object.keys(fileUploadSucs).length > 0 &&
      fileUploadSucs.success === true
    ) {
      let docData = [...uploadDoc];
      docData[currentIndex].filePath = fileUploadSucs.result.Location;
      setUploadDoc(docData);
    }
  }, [fileUploadSucs]);

  useEffect(() => {
    if (Object.keys(fileUploadError).length > 0) {
      toast(fileUploadError.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [fileUploadError]);

  useEffect(() => {
    if (Object.keys(editVendorData).length > 0) {
      let upDoc = [...uploadDoc];
      editVendorData.docDetails.length > 0 &&
        editVendorData.docDetails.map((itm, i) => {
          return (upDoc[i] = {
            docName: itm.docName,
            filePath: itm.filePath,
            docType: itm.docType,
            fileUrl: itm.filePath,
          });
        });
      setUploadDoc(upDoc);
    }
  }, [editVendorData]);

  const submitForm = async (data) => {
    let valid = 0;
    let validPhone = 0;
    let validBuild = 0;
    let validStreet = 0;
    let validZone = 0;
    let validLatLng = 0;
    let validMapLatLng = 0;
    let validMapBuild = 0;
    let validMapStreet = 0;
    let validMapLinkUrl = 0;

    if (phoneNumber.length < 6) {
      valid = 1;
      validPhone = 1;
    }
    if (radioVal === "blueboard" && buildingNo === "") {
      valid = 1;
      validBuild = 1;
    }
    if (radioVal === "blueboard" && streetNo === "") {
      valid = 1;
      validStreet = 1;
    }
    if (radioVal === "blueboard" && zoneNo === "") {
      valid = 1;
      validZone = 1;
    }

    if (radioVal === "map" && mapBuildingNo === "") {
      valid = 1;
      validMapBuild = 1;
    }
    if (radioVal === "map" && mapStreetNo === "") {
      valid = 1;
      validMapStreet = 1;
    }
    if (radioVal === "map" && mapLinkUrl === "") {
      valid = 1;
      validMapLinkUrl = 1;
    }

    let latitude = "";
    let longitude = "";
    let mapLnk = "";

    if (radioVal === "blueboard") {
      latitude = lat.toString();
      longitude = long.toString();
      mapLnk = mapLink.toString();
      if (lat === "" || long === "") {
        if (buildingNo && streetNo && zoneNo) {
          let response = await axios.get(
            `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS_wgs84/MapServer/0/query?where=zone_no%3D${zoneNo}+and+street_no%3D${streetNo}+and+building_no%3D${buildingNo}&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=X_COORD%2CY_COORD&returnGeometry=false&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson`
          );
          if (
            Object.keys(response).length > 0 > 0 &&
            response.data &&
            Object.keys(response.data).length > 0 &&
            response.data.features &&
            response.data.features.length > 0 &&
            response.data.features[0].attributes &&
            Object.keys(response.data.features[0].attributes).length > 0
          ) {
            longitude = response.data.features[0].attributes.X_COORD;
            latitude = response.data.features[0].attributes.Y_COORD;
            mapLnk = `http://maps.google.com/maps?z=15&t=m&q=loc:${latitude}+${longitude}`;
          } else {
            valid = 1;
            validLatLng = 1;
          }
        }
      }
    } else if (radioVal === "map") {
      latitude = mapLat.toString();
      longitude = mapLong.toString();
      mapLnk = mapLinkUrl.toString();
      data.locationName = "";
      // data.municipality = "";
      // if (mapLat === "" || mapLong === "") {
        if (mapLinkUrl.includes("loc:")) {
          let urlSplit = mapLinkUrl.split(":");
          let latlng = urlSplit[2].split("+");
          latitude = latlng[0];
          longitude = latlng[1];
        } else {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCYNTS0ZJHytAmi_nDuFtnmxnN5bsObt3Q&address=${encodeURIComponent(
              mapLinkUrl
            )}`
          );
          if (response.ok) {
            const dataRes = await response.json();
            const location = dataRes.results[0].geometry.location;
            latitude = location.lat;
            longitude = location.lng;
          } else {
            valid = 1;
            validMapLatLng = 1;
          }
        }
      // }
    }

    if (valid === 0) {
      let upDocs = uploadDoc.map((itm, i) => {
        return {
          docName: itm.docName,
          filePath: itm.filePath,
          docType: itm.docType,
        };
      });
      let pLoad = {
        name: data.name,
        email: data.email,
        password: data.password,
        phone: phoneNumber,
        address: {
          locationName: data.locationName,
          latitude: latitude,
          longitude: longitude,
          buildingNumber: radioVal === "blueboard" ? buildingNo : mapBuildingNo,
          streetNumber: radioVal === "blueboard" ? streetNo : mapStreetNo,
          municipality: "", //data.municipality,
          zoneNumber: radioVal === "blueboard" ? zoneNo : mapZoneNo,
          mapLink: radioVal === "blueboard" ? mapLnk : mapLinkUrl,
          isBlueBoard: radioVal === "blueboard" ? true : false,
        },
        vehicleDetails: {
          carPrice: Number(data.carPrice),
          pickupPrice: Number(data.pickupPrice),
          twowheelerPrice: Number(data.twowheelerPrice),
        },
        bankDetails: {
          accountNumber: data.accountNumber,
          ifsc: data.ifsc,
          accHolderName: data.accHolderName,
          bankName: data.bankName,
          iban: data.iban,
          address: data.address,
        },
        docDetails: upDocs,
      };
      if (Object.keys(editVendorData).length === 0) {
        dispatch(createVendor(pLoad));
      } else {
        if (pLoad.password === "") {
          delete pLoad.password;
        }
        let payload = { vendorId: editVendorData._id, data: pLoad };
        dispatch(updateVendor(payload));
      }
    }
    setErrorValidation({
      ...errorValidation,
      phoneNumber: validPhone,
      buildingNumber: validBuild,
      streetNumber: validStreet,
      zoneNumber: validZone,
      latLngAdrs: validLatLng,
      maplatLngAdrs: validMapLatLng,
      mapBuildingNumber: validMapBuild,
      mapStreetNumber: validMapStreet,
      mapLinkUrl: validMapLinkUrl,
    });
  };

  const fetchMapLink = () => {
    if (buildingNo && streetNo && zoneNo) {
      axios
        .get(
          `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS_wgs84/MapServer/0/query?where=zone_no%3D${zoneNo}+and+street_no%3D${streetNo}+and+building_no%3D${buildingNo}&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=X_COORD%2CY_COORD&returnGeometry=false&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson`
        )
        .then((response) => {
          if (
            response.data &&
            Object.keys(response.data).length > 0 &&
            response.data.features &&
            response.data.features.length > 0 &&
            response.data.features[0].attributes &&
            Object.keys(response.data.features[0].attributes).length > 0
          ) {
            let xCord = response.data.features[0].attributes.X_COORD;
            let yCord = response.data.features[0].attributes.Y_COORD;
            let mapLnk = ` http://maps.google.com/maps?z=15&t=m&q=loc:${yCord}+${xCord}`;
            setLong(xCord);
            setLat(yCord);
            setMapLink(mapLnk);
            setErrorValidation({ ...errorValidation, latLngAdrs: 0 });
          } else {
            // toast.error("Invalid address given", {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            setLat("");
            setLong("");
            setMapLink("");
            setErrorValidation({ ...errorValidation, latLngAdrs: 1 });
          }
        })
        .catch((error) => {
          // toast.error("Invalid address given", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          setLat("");
          setLong("");
          setMapLink("");
          setErrorValidation({ ...errorValidation, latLngAdrs: 1 });
        });
    } else {
      setErrorValidation({ ...errorValidation, latLngAdrs: 1 });
    }
  };

  const fetchLatLng = async () => {
    try {
      if (mapLinkUrl.includes("loc:")) {
        let urlSplit = mapLinkUrl.split(":");
        let latlng = urlSplit[2].split("+");
        setMapLat(latlng[0]);
        setMapLong(latlng[1]);

        setErrorValidation({ ...errorValidation, maplatLngAdrs: 0 });
      } else {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCYNTS0ZJHytAmi_nDuFtnmxnN5bsObt3Q&address=${encodeURIComponent(
            mapLinkUrl
          )}`
        );
        if (response.ok) {
          const data = await response.json();
          const location = data.results[0].geometry.location;
          setMapLat(location.lat);
          setMapLong(location.lng);

          setErrorValidation({ ...errorValidation, maplatLngAdrs: 0 });
        } else {
          console.log("2");
          setMapLat("");
          setMapLong("");
          setErrorValidation({ ...errorValidation, maplatLngAdrs: 1 });
        }
      }
    } catch (error) {
      console.log("3");
      console.error("error");
      setMapLat("");
      setMapLong("");
      setErrorValidation({ ...errorValidation, maplatLngAdrs: 1 });
    }
  };

  const handelUploadFiles = (e, index) => {
    let docImg = [...uploadDoc];
    const up = new FormData();
    if (e) {
      docImg[index].fileUrl = URL.createObjectURL(e);
      setUploadDoc(docImg);
      up.append("files", e);
    }
    setCurrentIndex(index);
    dispatch(uploadImage(up));
  };

  const addDocument = (index) => {
    return (
      <img
        src={plusImg}
        alt=""
        onClick={() => {
          let newDoc = [...uploadDoc];
          if (newDoc[index].fileUrl === "") {
            alert("Please add a document");
          } else {
            newDoc.push({
              docName: "",
              filePath: "",
              docType: "",
              fileUrl: "",
            });
            setUploadDoc(newDoc);
          }
        }}
        style={{
          color: "blue",
          cursor: "pointer",
        }}
      />
    );
  };

  const removeDocument = (index) => {
    return (
      <img
        src={cancelImg}
        alt=""
        onClick={() => {
          let fname = "file" + index;
          document.getElementById(fname).value = "";
          let newDoc = [...uploadDoc];
          if (newDoc.length === 1) {
            newDoc = [
              {
                docName: "",
                filePath: "",
                docType: "",
                fileUrl: "",
              },
            ];
          } else {
            newDoc.splice(index, 1);
          }
          setUploadDoc(newDoc);
        }}
        style={{
          color: "blue",
          cursor: "pointer",
        }}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          <Row className="common-head">
            <p className="head-p">
              {Object.keys(editVendorData).length > 0 ? t("Edit Vendor") : t("Add Vendor")}
            </p>
            <Button
              onClick={() => {
                setPageType("list");
              }}
              type="button"
              className="top-btn btn form-btn bgcolor1 ripple1"
            >
              {t("List Vendors")}
            </Button>
          </Row>

          <Row className="com-cntnt">
            <form onSubmit={handleSubmit(submitForm)}>
              <Card className="form-card" bordered={false}>
                <Row>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Vendor Name')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="text"
                            placeholder={t("Name")}
                            defaultValue={
                              Object.keys(editVendorData).length > 0
                                ? editVendorData.name
                                : ""
                            }
                            {...register("name", {
                              required: "Please enter vendor name.",
                              onChange: (e) => {
                                e.target.value = e.target.value.replace(
                                  /[^a-z\u0600-\u06FF@~`!@#$%^&*()_ =+\\\\';:\"\\/?>.<,-]/gi,
                                  ""
                                );
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />{" "}
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t("Phone Number")}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <PhoneInput
                            country={"qa"}
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                          />
                          {errorValidation.phoneNumber === 1 && (
                            <p className="val-error">
                              Please enter phone number.
                            </p>
                          )}
                          {/* <input
                            className="field-input"
                            type="text"
                            maxLength={11}
                            placeholder="with country code(eg:974xxxxxxxx)"
                            defaultValue={
                              Object.keys(editVendorData).length > 0
                                ? editVendorData.phone
                                : ""
                            }
                            {...register("phone", {
                              required: "Please enter vendor phone.",
                              onChange: (e) => {
                                e.target.value = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="phone"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          /> */}
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Email')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="text"
                            placeholder={t("Email")}
                            defaultValue={
                              Object.keys(editVendorData).length > 0
                                ? editVendorData.email
                                : ""
                            }
                            {...register("email", {
                              required: "Please enter vendor email.",
                              onBlur: (e) => {
                                if (
                                  e.target.value &&
                                  !/\S+@\S+\.\S+/.test(e.target.value)
                                ) {
                                  setErrorValidation({
                                    ...errorValidation,
                                    email: 1,
                                  });
                                } else {
                                  setErrorValidation({
                                    ...errorValidation,
                                    email: 0,
                                  });
                                }
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                          {errorValidation.email === 1 && (
                            <p className="val-error">Email is invalid</p>
                          )}
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Account Holder Name')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="text"
                            placeholder={t("Account Holder Name")}
                            defaultValue={
                              Object.keys(editVendorData).length > 0 &&
                              editVendorData.bankDetails
                                ? editVendorData.bankDetails.accHolderName
                                : ""
                            }
                            {...register("accHolderName", {
                              required: "Please enter account holder name.",
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="accHolderName"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Bank Name')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="text"
                            placeholder={t("Bank Name")}
                            defaultValue={
                              Object.keys(editVendorData).length > 0 &&
                              editVendorData.bankDetails
                                ? editVendorData.bankDetails.bankName
                                : ""
                            }
                            {...register("bankName", {
                              required: "Please enter bank name.",
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="bankName"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Account Number')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="text"
                            placeholder={t("Account Number")}
                            defaultValue={
                              Object.keys(editVendorData).length > 0 &&
                              editVendorData.bankDetails
                                ? editVendorData.bankDetails.accountNumber
                                : ""
                            }
                            {...register("accountNumber", {
                              required: "Please enter account number.",
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="accountNumber"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('IBAN')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="text"
                            placeholder={t("IBAN")}
                            defaultValue={
                              Object.keys(editVendorData).length > 0 &&
                              editVendorData.bankDetails
                                ? editVendorData.bankDetails.iban
                                : ""
                            }
                            {...register("iban", {
                              required: "Please enter IBAN.",
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="iban"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Swift Code')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="text"
                            placeholder={t("Swift Code")}
                            defaultValue={
                              Object.keys(editVendorData).length > 0 &&
                              editVendorData.bankDetails
                                ? editVendorData.bankDetails.ifsc
                                : ""
                            }
                            {...register("ifsc", {
                              required: "Please enter Swift code.",
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="ifsc"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Bank Address')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="text"
                            placeholder={t('Bank Address')}
                            defaultValue={
                              Object.keys(editVendorData).length > 0 &&
                              editVendorData.bankDetails
                                ? editVendorData.bankDetails.address
                                : ""
                            }
                            {...register("address")}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="ifsc"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">
                          {t("Password")}
                          {Object.keys(editVendorData).length > 0 &&
                            " (Enter if you need to change)"}
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="password"
                            placeholder={t("Password")}
                            {...register("password")}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="password"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                        <p className="label-text">{t("Vehicle Price")}</p>
                      </Col>
                      <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <div className="vehicle-driver">
                          <div className="radio">
                            <label htmlFor="html">
                              <span className="field-icn material-icons">
                                directions_car
                              </span>
                            </label>
                            <div className="field-coulmn mr-5">
                              <input
                                className="field-input"
                                type="number"
                                name="carPrice"
                                placeholder={t("Car Price")}
                                defaultValue={
                                  Object.keys(editVendorData).length > 0 &&
                                  editVendorData.vehicleDetails
                                    ? editVendorData.vehicleDetails.carPrice
                                    : 20
                                }
                                {...register("carPrice")}
                              />
                            </div>
                            <label htmlFor="html">
                              <span className="field-icn material-icons">
                                local_shipping
                              </span>
                            </label>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                name="pickupPrice"
                                type="number"
                                placeholder={t("Pickup Price")}
                                defaultValue={
                                  Object.keys(editVendorData).length > 0 &&
                                  editVendorData.vehicleDetails
                                    ? editVendorData.vehicleDetails.pickupPrice
                                    : 20
                                }
                                {...register("pickupPrice")}
                              />
                            </div>
                            <label htmlFor="html">
                              <span className="field-icn material-icons">
                                moped
                              </span>
                            </label>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                name="twowheelerPrice"
                                type="number"
                                placeholder={t("Two Wheeler Price")}
                                defaultValue={
                                  Object.keys(editVendorData).length > 0 &&
                                  editVendorData.vehicleDetails
                                    ? editVendorData.vehicleDetails
                                        .twowheelerPrice
                                    : 20
                                }
                                {...register("twowheelerPrice")}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Card>

              <Card className="form-card" bordered={false}>
                <Row>
                  <Radio.Group
                    onChange={(e) => {
                      setRadioVal(e.target.value);
                    }}
                    value={radioVal}
                    style={{
                      marginBottom: 8,
                    }}
                  >
                    <Radio.Button value="blueboard">{t('BlueBoard')}</Radio.Button>
                    <Radio.Button value="map">{t('Map')}</Radio.Button>
                  </Radio.Group>
                </Row>
                <Row>
                  {radioVal === "blueboard" && (
                    <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                      <Col span={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t('Location Name')}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Location Name")}
                                defaultValue={
                                  Object.keys(editVendorData).length > 0 &&
                                  editVendorData.address
                                    ? editVendorData.address.locationName
                                    : ""
                                }
                                {...register("locationName", {
                                  required: "Please enter location name.",
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="locationName"
                                render={({ message }) => (
                                  <p className="val-error">{message}</p>
                                )}
                              />
                            </div>
                          </Col>
                        </div>
                      </Col>
                      {/* <Col span={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">Municipality</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Municipality")}
                                defaultValue={
                                  Object.keys(editVendorData).length > 0 &&
                                  editVendorData.address
                                    ? editVendorData.address.municipality
                                    : ""
                                }
                                {...register("municipality")}
                              />
                            </div>
                          </Col>
                        </div>
                      </Col> */}
                      <Col span={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t('Building Number')}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Building Number")}
                                value={buildingNo}
                                onChange={(e) => {
                                  setBuildingNo(e.target.value);
                                  setLat("");
                                  setLong("");
                                  setMapLink("");
                                }}
                              />
                              {errorValidation.buildingNumber === 1 && (
                                <p className="val-error">
                                  Please enter building number.
                                </p>
                              )}
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t('Street Number')}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Street Number")}
                                value={streetNo}
                                onChange={(e) => {
                                  setStreetNo(e.target.value);
                                  setLat("");
                                  setLong("");
                                  setMapLink("");
                                }}
                              />
                              {errorValidation.streetNumber === 1 && (
                                <p className="val-error">
                                  Please enter street number.
                                </p>
                              )}
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t('Zone Number')}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Zone Number")}
                                value={zoneNo}
                                onChange={(e) => {
                                  setZoneNo(e.target.value);
                                  setLat("");
                                  setLong("");
                                  setMapLink("");
                                }}
                              />
                              {errorValidation.zoneNumber === 1 && (
                                <p className="val-error">
                                  Please enter zone number.
                                </p>
                              )}
                            </div>
                          </Col>
                        </div>
                      </Col>
                      {errorValidation.latLngAdrs === 1 && (
                        <Col span={24}>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12} push={3}>
                            <p className="val-error">
                              Invalid Building/Street/Zone Number
                            </p>
                          </Col>
                        </Col>
                      )}
                      <Col span={24} push={8}>
                        <Button
                          className="flex outline-btn"
                          color="info"
                          onClick={() => fetchMapLink()}
                        >
                          <p>{t('Generate Map')}</p>
                        </Button>
                      </Col>
                      <Col span={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">
                              {t("Map Link")}
                              {mapLink !== "" && (
                                <a
                                  target="_blank"
                                  href={mapLink}
                                  className="ml-5"
                                  rel="noreferrer"
                                >
                                  {t("Show map")}
                                </a>
                              )}
                            </p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Map Link")}
                                value={mapLink}
                                readOnly
                                onChange={(e) => setMapLink(e.target.value)}
                              />
                            </div>
                          </Col>
                        </div>
                      </Col>
                    </Col>
                  )}
                  {radioVal === "map" && (
                    <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                      <Col span={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t("House/Flat/Block No")}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("House/Flat/Block No")}
                                value={mapBuildingNo}
                                onChange={(e) => {
                                  setMapBuildingNo(e.target.value);
                                  // setLat("");
                                  // setLong("");
                                  // setMapLink("");
                                }}
                              />
                              {errorValidation.mapBuildingNumber === 1 && (
                                <p className="val-error">
                                  Please enter House/Flat/Block No.
                                </p>
                              )}
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t("Address Line 1")}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Address Line 1")}
                                value={mapStreetNo}
                                onChange={(e) => {
                                  setMapStreetNo(e.target.value);
                                  // setLat("");
                                  // setLong("");
                                  // setMapLink("");
                                }}
                              />
                              {errorValidation.mapStreetNumber === 1 && (
                                <p className="val-error">
                                  Please enter address line 1.
                                </p>
                              )}
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">
                              {t("Address Line 2 (optional)")}
                            </p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Address Line 2 (optional)")}
                                value={mapZoneNo}
                                onChange={(e) => {
                                  setMapZoneNo(e.target.value);
                                  // setLat("");
                                  // setLong("");
                                  // setMapLink("");
                                }}
                              />
                            </div>
                          </Col>
                        </div>
                      </Col>

                      <Col span={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">
                              Map Link (Google Map FUll URL)
                            </p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Map Link")}
                                value={mapLinkUrl}
                                onChange={(e) => setMapLinkUrl(e.target.value)}
                              />
                            </div>
                          </Col>
                        </div>
                      </Col>
                      {errorValidation.mapLinkUrl === 1 && (
                        <p className="val-error">Please enter map link url.</p>
                      )}
                      {errorValidation.maplatLngAdrs === 1 && (
                        <Col span={24}>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12} push={3}>
                            <p className="val-error">
                              Please enter valid google map url
                            </p>
                          </Col>
                        </Col>
                      )}
                      <Col span={24} push={8}>
                        <Button
                          className="flex outline-btn"
                          color="info"
                          onClick={() => fetchLatLng()}
                        >
                          <p>{t("Verify")}</p>
                        </Button>
                      </Col>
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <iframe
                      width="100%"
                      title="googlemap"
                      height="250"
                      name="gMap"
                      loading="lazy"
                      src={`https://www.google.com/maps/embed/v1/place?q=${
                        radioVal === "blueboard" ? lat : mapLat
                      },${radioVal === "blueboard" ? long : mapLong}&key=${
                        process.env.REACT_APP_GOOGLE_API_KEY
                      }`}
                    ></iframe>
                  </Col>
                  <Col xs={24} sm={14} md={14} lg={14} xl={14}>
                    {uploadDoc.map((item, i) => {
                      return (
                        <div key={i} className="row new-field mb-20">
                          {i === 0 && (
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <p className="label-text">{t("Upload Documents")}</p>
                            </Col>
                          )}
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row>
                              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <div className="field-coulmn mr-5 mb-2">
                                  <Input
                                    id="docName"
                                    name="docName"
                                    type="text"
                                    value={uploadDoc[i].docName}
                                    onChange={(e) => {
                                      let docImg = [...uploadDoc];
                                      docImg[i].docName = e.target.value;
                                      setUploadDoc(docImg);
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <div className="field-coulmn mb-2">
                                  <Input
                                    id={`file${i}`}
                                    name="file"
                                    type="file"
                                    onChange={(e) => {
                                      if (e.target.files) {
                                        if (e.target.files.length) {
                                          handelUploadFiles(
                                            e.target.files[0],
                                            i
                                          );
                                        } else {
                                          handelUploadFiles(false, i);
                                        }
                                      }
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} sm={4} md={4} lg={2} xl={2}>
                                {uploadDoc[i].fileUrl ? (
                                  <a
                                    href={uploadDoc[i].fileUrl}
                                    target="_blank"
                                    download={false}
                                    rel="noreferrer"
                                  >
                                    <img
                                      alt=""
                                      className="download-icon"
                                      src={downloadSmallImg}
                                    />
                                  </a>
                                ) : null}
                              </Col>
                              <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                                <div className="field-coulmn">
                                  {uploadDoc.length - 1 === i ? (
                                    <Row>
                                      <Col span={12}>{addDocument(i)}</Col>
                                      <Col span={12}>{removeDocument(i)}</Col>
                                    </Row>
                                  ) : (
                                    <Col span={12}>{removeDocument(i)}</Col>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
                <div className="row btn-float-right">
                  <div className="btn-field">
                    <button
                      type="submit"
                      className="btn form-btn bgcolor1 ripple1 mr-5"
                    >
                      {t("Save")}
                    </button>
                    <button
                      onClick={() => setPageType("list")}
                      type="button"
                      className="btn  form-btn bgcolor3 ripple3"
                    >
                      {t('Cancel')}
                    </button>
                  </div>
                </div>
              </Card>
            </form>
          </Row>
        </Row>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default VendorAdd;
