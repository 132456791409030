// import axios from "axios";
// const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
import axiosInstance from "../ApiHelper";
const UPLOAD_URL = `${process.env.REACT_APP_UPLOAD_URL}`;

class commonService {
  constructor(callback) {
    this.callback = callback;
  }

  async uploadImage(payload) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const response = await axiosInstance
      .post(`${UPLOAD_URL}files`, payload, {
        headers: headers,
      })
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async paymentReturnStatus(payload) {
    const response = await axiosInstance
      .get(`vendor/payment/status/${payload.paymentId}`)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async fetchSettingsData() {
    const response = await axiosInstance
      .get("settingsData")
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async updatePickupTime(payload) {
    const response = await axiosInstance
      .post("pickupTime", payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async fetchAllDocMaster(payload) {
    const response = await axiosInstance
      .get(`doc/master/${payload.type}`)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async updateWeightLimit(payload) {
    const response = await axiosInstance
      .post("weightLimit", payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }
}

export default new commonService();
