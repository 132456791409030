// import axios from "axios";
// const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
import axiosInstance from "../ApiHelper";


class customerService {
  constructor(callback) {
    this.callback = callback;
  }

  async createCustomer(payload) {
    const response = await axiosInstance
      .post(`customer/create`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async updateCustomer(payload) {
    const response = await axiosInstance
      .put(`customer/${payload.customerId}`, payload.data)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async listCustomer(payload) {
    const response = await axiosInstance
      .post(`customers`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async deleteCustomer(payload) {
    const response = await axiosInstance
      .delete(`customer/${payload}`)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async listCustomerByVendorId(payload) {
    const response = await axiosInstance
      .post(`customer/byvendor`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }
}

export default new customerService();
