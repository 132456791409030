import React, { useState, useEffect, useContext } from "react";
//React Hook Form cleanly grabs the input values and returns them in a handleSubmit function.
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Redux
import {
  createCustomer,
  resetFunction,
  updateCustomer,
} from "../../store/customer/customerState";
import { listVendor } from "../../store/vendor/vendorState";
import { useDispatch, useSelector } from "react-redux";
import { StateContext } from "../../context/AppProvider";
import Select from "react-select";
import axios from "axios";
import { Button, Card, Col, Row, Radio } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from 'react-i18next';
const CustomerAdd = ({ pageType, setPageType }) => {
  const { t } = useTranslation();
  const vendorList = useSelector((state) => state.Vendor.vendorList);
  const login = useSelector((state) => state.Login.logData);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });
  const dispatch = useDispatch();
  const contData = useContext(StateContext);
  const editData = contData.customerEditData;
  const [selectVendorOptions, setSelectVendorOptions] = useState([]);
  const [vendorSelected, setVendorSelected] = useState({
    label: t("Select Vendor"),
    value: "",
  });
  const [phoneNumber, setPhoneNumber] = useState(
    Object.keys(editData).length > 0 ? editData.phone : ""
  );
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };
  const [lat, setLat] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? ""
        : editData.address.latitude
      : ""
  );
  const [long, setLong] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? ""
        : editData.address.longitude
      : ""
  );
  const [buildingNo, setBuildingNo] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? ""
        : editData.address.buildingNumber
      : ""
  );
  const [streetNo, setStreetNo] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? ""
        : editData.address.streetNumber
      : ""
  );
  const [zoneNo, setZoneNo] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? ""
        : editData.address.zoneNumber
      : ""
  );
  const [mapLink, setMapLink] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? ""
        : editData.address.mapLink
      : ""
  );

  const [mapLat, setMapLat] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? editData.address.latitude
        : ""
      : ""
  );
  const [mapLong, setMapLong] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? editData.address.longitude
        : ""
      : ""
  );
  const [mapBuildingNo, setMapBuildingNo] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? editData.address.buildingNumber
        : ""
      : ""
  );
  const [mapStreetNo, setMapStreetNo] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? editData.address.streetNumber
        : ""
      : ""
  );
  const [mapZoneNo, setMapZoneNo] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? editData.address.zoneNumber
        : ""
      : ""
  );
  const [mapLinkUrl, setMapLinkUrl] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? editData.address.mapLink
        : ""
      : ""
  );

  const [errorValidation, setErrorValidation] = useState({
    phoneNumber: 0,
    vendorError: 0,
    buildingNumber: 0,
    streetNumber: 0,
    zoneNumber: 0,
    latLngAdrs: 0,
    maplatLngAdrs: 0,
    mapBuildingNumber: 0,
    mapStreetNumber: 0,
    mapLinkUrl: 0,
  });
  const [radioVal, setRadioVal] = useState(
    Object.keys(editData).length > 0 && editData.address
      ? editData.address.hasOwnProperty("isBlueBoard") === true &&
        editData.address.isBlueBoard === false
        ? "map"
        : "blueboard"
      : "blueboard"
  );

  useEffect(() => {
    dispatch(listVendor());
  }, [dispatch]);

  const submitForm = async (data) => {
    let valid = 0;
    let validVendor = 0;
    let validBuild = 0;
    let validPhone = 0;
    let validStreet = 0;
    let validZone = 0;
    let validLatLng = 0;
    let validMapLatLng = 0;
    let validMapBuild = 0;
    let validMapStreet = 0;
    let validMapLinkUrl = 0;

    if (phoneNumber.length < 6) {
      valid = 1;
      validPhone = 1;
    }

    if (
      login.vendor &&
      login.vendor.is_admin === true &&
      vendorSelected.value === ""
    ) {
      valid = 1;
      validVendor = 1;
    }

    if (radioVal === "blueboard" && buildingNo === "") {
      valid = 1;
      validBuild = 1;
    }
    if (radioVal === "blueboard" && streetNo === "") {
      valid = 1;
      validStreet = 1;
    }
    if (radioVal === "blueboard" && zoneNo === "") {
      valid = 1;
      validZone = 1;
    }

    if (radioVal === "map" && mapBuildingNo === "") {
      valid = 1;
      validMapBuild = 1;
    }
    if (radioVal === "map" && mapStreetNo === "") {
      valid = 1;
      validMapStreet = 1;
    }
    if (radioVal === "map" && mapLinkUrl === "") {
      valid = 1;
      validMapLinkUrl = 1;
    }

    let latitude = "";
    let longitude = "";
    let mapLnk = "";
    if (radioVal === "blueboard") {
      latitude = lat.toString();
      longitude = long.toString();
      mapLnk = mapLink.toString();
      if (lat === "" || long === "") {
        if (buildingNo && streetNo && zoneNo) {
          let response = await axios.get(
            `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS_wgs84/MapServer/0/query?where=zone_no%3D${zoneNo}+and+street_no%3D${streetNo}+and+building_no%3D${buildingNo}&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=X_COORD%2CY_COORD&returnGeometry=false&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson`
          );
          if (
            Object.keys(response).length > 0 > 0 &&
            response.data &&
            Object.keys(response.data).length > 0 &&
            response.data.features &&
            response.data.features.length > 0 &&
            response.data.features[0].attributes &&
            Object.keys(response.data.features[0].attributes).length > 0
          ) {
            longitude = response.data.features[0].attributes.X_COORD;
            latitude = response.data.features[0].attributes.Y_COORD;
            mapLnk = `http://maps.google.com/maps?z=15&t=m&q=loc:${latitude}+${longitude}`;
          } else {
            valid = 1;
            validLatLng = 1;
          }
        }
      }
    } else if (radioVal === "map") {
      latitude = mapLat.toString();
      longitude = mapLong.toString();
      mapLnk = mapLinkUrl.toString();
      data.locationName = "";
      // data.municipality = "";
      // if (mapLat === "" || mapLong === "") {
        if (mapLinkUrl.includes("loc:")) {
          let urlSplit = mapLinkUrl.split(":");
          let latlng = urlSplit[2].split("+");

          latitude = latlng[0];
          longitude = latlng[1];
        } else {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCYNTS0ZJHytAmi_nDuFtnmxnN5bsObt3Q&address=${encodeURIComponent(
              mapLinkUrl
            )}`
          );
          if (response.ok) {
            const data = await response.json();
            const location = data.results[0].geometry.location;
            latitude = location.lat;
            longitude = location.lng;
          } else {
            valid = 1;
            validMapLatLng = 1;
          }
        // }
      }
    }

    if (valid === 0) {
      let pLoad = {
        vendor:
          login.vendor && login.vendor.is_admin === true
            ? vendorSelected.value
            : login.vendor.id,
        name: data.name,
        phone: phoneNumber,
        address: {
          locationName: data.locationName,
          latitude: latitude,
          longitude: longitude,
          buildingNumber: radioVal === "blueboard" ? buildingNo : mapBuildingNo,
          streetNumber: radioVal === "blueboard" ? streetNo : mapStreetNo,
          municipality: "", //data.municipality,
          zoneNumber: radioVal === "blueboard" ? zoneNo : mapZoneNo,
          mapLink: radioVal === "blueboard" ? mapLnk : mapLinkUrl,
          isBlueBoard: radioVal === "blueboard" ? true : false,
        },
      };
      if (Object.keys(editData).length === 0) {
        dispatch(createCustomer(pLoad));
      } else {
        let payload = { customerId: editData._id, data: pLoad };
        dispatch(updateCustomer(payload));
      }
    }
    setErrorValidation({
      ...errorValidation,
      vendorError: validVendor,
      buildingNumber: validBuild,
      phoneNumber: validPhone,
      streetNumber: validStreet,
      zoneNumber: validZone,
      latLngAdrs: validLatLng,
      maplatLngAdrs: validMapLatLng,
      mapBuildingNumber: validMapBuild,
      mapStreetNumber: validMapStreet,
      mapLinkUrl: validMapLinkUrl,
    });
  };

  // const customer = useSelector((state) => state.Customer.createCustomerStatus);
  // const error = useSelector(
  //   (state) => state.Customer.createCustomerStatusError
  // );

  // useEffect(() => {
  //   if (customer && customer.success === true) {
  //     toast(customer.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     dispatch(resetFunction());
  //     setPageType("list");
  //   }
  // }, [customer]);

  // useEffect(() => {
  //   if (error && error.data && error.data.success === false) {
  //     toast.error(error.data.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // }, [error]);

  useEffect(() => {
    if (vendorList && vendorList && vendorList.success === true) {
      let data = vendorList.data;
      const vendorOptions = data.map((item) => ({
        label: item.name + "(" + item.phone + ")", // <-- input values you are matching
        value: item._id,
      }));
      setSelectVendorOptions(vendorOptions);

      if (Object.keys(editData).length > 0) {
        if (editData.vendor) {
          let v = {
            label: editData.vendor.name + "(" + editData.vendor.phone + ")", // <-- input values you are matching
            value: editData.vendor._id,
          };
          setVendorSelected(v);
        }
      }
    }
  }, [dispatch, vendorList]);

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      if (editData.vendor) {
        let v = {
          label: editData.vendor.name + "(" + editData.vendor.phone + ")", // <-- input values you are matching
          value: editData.vendor._id,
        };
        setVendorSelected(v);
      }
      setLat(
        editData.address
          ? editData.address.hasOwnProperty("isBlueBoard") === true &&
            editData.address.isBlueBoard === false
            ? ""
            : editData.address.latitude
          : ""
      );
      setLong(
        editData.address
          ? editData.address.hasOwnProperty("isBlueBoard") === true &&
            editData.address.isBlueBoard === false
            ? ""
            : editData.address.longitude
          : ""
      );
      setMapLink(
        editData.address
          ? editData.address.hasOwnProperty("isBlueBoard") === true &&
            editData.address.isBlueBoard === false
            ? ""
            : editData.address.mapLink
          : ""
      );
      setMapLat(
        editData.address
          ? editData.address.hasOwnProperty("isBlueBoard") === true &&
            editData.address.isBlueBoard === false
            ? editData.address.latitude
            : ""
          : ""
      );
      setMapLong(
        editData.address
          ? editData.address.hasOwnProperty("isBlueBoard") === true &&
            editData.address.isBlueBoard === false
            ? editData.address.longitude
            : ""
          : ""
      );
      setMapLinkUrl(
        editData.address
          ? editData.address.hasOwnProperty("isBlueBoard") === true &&
            editData.address.isBlueBoard === false
            ? editData.address.mapLink
            : ""
          : ""
      );
    }
  }, [editData]);

  const handleVendorChange = (val) => {
    setVendorSelected(val);
  };

  const fetchMapLink = () => {
    if (buildingNo && streetNo && zoneNo) {
      axios
        .get(
          `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS_wgs84/MapServer/0/query?where=zone_no%3D${zoneNo}+and+street_no%3D${streetNo}+and+building_no%3D${buildingNo}&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=X_COORD%2CY_COORD&returnGeometry=false&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson`
        )
        .then((response) => {
          if (
            response.data &&
            Object.keys(response.data).length > 0 &&
            response.data.features &&
            response.data.features.length > 0 &&
            response.data.features[0].attributes &&
            Object.keys(response.data.features[0].attributes).length > 0
          ) {
            let xCord = response.data.features[0].attributes.X_COORD;
            let yCord = response.data.features[0].attributes.Y_COORD;
            let mapLnk = ` http://maps.google.com/maps?z=15&t=m&q=loc:${yCord}+${xCord}`;
            setLong(xCord);
            setLat(yCord);
            setMapLink(mapLnk);
            setErrorValidation({ ...errorValidation, latLngAdrs: 0 });
          } else {
            // toast.error("Invalid address given", {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            setLat("");
            setLong("");
            setMapLink("");
            setErrorValidation({ ...errorValidation, latLngAdrs: 1 });
          }
        })
        .catch((error) => {
          // toast.error("Invalid address given", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          setLat("");
          setLong("");
          setMapLink("");
          setErrorValidation({ ...errorValidation, latLngAdrs: 1 });
        });
    } else {
      setErrorValidation({ ...errorValidation, latLngAdrs: 1 });
    }
  };

  const fetchLatLng = async () => {
    try {
      if (mapLinkUrl.includes("loc:")) {
        let urlSplit = mapLinkUrl.split(":");
        let latlng = urlSplit[2].split("+");
        setMapLat(latlng[0]);
        setMapLong(latlng[1]);

        setErrorValidation({ ...errorValidation, maplatLngAdrs: 0 });
      } else {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCYNTS0ZJHytAmi_nDuFtnmxnN5bsObt3Q&address=${encodeURIComponent(
            mapLinkUrl
          )}`
        );
        if (response.ok) {
          const data = await response.json();
          const location = data.results[0].geometry.location;
          setMapLat(location.lat);
          setMapLong(location.lng);

          setErrorValidation({ ...errorValidation, maplatLngAdrs: 0 });
        } else {
          setMapLat("");
          setMapLong("");
          setErrorValidation({ ...errorValidation, maplatLngAdrs: 1 });
        }
      }
    } catch (error) {
      console.error("error");
      setMapLat("");
      setMapLong("");
      setErrorValidation({ ...errorValidation, maplatLngAdrs: 1 });
    }
  };

  return (
    <React.Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          <Row className="common-head">
            <p className="head-p">
              {Object.keys(editData).length > 0 ? t("Edit Customer"): t("Add Customer")}
            </p>
            <Button
              onClick={() => {
                setPageType("list");
              }}
              type="button"
              className="top-btn btn form-btn bgcolor1 ripple1"
            >
              {t('LIST CUSTOMERS')}
            </Button>
          </Row>

          <Row className="com-cntnt">
            <form onSubmit={handleSubmit(submitForm)}>
              <Card className="form-card" bordered={false}>
                <Row>
                  {login.vendor && login.vendor.is_admin === true && (
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                      <div className="row new-field">
                        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                          <p className="label-text">{t('Vendor')}</p>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                          <div className="field-coulmn">
                            <Select
                              value={vendorSelected}
                              onChange={(selected) =>
                                handleVendorChange(selected)
                              }
                              options={selectVendorOptions}
                              classNamePrefix="select2-selection"
                            />
                            {/* <ErrorMessage
                            errors={errors}
                            name="vendor"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          /> */}
                            {errorValidation.vendorError === 1 && (
                              <p className="val-error">
                                Please select a vendor.
                              </p>
                            )}
                          </div>
                        </Col>
                      </div>
                    </Col>
                  )}
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Name')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="text"
                            placeholder={t("Name")}
                            defaultValue={
                              Object.keys(editData).length > 0
                                ? editData.name
                                : ""
                            }
                            {...register("name", {
                              required: "Please enter name.",
                              onChange: (e) => {
                                e.target.value = e.target.value.replace(
                                  /[^a-z\u0600-\u06FF@~`!@#$%^&*()_ =+\\\\';:\"\\/?>.<,-]/gi,
                                  ""
                                );
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t("Phone Number")}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <PhoneInput
                            country={"qa"}
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                          />
                          {errorValidation.phoneNumber === 1 && (
                            <p className="val-error">
                              Please enter phone number.
                            </p>
                          )}
                          {/* <input
                            className="field-input"
                            type="text"
                            maxLength={11}
                            placeholder="with country code(eg:974xxxxxxxx)"
                            defaultValue={
                              Object.keys(editData).length > 0
                                ? editData.phone
                                : ""
                            }
                            {...register("phone", {
                              required: "Please enter Phone Number.",
                              onChange: (e) => {
                                e.target.value = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                              },
                            })}
                          /> 
                          <ErrorMessage
                            errors={errors}
                            name="phone"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />*/}
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-18">
                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Row>
                      <Radio.Group
                        onChange={(e) => {
                          setRadioVal(e.target.value);
                        }}
                        value={radioVal}
                        style={{
                          marginBottom: 8,
                        }}
                      >
                        <Radio.Button value="blueboard">{t('BlueBoard')}</Radio.Button>
                        <Radio.Button value="map">{t('Map')}</Radio.Button>
                      </Radio.Group>
                    </Row>
                    <Row>
                      {radioVal === "blueboard" && (
                        <Col span={24}>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">{t('Location Name')}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Location Name")}
                                    defaultValue={
                                      Object.keys(editData).length > 0 &&
                                      editData.address
                                        ? editData.address.locationName
                                        : ""
                                    }
                                    {...register("locationName", {
                                      required: "Please enter location name.",
                                    })}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="locationName"
                                    render={({ message }) => (
                                      <p className="val-error">{message}</p>
                                    )}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Col>
                          {/* <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">Municipality</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Municipality")}
                                    defaultValue={
                                      Object.keys(editData).length > 0 &&
                                      editData.address
                                        ? editData.address.municipality
                                        : ""
                                    }
                                    {...register("municipality")}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Col> */}
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">{t('Building Number')}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Building Number")}
                                    value={buildingNo}
                                    onChange={(e) => {
                                      setBuildingNo(e.target.value);
                                      setLat("");
                                      setLong("");
                                      setMapLink("");
                                    }}
                                  />
                                  {errorValidation.buildingNumber === 1 && (
                                    <p className="val-error">
                                      Please enter building number.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">{t('Street Number')}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Street Number")}
                                    value={streetNo}
                                    onChange={(e) => {
                                      setStreetNo(e.target.value);
                                      setLat("");
                                      setLong("");
                                      setMapLink("");
                                    }}
                                  />
                                  {errorValidation.streetNumber === 1 && (
                                    <p className="val-error">
                                      Please enter street number.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">{t('Zone Number')}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Zone Number")}
                                    value={zoneNo}
                                    onChange={(e) => {
                                      setZoneNo(e.target.value);
                                      setLat("");
                                      setLong("");
                                      setMapLink("");
                                    }}
                                  />
                                  {errorValidation.zoneNumber === 1 && (
                                    <p className="val-error">
                                      Please enter zone number.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          {errorValidation.latLngAdrs === 1 && (
                            <Col span={24}>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                push={3}
                              >
                                <p className="val-error">
                                  Invalid Building/Street/Zone Number
                                </p>
                              </Col>
                            </Col>
                          )}
                          <Col span={24} push={8}>
                            <Button
                              className="flex outline-btn"
                              color="info"
                              onClick={() => fetchMapLink()}
                            >
                              <p>{t('Generate Map')}</p>
                            </Button>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("Map Link")}
                                  {mapLink !== "" && (
                                    <a
                                      target="_blank"
                                      href={mapLink}
                                      className="ml-5"
                                      rel="noreferrer"
                                    >
                                      {t("Show map")}
                                    </a>
                                  )}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Map Link")}
                                    value={mapLink}
                                    readOnly
                                    onChange={(e) => setMapLink(e.target.value)}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Col>
                      )}
                      {radioVal === "map" && (
                        <Col span={24}>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("House/Flat/Block No")}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("House/Flat/Block No")}
                                    value={mapBuildingNo}
                                    onChange={(e) => {
                                      setMapBuildingNo(e.target.value);
                                      // setLat("");
                                      // setLong("");
                                      // setMapLink("");
                                    }}
                                  />
                                  {errorValidation.mapBuildingNumber === 1 && (
                                    <p className="val-error">
                                      Please enter House/Flat/Block No.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">{t("Address Line 1")}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Address Line 1")}
                                    value={mapStreetNo}
                                    onChange={(e) => {
                                      setMapStreetNo(e.target.value);
                                      // setLat("");
                                      // setLong("");
                                      // setMapLink("");
                                    }}
                                  />
                                  {errorValidation.mapStreetNumber === 1 && (
                                    <p className="val-error">
                                      Please enter address line 1.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("Address Line 2 (optional)")}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Address Line 2 (optional)")}
                                    value={mapZoneNo}
                                    onChange={(e) => {
                                      setMapZoneNo(e.target.value);
                                      // setLat("");
                                      // setLong("");
                                      // setMapLink("");
                                    }}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Col>

                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("Map Link (Google Map FUll URL)")}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Map Link")}
                                    value={mapLinkUrl}
                                    onChange={(e) =>
                                      setMapLinkUrl(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                            </div>
                          </Col>
                          {errorValidation.mapLinkUrl === 1 && (
                            <p className="val-error">
                              Please enter map link url.
                            </p>
                          )}
                          {errorValidation.maplatLngAdrs === 1 && (
                            <Col span={24}>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                push={3}
                              >
                                <p className="val-error">
                                  Please enter valid google map url
                                </p>
                              </Col>
                            </Col>
                          )}
                          <Col span={24} push={8}>
                            <Button
                              className="flex outline-btn"
                              color="info"
                              onClick={() => fetchLatLng()}
                            >
                              <p>{t("Verify")}</p>
                            </Button>
                          </Col>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <iframe
                      width="100%"
                      height="300"
                      name="gMap"
                      loading="lazy"
                      src={`https://www.google.com/maps/embed/v1/place?q=${
                        radioVal === "blueboard" ? lat : mapLat
                      },${radioVal === "blueboard" ? long : mapLong}&key=${
                        process.env.REACT_APP_GOOGLE_API_KEY
                      }`}
                    ></iframe>
                  </Col>
                </Row>
                <div className="row btn-float-right">
                  <div className=" btn-field">
                    <button
                      type="submit"
                      className="btn form-btn bgcolor1 ripple1 mr-5"
                    >
                      {t("Save")}
                    </button>
                    <button
                      onClick={() => setPageType("list")}
                      type="button"
                      className="btn  form-btn bgcolor3 ripple3"
                    >
                      {t('Cancel')}
                    </button>
                  </div>
                </div>
              </Card>
            </form>
          </Row>
        </Row>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default CustomerAdd;
