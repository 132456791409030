import React, { useEffect, useState, useContext } from "react";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import calendarImg from "../../assets/images/VectorCalendar.svg";
import DatePicker from "react-datepicker";
import StatementView from "./StatementModal";
import WalletRecharge from "./RechargeModal";
import { useDispatch, useSelector } from "react-redux";
import { resetWalletRechargeOnline } from "../../store/wallet/walletState";
import { resetPaymentStatus } from "../../store/common/commonState";
import { walletStatementHistory } from "../../store/wallet/walletState";
import { useLocation } from "react-router";
import { DispatchContext } from "../../context/AppProvider";
import { Button, Col, Row } from "antd";
import { useTranslation } from 'react-i18next';

const WalletIndex = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateData = useLocation();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [rechargeModal, setRechargeModal] = useState(false);
  const rechargeToggle = () => setRechargeModal(!rechargeModal);
  const paymentSts = useSelector((state) => state.Common.paymentStatus);
  const login = useSelector((state) => state.Login.logData);
  const contextDispatch = useContext(DispatchContext);

  useEffect(() => {
    dispatch(resetWalletRechargeOnline());
    dispatch(resetPaymentStatus());
    let payload = { fromDate: fromDate, toDate: toDate };
    dispatch(walletStatementHistory(payload));
    contextDispatch({
      type: "header_text",
      payload: {
        title: t('Wallet')
      },
    });
  }, []);

  useEffect(() => {
    if (stateData.state !== null) {
      if (stateData.state.pageFrom === "sidebar") {
        dispatch(resetWalletRechargeOnline());
        dispatch(resetPaymentStatus());
        setFromDate(new Date());
        setToDate(new Date());
        let payload = { fromDate: new Date(), toDate: new Date() };
        dispatch(walletStatementHistory(payload));
      }
    }
  }, [stateData]);

  useEffect(() => {
    if (paymentSts && Object.keys(paymentSts).length > 0) {
      let msg = "Payment Failed";
      if (paymentSts.data && Object.keys(paymentSts.data).length > 0) {
        let sts = paymentSts.data.status;
        msg =
          "Payment " + sts.charAt(0).toUpperCase() + sts.slice(1).toLowerCase();
      }
      toast(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [paymentSts]);

  const handleWalletStatement = () => {
    let payload = { fromDate: fromDate, toDate: toDate };
    dispatch(walletStatementHistory(payload));
  };
  const wallet = useSelector((state) => state.Wallet.walletHistory);

  return (
    <React.Fragment>
      <div className="page">
        <div className="hd-sec">
          <Row className="common-listpage">
            {login.vendor && login.vendor.is_admin === false && (
              <Row className="common-head">
                <p className="head-p">
                  Wallet Balance:{" "}
                  <span className="blue-color">{wallet.wallet} QAR</span>
                </p>
                <Button
                  onClick={rechargeToggle}
                  type="button"
                  className="top-btn btn form-btn bgcolor1 ripple1"
                >
                  {t("Recharge Online")}
                </Button>
              </Row>
            )}
            <Row className="com-cntnt">
              <p className="sub-head">{t('View Statement')}</p>
              <Row className="card-cntnt">
                <Col xs={24} sm={6} md={4} lg={4} xl={4} className="mr-25">
                  <div className="new-field">
                    <p className="label-text">{t("From Date")}</p>
                    <div className="filter-coulmn">
                      <img alt="" className="black-clendar" src={calendarImg} />
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) => {
                          setFromDate(date);
                        }}
                        placeholderText={t("Select Date")}
                        maxDate={toDate}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={6} md={4} lg={4} xl={4}>
                  <div className="new-field">
                    <p className="label-text">{t("To Date")}</p>
                    <div className="filter-coulmn">
                      <img alt="" className="black-clendar" src={calendarImg} />
                      <DatePicker
                        selected={toDate}
                        onChange={(date) => {
                          setToDate(date);
                        }}
                        placeholderText={t("Select Date")}
                        minDate={fromDate}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Button
                    type="button"
                    className="btn top-btn bgcolor1 ripple1 padding-button"
                    onClick={handleWalletStatement}
                  >
                    {t("View Statement")}
                  </Button>
                </Col>
              </Row>
            </Row>
            <Row>
              <StatementView wallet={wallet} login={login} />
            </Row>
          </Row>
        </div>
      </div>
      <WalletRecharge
        rechargeModal={rechargeModal}
        setRechargeModal={setRechargeModal}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default WalletIndex;
