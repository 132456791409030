import React, { useState, useEffect, useContext } from "react";
//React Hook Form cleanly grabs the input values and returns them in a handleSubmit function.
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Redux

import moment from "moment";
import { listVendor } from "../../store/vendor/vendorState";
import { createOrder, updateOrder } from "../../store/order/orderState";
import { listCustomerByVendor } from "../../store/customer/customerState";
import {
  fetchSettingsData,
  uploadImage,
  // resetImage,
} from "../../store/common/commonState";
import { useDispatch, useSelector } from "react-redux";
import { StateContext } from "../../context/AppProvider";
import Select from "react-select";
import DatePicker from "react-datepicker";
import calendarImg from "../../assets/images/VectorCalendar.svg";
import axios from "axios";
import { Input } from "reactstrap";
import cancelImg from "../../assets/images/cancel.svg";
import plusImg from "../../assets/images/plus.svg";
import downloadSmallImg from "../../assets/images/download-small.svg";
import { Button, Card, Col, Row, Radio } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";

const OrderAdd = ({ pageType, setPageType }) => {
  const { t } = useTranslation();
  const vendorList = useSelector((state) => state.Vendor.vendorList);
  const customerList = useSelector(
    (state) => state.Customer.customerByVendorList
  );

  const dispatch = useDispatch();
  const contData = useContext(StateContext);
  const editData = contData.orderEditData;
  const [pickupTime, setPickupTime] = useState("");
  const [pickupDate, setPickupDate] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [selectVendorOptions, setSelectVendorOptions] = useState([]);
  const [selectCustomerOptions, setSelectCustomerOptions] = useState([]);
  const [packWeigh, setpackWeigh] = useState("");
  const [vendorSelected, setVendorSelected] = useState({
    label: t("Select vendor"),
    value: "",
  });
  const [customerSelected, setCustomerSelected] = useState({
    label: t("Select customer"),
    value: "",
  });
  const [customerAddress, setCustomerAddress] = useState({
    name: "",
    phone: "",
    locationName: "",
    latitude: "",
    longitude: "",
    buildingNumber: "",
    streetNumber: "",
    // municipality: "",
    zoneNumber: "",
    mapLink: "",
    mapLat: "",
    mapLong: "",
    mapBuildingNo: "",
    mapStreetNo: "",
    mapZoneNo: "",
    mapLinkUrl: "",
  });
  const [pickVehicle, setPickVehicle] = useState("car");
  const [vehicleEnableDisable, setVehicleEnableDisable] = useState({
    car: false,
    twoWheeler: false,
    truck: false,
  });
  const login = useSelector((state) => state.Login.logData);
  const [vendorAddress, setVendorAddress] = useState({});
  const [deliveryType, setDeliveryType] = useState("Normal");
  const [packagePaymentType, setPackagePaymentType] = useState("COD");
  const [errorValidation, setErrorValidation] = useState({
    buildingNumber: 0,
    streetNumber: 0,
    zoneNumber: 0,
    locationName: 0,
    amountCollected: 0,
    vendorSelected: 0,
    customerName: 0,
    customerPhone: 0,
    // municipality: 0,
    // deliveryDate: 0,
    packWeigh: 0,
    latLngAdrs: 0,
    mapBuildingNumber: 0,
    mapStreetNumber: 0,
    mapLinkUrl: 0,
    maplatLngAdrs: 0,
  });
  const [uploadDoc, setUploadDoc] = useState([
    {
      docName: "",
      filePath: "",
      docType: "",
      fileUrl: "",
    },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [weighLimit, setWeighLimit] = useState({
    bikeFrom: "",
    bikeTo: "",
    carFrom: "",
    carTo: "",
    truckFrom: "",
    truckTo: "",
  });
  const [radioVal, setRadioVal] = useState("blueboard");
  const [sendMsgCheck, setSendMsgCheck] = useState(false);

  const settingsDt = useSelector((state) => state.Common.settingsData);
  const fileUploadSucs = useSelector((state) => state.Common.uploadData);
  const fileUploadError = useSelector((state) => state.Common.uploadError);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      // pickUpVehicle:
      //   Object.keys(editData).length > 0 ? editData.pickUpVehicle : "car",
      paymentType:
        Object.keys(editData).length > 0 ? editData.paymentType : "COD",
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  useEffect(() => {
    dispatch(listVendor());
    dispatch(fetchSettingsData());
  }, [dispatch]);

  useEffect(() => {
    if (settingsDt && settingsDt.data) {
      setPickupTime(settingsDt.data.normalPickUpTime);
      setWeighLimit({
        bikeFrom: settingsDt.data.weightLimit.bikeFrom,
        bikeTo: settingsDt.data.weightLimit.bikeTo,
        carFrom: settingsDt.data.weightLimit.carFrom,
        carTo: settingsDt.data.weightLimit.carTo,
        truckFrom: settingsDt.data.weightLimit.truckFrom,
        truckTo: settingsDt.data.weightLimit.truckTo,
      });
    }
  }, [dispatch, settingsDt]);

  useEffect(() => {
    if (
      Object.keys(fileUploadSucs).length > 0 &&
      fileUploadSucs.success === true
    ) {
      let docData = [...uploadDoc];
      docData[currentIndex].filePath = fileUploadSucs.result.Location;
      setUploadDoc(docData);
    }
  }, [fileUploadSucs]);

  useEffect(() => {
    if (Object.keys(fileUploadError).length > 0) {
      toast(fileUploadError.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [fileUploadError]);

  const handlePhoneNumberChange = async (e) => {
    const result = e.replace(/\D/g, "");
    setCustomerAddress({
      ...customerAddress,
      phone: result,
    });
  };

  const submitForm = async (data) => {
    let valid = 0;
    let validBuild = 0;
    let validStreet = 0;
    let validZone = 0;
    let valiLocNme = 0;
    let validAmtClt = 0;
    let validVend = 0;
    let validCustNme = 0;
    let validCustPh = 0;
    let validMunplty = 0;
    let validDelDate = 0;
    let validPackWeigh = 0;
    let validLatLng = 0;
    let validMapLatLng = 0;
    let validMapBuild = 0;
    let validMapStreet = 0;
    let validMapLinkUrl = 0;
    if (sendMsgCheck !== true) {
      if (radioVal === "blueboard" && customerAddress.buildingNumber === "") {
        valid = 1;
        validBuild = 1;
      }
      if (radioVal === "blueboard" && customerAddress.streetNumber === "") {
        valid = 1;
        validStreet = 1;
      }
      if (radioVal === "blueboard" && customerAddress.zoneNumber === "") {
        valid = 1;
        validZone = 1;
      }
      if (radioVal === "blueboard" && customerAddress.locationName === "") {
        valid = 1;
        valiLocNme = 1;
      }
      if (radioVal === "map" && customerAddress.mapBuildingNo === "") {
        valid = 1;
        validMapBuild = 1;
      }
      if (radioVal === "map" && customerAddress.mapStreetNo === "") {
        valid = 1;
        validMapStreet = 1;
      }
      if (radioVal === "map" && customerAddress.mapLinkUrl === "") {
        valid = 1;
        validMapLinkUrl = 1;
      }
    }

    if (packagePaymentType === "COD" && data.amountToBeCollected === "") {
      valid = 1;
      validAmtClt = 1;
    }
    if (vendorSelected.value === "") {
      valid = 1;
      validVend = 1;
    }
    if (customerAddress.name === "") {
      valid = 1;
      validCustNme = 1;
    }
    if (customerAddress.phone === "") {
      valid = 1;
      validCustPh = 1;
    }
    // if (radioVal === "blueboard" && customerAddress.municipality === "") {
    //   valid = 1;
    //   validMunplty = 1;
    // }
    // if (deliveryDate === null) {
    //   valid = 1;
    //   validDelDate = 1;
    // }
    if (packWeigh === "") {
      valid = 1;
      validPackWeigh = 1;
    }

    let latitude = "";
    let longitude = "";
    let mapLnk = "";

    if (sendMsgCheck !== true) {
      if (radioVal === "blueboard") {
        latitude = customerAddress.latitude.toString();
        longitude = customerAddress.longitude.toString();
        mapLnk = customerAddress.mapLink;
        if (
          customerAddress.latitude === "" ||
          customerAddress.longitude === ""
        ) {
          if (
            customerAddress.buildingNumber &&
            customerAddress.streetNumber &&
            customerAddress.zoneNumber
          ) {
            let zoneNo = customerAddress.zoneNumber;
            let streetNo = customerAddress.streetNumber;
            let buildingNo = customerAddress.buildingNumber;
            let response = await axios.get(
              `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS_wgs84/MapServer/0/query?where=zone_no%3D${zoneNo}+and+street_no%3D${streetNo}+and+building_no%3D${buildingNo}&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=X_COORD%2CY_COORD&returnGeometry=false&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson`
            );
            if (
              Object.keys(response).length > 0 > 0 &&
              response.data &&
              Object.keys(response.data).length > 0 &&
              response.data.features &&
              response.data.features.length > 0 &&
              response.data.features[0].attributes &&
              Object.keys(response.data.features[0].attributes).length > 0
            ) {
              longitude = response.data.features[0].attributes.X_COORD;
              latitude = response.data.features[0].attributes.Y_COORD;
              mapLnk = `http://maps.google.com/maps?z=15&t=m&q=loc:${latitude}+${longitude}`;
            } else {
              valid = 1;
              validLatLng = 1;
            }
          }
        }
      } else if (radioVal === "map") {
        latitude = customerAddress.mapLat.toString();
        longitude = customerAddress.mapLong.toString();
        mapLnk = customerAddress.mapLinkUrl.toString();
        // if (customerAddress.mapLat === "" || customerAddress.mapLong === "") {
        if (customerAddress.mapLinkUrl.includes("loc:")) {
          let urlSplit = customerAddress.mapLinkUrl.split(":");
          let latlng = urlSplit[2].split("+");
          latitude = latlng[0];
          longitude = latlng[1];
        } else {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCYNTS0ZJHytAmi_nDuFtnmxnN5bsObt3Q&address=${encodeURIComponent(
              customerAddress.mapLinkUrl
            )}`
          );
          if (response.ok) {
            const data = await response.json();
            const location = data.results[0].geometry.location;
            latitude = location.lat;
            longitude = location.lng;
          } else {
            valid = 1;
            validMapLatLng = 1;
          }
        }
        // }
      }
    }

    if (valid === 0) {
      data.vendor = vendorSelected.value;
      data.customer = customerSelected.value;
      data.customerName = customerAddress && customerAddress.name;
      data.customerPhone = customerAddress && customerAddress.phone;
      data.deliveryDate = moment.utc(deliveryDate).format("YYYY-MM-DD, h:mm a");
      data.packageWeight = packWeigh;
      data.pickUpVehicle = pickVehicle;
      data.pickupDate =
        pickupDate !== null
          ? moment.utc(pickupDate).format("YYYY-MM-DD")
          : null;
      data.deliverySlot = "10 AM to 12 PM";
      // data.deliveryCharge = "20";

      data.deliveryType = deliveryType;
      data.redirectUrl = window.location.href.replace(
        window.location.pathname,
        "/redirect_url/order"
      );
      data.packagePaymentType = packagePaymentType;
      data.dropoffAddress = {
        locationName:
          radioVal === "blueboard" ? customerAddress.locationName : "",
        latitude: latitude.toString(),
        longitude: longitude.toString(),
        buildingNumber:
          radioVal === "blueboard"
            ? customerAddress.buildingNumber
            : customerAddress.mapBuildingNo,
        streetNumber:
          radioVal === "blueboard"
            ? customerAddress.streetNumber
            : customerAddress.mapStreetNo,
        municipality: "",
        // radioVal === "blueboard" ? customerAddress.municipality : "",
        zoneNumber:
          radioVal === "blueboard"
            ? customerAddress.zoneNumber
            : customerAddress.mapZoneNo,
        mapLink: mapLnk,
        isBlueBoard:
          radioVal === "blueboard" && sendMsgCheck === false ? true : false,
      };

      data.pickupAddress = {
        locationName:
          vendorAddress &&
          vendorAddress.address &&
          vendorAddress.address.locationName,
        latitude:
          vendorAddress &&
          vendorAddress.address &&
          vendorAddress.address.latitude,
        longitude:
          vendorAddress &&
          vendorAddress.address &&
          vendorAddress.address.longitude,
        buildingNumber:
          vendorAddress &&
          vendorAddress.address &&
          vendorAddress.address.buildingNumber,
        streetNumber:
          vendorAddress &&
          vendorAddress.address &&
          vendorAddress.address.streetNumber,
        municipality:
          vendorAddress &&
          vendorAddress.address &&
          vendorAddress.address.municipality,
        zoneNumber:
          vendorAddress &&
          vendorAddress.address &&
          vendorAddress.address.zoneNumber,
        mapLink:
          vendorAddress &&
          vendorAddress.address &&
          vendorAddress.address.mapLink,
      };
      let upDocs = uploadDoc.map((itm, i) => {
        return {
          docName: itm.docName,
          filePath: itm.filePath,
          docType: itm.docType,
        };
      });
      data.docDetails = upDocs;
      data.platform = "Web";
      data.customerMsg = sendMsgCheck;

      if (Object.keys(editData).length === 0) {
        let dataN =
          vendorList && Object.keys(vendorList).length > 0 && vendorList.data;
        let selVendor = dataN.find((item) => item._id === vendorSelected.value);
        if (data.pickUpVehicle === "car")
          data.deliveryCharge =
            selVendor &&
            selVendor.vehicleDetails &&
            selVendor.vehicleDetails.carPrice;
        else if (data.pickUpVehicle === "pickup")
          data.deliveryCharge =
            selVendor &&
            selVendor.vehicleDetails &&
            selVendor.vehicleDetails.pickupPrice;
        else if (data.pickUpVehicle === "twowheeler")
          data.deliveryCharge =
            selVendor &&
            selVendor.vehicleDetails &&
            selVendor.vehicleDetails.twowheelerPrice;
        else data.deliveryCharge = 20;
        dispatch(createOrder(data));
      } else {
        data.deliveryCharge = editData.deliveryCharge;
        data.order = editData._id;
        dispatch(updateOrder(data));
      }
    }
    setErrorValidation({
      ...errorValidation,
      buildingNumber: validBuild,
      streetNumber: validStreet,
      zoneNumber: validZone,
      locationName: valiLocNme,
      amountCollected: validAmtClt,
      vendorSelected: validVend,
      customerName: validCustNme,
      customerPhone: validCustPh,
      // municipality: validMunplty,
      // deliveryDate: validDelDate,
      packWeigh: validPackWeigh,
      latLngAdrs: validLatLng,
      maplatLngAdrs: validMapLatLng,
      mapBuildingNumber: validMapBuild,
      mapStreetNumber: validMapStreet,
      mapLinkUrl: validMapLinkUrl,
    });
  };

  useEffect(() => {
    if (
      login &&
      Object.keys(login).length > 0 &&
      login.vendor.is_admin === false
    ) {
      let v = {
        label: login.vendor.name + "(" + login.vendor.phone + ")", // <-- input values you are matching
        value: login.vendor.id,
      };
      setVendorSelected(v);
      setVendorAddress(login.vendor);
      dispatch(listCustomerByVendor({ vendor: login.vendor.id }));
    }
  }, [login]);

  useEffect(() => {
    if (vendorList && vendorList && vendorList.success === true) {
      let data = vendorList.data;
      const vendorOptions = data.map((item) => ({
        label: item.name + "(" + item.phone + ")", // <-- input values you are matching
        value: item._id,
      }));
      setSelectVendorOptions(vendorOptions);
    }
  }, [dispatch, vendorList]);

  useEffect(() => {
    if (customerList && customerList && customerList.success === true) {
      let data = customerList.data;
      const custOptions = data.map((item) => ({
        label: item.name + "(" + item.phone + ")", // <-- input values you are matching
        value: item._id,
      }));
      setSelectCustomerOptions(custOptions);
      //dispatch(resetCustomer());
    } else {
      setSelectCustomerOptions([]);
      setCustomerSelected({
        label: "Select customer",
        value: "",
      });
    }
  }, [dispatch, customerList]);

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      handleEditData();
    }
  }, [editData]);

  const handleEditData = () => {
    if (editData.vendor && login && login.vendor.is_admin === true) {
      let v = {
        label: editData.vendor.name + "(" + editData.vendor.phone + ")", // <-- input values you are matching
        value: editData.vendor._id,
      };
      setVendorSelected(v);
      setVendorAddress(editData.vendor);
    }
    setpackWeigh(editData.packageWeight);
    setPickVehicle(editData.pickUpVehicle.toLowerCase());
    // editData.pickupDate !== null && setDeliveryType("Express");
    editData.pickupDate !== null &&
      setPickupDate(new Date(editData.pickupDate));
    setPackagePaymentType(editData.packagePaymentType);
    editData.deliveryDate !== null &&
      setDeliveryDate(new Date(editData.deliveryDate));
    setCustomerAddress({
      name: editData.customerName,
      phone: editData.customerPhone,
      locationName:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? ""
          : editData.dropoffAddress.locationName,
      latitude:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? ""
          : editData.dropoffAddress.latitude,
      longitude:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? ""
          : editData.dropoffAddress.longitude,
      buildingNumber:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? ""
          : editData.dropoffAddress.buildingNumber,
      streetNumber:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? ""
          : editData.dropoffAddress.streetNumber,
      // municipality:
      //   editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
      //   editData.dropoffAddress.isBlueBoard === false
      //     ? ""
      //     : editData.dropoffAddress.municipality,
      zoneNumber:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? ""
          : editData.dropoffAddress.zoneNumber,
      mapLink:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? ""
          : editData.dropoffAddress.mapLink,
      mapLat:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? editData.dropoffAddress.latitude
          : "",
      mapLong:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? editData.dropoffAddress.longitude
          : "",
      mapBuildingNo:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? editData.dropoffAddress.buildingNumber
          : "",
      mapStreetNo:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? editData.dropoffAddress.streetNumber
          : "",
      mapZoneNo:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? editData.dropoffAddress.zoneNumber
          : "",
      mapLinkUrl:
        editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
        editData.dropoffAddress.isBlueBoard === false
          ? editData.dropoffAddress.mapLink
          : "",
    });
    setRadioVal(
      editData.dropoffAddress
        ? editData.dropoffAddress.hasOwnProperty("isBlueBoard") === true &&
          editData.dropoffAddress.isBlueBoard === false
          ? "map"
          : "blueboard"
        : "blueboard"
    );
    if (editData.customer) {
      let v = {
        label: editData.customerName + "(" + editData.customerPhone + ")", // <-- input values you are matching
        value: editData.customer,
      };
      setCustomerSelected(v);
    }

    if (
      editData.packageWeight > settingsDt.data.weightLimit.bikeFrom &&
      editData.packageWeight < settingsDt.data.weightLimit.bikeTo
    ) {
      setVehicleEnableDisable({
        ...vehicleEnableDisable,
        car: false,
        truck: false,
        twoWheeler: false,
      });
    } else if (
      editData.packageWeight > settingsDt.data.weightLimit.carFrom &&
      editData.packageWeight < settingsDt.data.weightLimit.carTo
    ) {
      setVehicleEnableDisable({
        ...vehicleEnableDisable,
        car: false,
        truck: false,
        twoWheeler: true,
      });
    } else if (
      editData.packageWeight > settingsDt.data.weightLimit.truckFrom &&
      editData.packageWeight < settingsDt.data.weightLimit.truckTo
    ) {
      setVehicleEnableDisable({
        ...vehicleEnableDisable,
        car: true,
        truck: false,
        twoWheeler: true,
      });
    }
    let upDoc = [...uploadDoc];
    editData.docDetails.length > 0 &&
      editData.docDetails.map((itm, i) => {
        return (upDoc[i] = {
          docName: itm.docName,
          filePath: itm.filePath,
          docType: itm.docType,
          fileUrl: itm.filePath,
        });
      });
    setUploadDoc(upDoc);
    setSendMsgCheck(editData.customerMsg);
  };

  const handleVendorChange = (val) => {
    setVendorSelected(val);
    if (val && val.value) {
      dispatch(listCustomerByVendor({ vendor: val.value }));
      let vendor = vendorList.data.find((v) => v._id === val.value);
      setVendorAddress(vendor);
    }
  };

  const handleCustomerChange = (val) => {
    setCustomerSelected(val);
    if (val && val.value) {
      let customer = customerList.data.find((v) => v._id === val.value);
      customer &&
        customer.address &&
        setCustomerAddress({
          name: customer.name,
          phone: customer.phone,
          locationName:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? ""
              : customer.address.locationName,
          latitude:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? ""
              : customer.address.latitude,
          longitude:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? ""
              : customer.address.longitude,
          buildingNumber:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? ""
              : customer.address.buildingNumber,
          streetNumber:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? ""
              : customer.address.streetNumber,
          // municipality: customer.address.municipality,
          zoneNumber:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? ""
              : customer.address.zoneNumber,
          mapLink:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? ""
              : customer.address.mapLink,

          mapLat:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? customer.address.latitude
              : "",
          mapLong:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? customer.address.longitude
              : "",
          mapBuildingNo:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? customer.address.buildingNumber
              : "",
          mapStreetNo:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? customer.address.streetNumber
              : "",
          mapZoneNo:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? customer.address.zoneNumber
              : "",
          mapLinkUrl:
            customer.address.hasOwnProperty("isBlueBoard") === true &&
            customer.address.isBlueBoard === false
              ? customer.address.mapLink
              : "",
        });
      setRadioVal(
        customer.address.hasOwnProperty("isBlueBoard") === true &&
          customer.address.isBlueBoard === false
          ? "map"
          : "blueboard"
      );
    }
  };

  const handleDeliveryType = (event) => {
    setDeliveryType(event.target.value);
  };

  const handlePackagePaymentType = (event) => {
    setPackagePaymentType(event.target.value);
  };

  const fetchMapLink = () => {
    if (
      customerAddress.buildingNumber &&
      customerAddress.streetNumber &&
      customerAddress.zoneNumber
    ) {
      let zoneNo = customerAddress.zoneNumber;
      let streetNo = customerAddress.streetNumber;
      let buildingNo = customerAddress.buildingNumber;

      axios
        .get(
          `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS_wgs84/MapServer/0/query?where=zone_no%3D${zoneNo}+and+street_no%3D${streetNo}+and+building_no%3D${buildingNo}&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=X_COORD%2CY_COORD&returnGeometry=false&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson`
        )
        .then((response) => {
          if (
            response.data &&
            Object.keys(response.data).length > 0 &&
            response.data.features &&
            response.data.features.length > 0 &&
            response.data.features[0].attributes &&
            Object.keys(response.data.features[0].attributes).length > 0
          ) {
            let xCord = response.data.features[0].attributes.X_COORD;
            let yCord = response.data.features[0].attributes.Y_COORD;
            let mapLnk = ` http://maps.google.com/maps?z=15&t=m&q=loc:${yCord}+${xCord}`;
            setCustomerAddress({
              ...customerAddress,
              latitude: yCord,
              longitude: xCord,
              mapLink: mapLnk,
            });
            setErrorValidation({ ...errorValidation, latLngAdrs: 0 });
          } else {
            setCustomerAddress({
              ...customerAddress,
              latitude: "",
              longitude: "",
              mapLink: "",
            });
            setErrorValidation({ ...errorValidation, latLngAdrs: 1 });
          }
        })
        .catch((error) => {
          setCustomerAddress({
            ...customerAddress,
            latitude: "",
            longitude: "",
            mapLink: "",
          });
          setErrorValidation({ ...errorValidation, latLngAdrs: 1 });
        });
    }
  };

  const fetchLatLng = async () => {
    try {
      if (customerAddress.mapLinkUrl.includes("loc:")) {
        let urlSplit = customerAddress.mapLinkUrl.split(":");
        let latlng = urlSplit[2].split("+");
        setCustomerAddress({
          ...customerAddress,
          mapLat: latlng[0],
          mapLong: latlng[1],
        });

        setErrorValidation({ ...errorValidation, maplatLngAdrs: 0 });
      } else {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCYNTS0ZJHytAmi_nDuFtnmxnN5bsObt3Q&address=${encodeURIComponent(
            customerAddress.mapLinkUrl
          )}`
        );
        if (response.ok) {
          const data = await response.json();
          const location = data.results[0].geometry.location;
          setCustomerAddress({
            ...customerAddress,
            mapLat: location.lat,
            mapLong: location.lng,
          });

          setErrorValidation({ ...errorValidation, maplatLngAdrs: 0 });
        } else {
          setCustomerAddress({
            ...customerAddress,
            mapLat: "",
            mapLong: "",
          });
          setErrorValidation({ ...errorValidation, maplatLngAdrs: 1 });
        }
      }
    } catch (error) {
      console.error("error");
      setCustomerAddress({
        ...customerAddress,
        mapLat: "",
        mapLong: "",
      });
      setErrorValidation({ ...errorValidation, maplatLngAdrs: 1 });
    }
  };

  const handelUploadFiles = (e, index) => {
    let docImg = [...uploadDoc];
    const up = new FormData();
    if (e) {
      docImg[index].fileUrl = URL.createObjectURL(e);
      setUploadDoc(docImg);
      up.append("files", e);
    }
    setCurrentIndex(index);
    dispatch(uploadImage(up));
  };

  const addDocument = (index) => {
    return (
      <img
        src={plusImg}
        alt=""
        onClick={() => {
          let newDoc = [...uploadDoc];
          if (newDoc[index].fileUrl === "") {
            alert("Please add a document");
          } else {
            newDoc.push({
              docName: "",
              filePath: "",
              docType: "",
              fileUrl: "",
            });
            setUploadDoc(newDoc);
          }
        }}
        style={{
          color: "blue",
          cursor: "pointer",
        }}
      />
    );
  };

  const removeDocument = (index) => {
    return (
      <img
        src={cancelImg}
        alt=""
        onClick={() => {
          let fname = "file" + index;
          document.getElementById(fname).value = "";
          let newDoc = [...uploadDoc];
          if (newDoc.length === 1) {
            newDoc = [
              {
                docName: "",
                filePath: "",
                docType: "",
                fileUrl: "",
              },
            ];
          } else {
            newDoc.splice(index, 1);
          }
          setUploadDoc(newDoc);
        }}
        style={{
          color: "blue",
          cursor: "pointer",
        }}
      />
    );
  };
  const handleReset = () => {
    if (Object.keys(editData).length > 0) {
      handleEditData();
    } else {
      if (
        login &&
        Object.keys(login).length > 0 &&
        login.vendor.is_admin === false
      ) {
        let v = {
          label: login.vendor.name + "(" + login.vendor.phone + ")", // <-- input values you are matching
          value: login.vendor.id,
        };
        setVendorSelected(v);
        setVendorAddress(login.vendor);
        dispatch(listCustomerByVendor({ vendor: login.vendor.id }));
      }
      setPickupTime("");
      setPickupDate(new Date());
      setDeliveryDate(new Date());
      setSelectVendorOptions([]);
      setSelectCustomerOptions([]);
      setpackWeigh("");
      setCustomerSelected({
        label: t("Select customer"),
        value: "",
      });
      setCustomerAddress({
        name: "",
        phone: "+974",
        locationName: "",
        latitude: "",
        longitude: "",
        buildingNumber: "",
        streetNumber: "",
        // municipality: "",
        zoneNumber: "",
        mapLink: "",
        mapLat: "",
        mapLong: "",
        mapBuildingNo: "",
        mapStreetNo: "",
        mapZoneNo: "",
        mapLinkUrl: "",
      });
      setPickVehicle("car");
      setVehicleEnableDisable({
        car: false,
        twoWheeler: false,
        truck: false,
      });
      setDeliveryType("Normal");
      setPackagePaymentType("COD");
      setErrorValidation({
        buildingNumber: 0,
        streetNumber: 0,
        zoneNumber: 0,
        locationName: 0,
        amountCollected: 0,
        vendorSelected: 0,
        customerName: 0,
        customerPhone: 0,
        // municipality: 0,
        // deliveryDate: 0,
        packWeigh: 0,
        latLngAdrs: 0,
        mapBuildingNumber: 0,
        mapStreetNumber: 0,
        mapLinkUrl: 0,
        maplatLngAdrs: 0,
      });
      setCurrentIndex(0);
      // setWeighLimit({
      //   bikeFrom: "",
      //   bikeTo: "",
      //   carFrom: "",
      //   carTo: "",
      //   truckFrom: "",
      //   truckTo: "",
      // });
      setRadioVal("blueboard");
      setSendMsgCheck(false);
      document.getElementById("order-form").reset();
    }
  };

  return (
    <React.Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          <Row className="common-head">
            <p className="head-p">
              {Object.keys(editData).length > 0
                ? t("Edit Order")
                : t("Add Order")}
            </p>
            <div className="page-header">
              <Button
                onClick={() => handleReset()}
                // onClick={() => setPageType("addReset")}
                type="button"
                className="flex top-btn btn form-btn bgcolor2 ripple1"
              >
                {t("Reset")}
              </Button>
              <Button
                onClick={() => setPageType("list")}
                type="button"
                className="top-btn btn form-btn bgcolor1 ripple1"
              >
                {t("LIST ORDERS")}
              </Button>
            </div>
          </Row>

          <Row className="com-cntnt">
            <form onSubmit={handleSubmit(submitForm)} id="order-form">
              <Card className="form-card" bordered={false}>
                <Card className="form-card" bordered={false}>
                  <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t("Vendor")}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <Select
                                value={vendorSelected}
                                onChange={(selected) =>
                                  handleVendorChange(selected)
                                }
                                options={selectVendorOptions}
                                classNamePrefix="select2-selection"
                                isDisabled={
                                  Object.keys(editData).length > 0
                                    ? true
                                    : login &&
                                      Object.keys(login).length > 0 &&
                                      login.vendor.is_admin === false
                                    ? true
                                    : false
                                }
                              />
                              {errorValidation.vendorSelected === 1 && (
                                <p className="val-error">
                                  Please select a vendor.
                                </p>
                              )}
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">
                              {t("Package Weight (in kg)")}
                            </p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="number"
                                step="0.01"
                                value={packWeigh}
                                min={
                                  weighLimit.bikeFrom !== ""
                                    ? weighLimit.bikeFrom.toString()
                                    : "0.01"
                                }
                                max={
                                  weighLimit.truckTo !== ""
                                    ? weighLimit.truckTo.toString()
                                    : ""
                                }
                                placeholder={t("Package Weight (in kg)")}
                                onChange={(e) => {
                                  setpackWeigh(e.target.value);
                                  setErrorValidation({
                                    ...errorValidation,
                                    packWeigh: 0,
                                  });
                                }}
                                onBlur={() => {
                                  if (
                                    packWeigh < weighLimit.bikeFrom ||
                                    packWeigh > weighLimit.truckTo
                                  ) {
                                    setpackWeigh("");
                                    setErrorValidation({
                                      ...errorValidation,
                                      packWeigh: 2,
                                    });
                                  }
                                  if (
                                    packWeigh >= weighLimit.bikeFrom &&
                                    packWeigh <= weighLimit.bikeTo
                                  ) {
                                    setVehicleEnableDisable({
                                      ...vehicleEnableDisable,
                                      car: false,
                                      truck: false,
                                      twoWheeler: false,
                                    });
                                    setPickVehicle("twowheeler");
                                  } else if (
                                    packWeigh >= weighLimit.carFrom &&
                                    packWeigh <= weighLimit.carTo
                                  ) {
                                    setVehicleEnableDisable({
                                      ...vehicleEnableDisable,
                                      car: false,
                                      truck: false,
                                      twoWheeler: true,
                                    });
                                    setPickVehicle("car");
                                  } else if (
                                    packWeigh >= weighLimit.truckFrom &&
                                    packWeigh <= weighLimit.truckTo
                                  ) {
                                    setVehicleEnableDisable({
                                      ...vehicleEnableDisable,
                                      car: true,
                                      truck: false,
                                      twoWheeler: true,
                                    });
                                    setPickVehicle("pickup");
                                  }
                                }}
                              />
                              {errorValidation.packWeigh === 1 && (
                                <p className="val-error">
                                  Please enter package weight.
                                </p>
                              )}
                              {errorValidation.packWeigh === 2 && (
                                <p className="val-error">
                                  Please enter weight above bike from and below
                                  truck to weight.
                                </p>
                              )}
                            </div>
                          </Col>
                        </div>
                      </Col>
                      {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">Delivery Type</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="vehicle-driver">
                              <div className="radio">
                                <input
                                  type="radio"
                                  value="Normal"
                                  checked={
                                    deliveryType === "Normal" ? true : false
                                  }
                                  onChange={handleDeliveryType}
                                />
                                <label forhtml="html" className="field-icn">
                                  Normal
                                </label>
                              </div>
                            </div>
                            <div className="vehicle-driver">
                              <div className="radio">
                                <input
                                  type="radio"
                                  value="Express"
                                  checked={
                                    deliveryType === "Express" ? true : false
                                  }
                                  onChange={handleDeliveryType}
                                />
                                <label forhtml="html" className="field-icn">
                                  Express
                                </label>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </Col> */}
                      {deliveryType === "Normal" ? (
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <div className="row new-field">
                            <div className="normal-dellivery col-12">
                              <h5>
                                Pick-up will be done by tomorrow {pickupTime}
                              </h5>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <div className="row new-field">
                            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                              <p className="label-text">Pick-up Date</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                              <div className="field-coulmn">
                                <div className="filter-coulmn sizer-1">
                                  <img
                                    alt=""
                                    className="black-clendar"
                                    src={calendarImg}
                                  />
                                  <DatePicker
                                    selected={pickupDate}
                                    onChange={(date) => {
                                      setPickupDate(date);
                                    }}
                                    placeholderText={t("Select Date")}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Col>
                      )}
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">
                              {t("Package Payment Type")}
                            </p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="vehicle-driver">
                              <div className="radio">
                                <input
                                  type="radio"
                                  value="COD"
                                  checked={packagePaymentType === "COD"}
                                  onChange={handlePackagePaymentType}
                                />
                                <label forhtml="html" className="field-icn">
                                  COD
                                </label>
                              </div>
                            </div>
                            <div className="vehicle-driver">
                              <div className="radio">
                                <input
                                  type="radio"
                                  value="Online"
                                  checked={packagePaymentType === "Online"}
                                  onChange={handlePackagePaymentType}
                                />
                                <label forhtml="html" className="field-icn">
                                  Online
                                </label>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </Col>
                      {packagePaymentType === "COD" && (
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <div className="row new-field">
                            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                              <p className="label-text">
                                {t("Amount to be collected")}
                              </p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                              <div className="field-coulmn">
                                <input
                                  className="field-input"
                                  type="number"
                                  step="0.01"
                                  placeholder={t("Amount to be collected")}
                                  defaultValue={
                                    Object.keys(editData).length > 0
                                      ? editData.amountToBeCollected
                                      : ""
                                  }
                                  {...register("amountToBeCollected")}
                                />
                                {errorValidation.amountCollected === 1 && (
                                  <p className="val-error">
                                    Please enter amount.
                                  </p>
                                )}
                              </div>
                            </Col>
                          </div>
                        </Col>
                      )}
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t("Vehicle")}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="vehicle-driver">
                              <div className="radio">
                                <input
                                  type="radio"
                                  id="html"
                                  name="pickUpVehicle"
                                  value="car"
                                  checked={pickVehicle === "car" && true}
                                  onChange={(e) =>
                                    setPickVehicle(e.target.value)
                                  }
                                  disabled={vehicleEnableDisable.car}
                                />
                                <label forhtml="html">
                                  <span className="field-icn material-icons">
                                    directions_car
                                  </span>
                                </label>
                              </div>
                              <label>
                                {settingsDt &&
                                  settingsDt.data &&
                                  "(" +
                                    settingsDt.data.weightLimit.carFrom +
                                    "kg - " +
                                    settingsDt.data.weightLimit.carTo +
                                    "kg)"}
                              </label>
                            </div>
                            <div className="vehicle-driver">
                              <div className="radio">
                                <input
                                  type="radio"
                                  id="html"
                                  name="pickUpVehicle"
                                  value="pickup"
                                  checked={pickVehicle === "pickup" && true}
                                  onChange={(e) =>
                                    setPickVehicle(e.target.value)
                                  }
                                  disabled={vehicleEnableDisable.truck}
                                />
                                <label forhtml="html">
                                  <span className="field-icn material-icons">
                                    local_shipping
                                  </span>
                                </label>
                              </div>
                              <label>
                                {settingsDt &&
                                  settingsDt.data &&
                                  "(" +
                                    settingsDt.data.weightLimit.truckFrom +
                                    "kg - " +
                                    settingsDt.data.weightLimit.truckTo +
                                    "kg)"}
                              </label>
                            </div>
                            <div className="vehicle-driver">
                              <div className="radio">
                                <input
                                  type="radio"
                                  id="html"
                                  name="pickUpVehicle"
                                  value="twowheeler"
                                  checked={pickVehicle === "twowheeler" && true}
                                  onChange={(e) =>
                                    setPickVehicle(e.target.value)
                                  }
                                  disabled={vehicleEnableDisable.twoWheeler}
                                />
                                <label forhtml="html">
                                  <span className="field-icn material-icons">
                                    moped
                                  </span>
                                </label>
                              </div>
                              <label>
                                {settingsDt &&
                                  settingsDt.data &&
                                  "(" +
                                    settingsDt.data.weightLimit.bikeFrom +
                                    "kg - " +
                                    settingsDt.data.weightLimit.bikeTo +
                                    "kg)"}
                              </label>
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name="password"
                              render={({ message }) => (
                                <p className="val-error">{message}</p>
                              )}
                            />
                          </Col>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t("Additional Info")}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <textarea
                                className="field-textarea"
                                name=""
                                id=""
                                cols="20"
                                rows="4"
                                spellCheck="false"
                                defaultValue={
                                  Object.keys(editData).length > 0
                                    ? editData.addInfo
                                    : ""
                                }
                                {...register("addInfo")}
                              ></textarea>
                            </div>
                          </Col>
                        </div>
                      </Col>
                      {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t('Delivery Date')}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <div className="filter-coulmn sizer-1">
                                <img
                                  alt=""
                                  className="black-clendar"
                                  src={calendarImg}
                                />
                                <DatePicker
                                  selected={deliveryDate}
                                  onChange={(date) => {
                                    setDeliveryDate(date);
                                  }}
                                  placeholderText={t("Select Date")}
                                  dateFormat="dd/MM/yyyy"
                                />
                                {errorValidation.deliveryDate === 1 && (
                                  <p className="val-error">
                                    Please select delivery date.
                                  </p>
                                )}
                              </div>
                            </div>
                          </Col>
                        </div>
                      </Col> */}
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t("Payment Type")}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="vehicle-driver">
                              <div className="radio">
                                <input
                                  type="radio"
                                  id="COD"
                                  name="paymentType"
                                  value="COD"
                                  disabled={
                                    Object.keys(editData).length > 0
                                      ? true
                                      : false
                                  }
                                  {...register("paymentType")}
                                />
                                <label forhtml="html" className="field-icn">
                                  COD
                                </label>
                              </div>
                            </div>
                            {login.vendor && login.vendor.is_admin === false ? (
                              <div className="vehicle-driver">
                                <div className="radio">
                                  <input
                                    type="radio"
                                    id="Online"
                                    name="paymentType"
                                    value="Online"
                                    disabled={
                                      Object.keys(editData).length > 0
                                        ? true
                                        : false
                                    }
                                    {...register("paymentType")}
                                  />
                                  <label forhtml="html" className="field-icn">
                                    Online
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <ErrorMessage
                              errors={errors}
                              name="paymentType"
                              render={({ message }) => (
                                <p className="val-error">{message}</p>
                              )}
                            />
                          </Col>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {uploadDoc.map((item, i) => {
                          return (
                            <div key={i} className="row new-field mb-20">
                              {i === 0 && (
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <p className="label-text">
                                    {t("Upload Documents")}
                                  </p>
                                </Col>
                              )}
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row>
                                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <div className="field-coulmn mr-5 mb-2">
                                      <Input
                                        id="docName"
                                        name="docName"
                                        type="text"
                                        value={uploadDoc[i].docName}
                                        onChange={(e) => {
                                          let docImg = [...uploadDoc];
                                          docImg[i].docName = e.target.value;
                                          setUploadDoc(docImg);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <div className="field-coulmn mb-2">
                                      <Input
                                        id={`file${i}`}
                                        name="file"
                                        type="file"
                                        onChange={(e) => {
                                          if (e.target.files) {
                                            if (e.target.files.length) {
                                              handelUploadFiles(
                                                e.target.files[0],
                                                i
                                              );
                                            } else {
                                              handelUploadFiles(false, i);
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} sm={4} md={4} lg={2} xl={2}>
                                    {uploadDoc[i].fileUrl ? (
                                      <a
                                        href={uploadDoc[i].fileUrl}
                                        target="_blank"
                                        download={false}
                                        rel="noreferrer"
                                      >
                                        <img
                                          alt=""
                                          className="download-icon"
                                          src={downloadSmallImg}
                                        />
                                      </a>
                                    ) : null}
                                  </Col>
                                  <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                                    <div className="field-coulmn">
                                      {uploadDoc.length - 1 === i ? (
                                        <Row>
                                          <Col span={12}>{addDocument(i)}</Col>
                                          <Col span={12}>
                                            {removeDocument(i)}
                                          </Col>
                                        </Row>
                                      ) : (
                                        <Col span={12}>{removeDocument(i)}</Col>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                          );
                        })}
                      </Col>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t("Customer")}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <Select
                                value={customerSelected}
                                onChange={(selected) =>
                                  handleCustomerChange(selected)
                                }
                                options={selectCustomerOptions}
                                classNamePrefix="select2-selection"
                                isDisabled={
                                  Object.keys(editData).length > 0
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t("Customer Name")}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Customer Name")}
                                value={customerAddress.name}
                                onChange={(e) => {
                                  let result = e.target.value.replace(
                                    /[^a-z\u0600-\u06FF@~`!@#$%^&*()_ =+\\\\';:\"\\/?>.<,-]/gi,
                                    ""
                                  );
                                  setCustomerAddress({
                                    ...customerAddress,
                                    name: result,
                                  });
                                }}
                              />
                              {errorValidation.customerName === 1 && (
                                <p className="val-error">
                                  Please enter customer name/select a customer.
                                </p>
                              )}
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t("Customer Phone")}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              {/* <input
                                className="field-input"
                                type="text"
                                maxLength={11}
                                placeholder="with country code(eg:974xxxxxxxx)"
                                value={customerAddress.phone}
                                onChange={(e) => {
                                  const result = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  setCustomerAddress({
                                    ...customerAddress,
                                    phone: result,
                                  });
                                }}
                              /> */}
                              <PhoneInput
                                country={"qa"}
                                value={customerAddress.phone}
                                onChange={handlePhoneNumberChange}
                              />
                              {errorValidation.customerPhone === 1 && (
                                <p className="val-error">
                                  Please enter customer phone number.
                                </p>
                              )}
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <Checkbox
                                checked={sendMsgCheck}
                                onChange={(e) => {
                                  setSendMsgCheck(e.target.checked);
                                }}
                              >
                                {t(
                                  "Send a link to the customer to update the address"
                                )}
                              </Checkbox>
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Row>
                        <Radio.Group
                          onChange={(e) => {
                            setRadioVal(e.target.value);
                          }}
                          value={radioVal}
                          style={{
                            marginBottom: 8,
                          }}
                        >
                          <Radio.Button value="blueboard">
                            {t("BlueBoard")}
                          </Radio.Button>
                          <Radio.Button value="map">{t("Map")}</Radio.Button>
                        </Radio.Group>
                      </Row>
                      {radioVal === "blueboard" && (
                        <Col span={24}>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("Location Name")}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Location Name")}
                                    value={customerAddress.locationName}
                                    onChange={(e) =>
                                      setCustomerAddress({
                                        ...customerAddress,
                                        locationName: e.target.value,
                                      })
                                    }
                                  />
                                  {errorValidation.locationName === 1 && (
                                    <p className="val-error">
                                      Please enter location name.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          {/* <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">Municipality</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Municipality")}
                                    defaultValue={customerAddress.municipality}
                                    onChange={(e) =>
                                      setCustomerAddress({
                                        ...customerAddress,
                                        municipality: e.target.value,
                                      })
                                    }
                                  />
                                  {errorValidation.municipality === 1 && (
                                    <p className="val-error">
                                      Please enter municipality.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col> */}
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("Building Number")}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Building Number")}
                                    value={customerAddress.buildingNumber}
                                    onChange={(e) =>
                                      setCustomerAddress({
                                        ...customerAddress,
                                        buildingNumber: e.target.value,
                                        latitude: "",
                                        longitude: "",
                                        mapLink: "",
                                      })
                                    }
                                  />
                                  {errorValidation.buildingNumber === 1 && (
                                    <p className="val-error">
                                      Please enter building number.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("Street Number")}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Street Number")}
                                    value={customerAddress.streetNumber}
                                    onChange={(e) =>
                                      setCustomerAddress({
                                        ...customerAddress,
                                        streetNumber: e.target.value,
                                        latitude: "",
                                        longitude: "",
                                        mapLink: "",
                                      })
                                    }
                                  />
                                  {errorValidation.streetNumber === 1 && (
                                    <p className="val-error">
                                      Please enter street number.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">{t("Zone Number")}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Zone Number")}
                                    value={customerAddress.zoneNumber}
                                    onChange={(e) =>
                                      setCustomerAddress({
                                        ...customerAddress,
                                        zoneNumber: e.target.value,
                                        latitude: "",
                                        longitude: "",
                                        mapLink: "",
                                      })
                                    }
                                  />
                                  {errorValidation.zoneNumber === 1 && (
                                    <p className="val-error">
                                      Please enter zone number.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          {errorValidation.latLngAdrs === 1 && (
                            <Col span={24}>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                push={3}
                              >
                                <p className="val-error">
                                  Invalid Building/Street/Zone Number
                                </p>
                              </Col>
                            </Col>
                          )}
                          <Col span={24} push={8}>
                            <Button
                              className="flex outline-btn"
                              color="info"
                              onClick={() => fetchMapLink()}
                            >
                              <p>{t("Generate Map")}</p>
                            </Button>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("Map Link")}
                                  {customerAddress &&
                                    customerAddress.mapLink && (
                                      <a
                                        target="_blank"
                                        href={customerAddress.mapLink}
                                        className="ml-5"
                                        rel="noreferrer"
                                      >
                                        {t("Show Map")}
                                      </a>
                                    )}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Map Link")}
                                    defaultValue={customerAddress.mapLink}
                                    readOnly
                                    onChange={(e) =>
                                      setCustomerAddress({
                                        ...customerAddress,
                                        mapLink: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Col>
                      )}
                      {radioVal === "map" && (
                        <Col span={24}>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("House/Flat/Block No")}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("House/Flat/Block No")}
                                    value={customerAddress.mapBuildingNo}
                                    onChange={(e) => {
                                      setCustomerAddress({
                                        ...customerAddress,
                                        mapBuildingNo: e.target.value,
                                      });
                                    }}
                                  />
                                  {errorValidation.mapBuildingNumber === 1 && (
                                    <p className="val-error">
                                      Please enter House/Flat/Block No.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("Address Line 1")}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Address Line 1")}
                                    value={customerAddress.mapStreetNo}
                                    onChange={(e) => {
                                      setCustomerAddress({
                                        ...customerAddress,
                                        mapStreetNo: e.target.value,
                                      });
                                    }}
                                  />
                                  {errorValidation.mapStreetNumber === 1 && (
                                    <p className="val-error">
                                      Please enter address line 1.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("Address Line 2 (optional)")}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Address Line 2 (optional)")}
                                    value={customerAddress.mapZoneNo}
                                    onChange={(e) => {
                                      setCustomerAddress({
                                        ...customerAddress,
                                        mapZoneNo: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="row new-field">
                              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <p className="label-text">
                                  {t("Map Link (Google Map FUll URL)")}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                                <div className="field-coulmn">
                                  <input
                                    className="field-input"
                                    type="text"
                                    placeholder={t("Map Link")}
                                    value={customerAddress.mapLinkUrl}
                                    onChange={(e) =>
                                      setCustomerAddress({
                                        ...customerAddress,
                                        mapLinkUrl: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                            </div>
                          </Col>
                          {errorValidation.mapLinkUrl === 1 && (
                            <p className="val-error">
                              Please enter map link url.
                            </p>
                          )}
                          {errorValidation.maplatLngAdrs === 1 && (
                            <Col span={24}>
                              <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                push={3}
                              >
                                <p className="val-error">
                                  Please enter valid google map url
                                </p>
                              </Col>
                            </Col>
                          )}
                          <Col span={24} push={8}>
                            <Button
                              className="flex outline-btn"
                              color="info"
                              onClick={() => fetchLatLng()}
                            >
                              <p>{t("Verify")}</p>
                            </Button>
                          </Col>
                        </Col>
                      )}
                      <Col span={24} className="mt-18">
                        <iframe
                          width="100%"
                          title="googlemap"
                          height="300"
                          name="gMap"
                          loading="lazy"
                          src={`https://www.google.com/maps/embed/v1/place?q=
                          ${
                            radioVal === "blueboard"
                              ? customerAddress.latitude
                              : customerAddress.mapLat
                          },${
                            radioVal === "blueboard"
                              ? customerAddress.longitude
                              : customerAddress.mapLong
                          }&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                        ></iframe>
                      </Col>
                    </Col>
                  </Row>
                </Card>

                <div className="row btn-float-right">
                  <div className=" btn-field">
                    <button
                      type="submit"
                      className="btn form-btn bgcolor1 ripple1 mr-5"
                    >
                      {t("Save")}
                    </button>
                    <button
                      onClick={() => setPageType("list")}
                      type="button"
                      className="btn  form-btn bgcolor3 ripple3"
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </div>
              </Card>
            </form>
          </Row>
        </Row>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default OrderAdd;
