import React, { useState, useContext } from "react";
import OrderList from "./List";
//Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { DispatchContext } from "../../context/AppProvider";

const DashboardIndex = () => {
  const [pageType, setPageType] = useState("list");
  const contextDispatch = useContext(DispatchContext);

  useEffect(() => {
    contextDispatch({
      type: "header_text",
      payload: {
        title: "Dashboard",
      },
    });
  }, []);

  const renderPages = () => {
    switch (pageType) {
      case "list":
        return <OrderList setPageType={setPageType} />;
      default:
        return null;
    }
  };
  return (
    <React.Fragment>
      <div className="page">{renderPages()}</div>
      <ToastContainer />
    </React.Fragment>
  );
};
export default DashboardIndex;
