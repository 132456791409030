import { createContext, useReducer } from "react";
const DispatchContext = createContext();
const StateContext = createContext();

const AppProvider = ({ children }) => {
  const initialState = {
    customerEditData: {},
    couponEditData: {},
    vendorEditData: {},
    driverEditData: {},
    orderEditData: {},
    orderFilterData: {},
    dashboardOrder: {},
    headerText: {},
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "vendor_edit":
        return { ...state, vendorEditData: action.payload };
      case "driver_edit":
        return { ...state, driverEditData: action.payload };
      case "customer_edit":
        return { ...state, customerEditData: action.payload };
      case "coupon_edit":
        return { ...state, couponEditData: action.payload };
      case "order_edit":
        return { ...state, orderEditData: action.payload };
      case "order_filter":
        return { ...state, orderFilterData: action.payload };
      case "dashboard_order":
        return { ...state, dashboardOrder: action.payload };
      case "header_text":
        return { ...state, headerText: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export { AppProvider, DispatchContext, StateContext };
