import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    logData: {},
    error: {},
    isLoading: false,
  },
  reducers: {
    getLoginFetch: (state, payload) => {
      state.isLoading = true;
      state.logData = {};
      state.error = {};
    },
    getLoginSuccess: (state, action) => {
      state.isLoading = false;
      state.logData = action.payload;
    },
    getLoginError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    userLogout: (state, action) => {
      state.isLoading = false;
      state.logData = {};
      state.error = {};
      localStorage.setItem("language", "");
      storage.removeItem("persist:root");
    },
    resetData: (state, action) => {
      state.logData = {};
      state.error = {};
    },
  },
});

export const {
  getLoginFetch,
  getLoginSuccess,
  getLoginError,
  userLogout,
  resetData,
} = loginSlice.actions;
export default loginSlice.reducer;
