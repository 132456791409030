import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState("en");

  useEffect(() => {
    if (localStorage.getItem("language") != "") {
      setLang(localStorage.getItem("language"));
    }
  }, []);

  const changeLanguage = (e) => {
    const lng = e.target.value;
    i18n.changeLanguage(lng);
    setLang(lng);
    localStorage.setItem("language", lng);
  };

  return (
    <select
      className="ant-space css-dev-only-do-not-override-k7429z ant-space-horizontal ant-space-align-center"
      onChange={(e) => changeLanguage(e)}
      value={lang}
    >
      <option value="en">English</option>
      <option value="ar">Arabic</option>
    </select>
  );
}
export default LanguageSelector;
