import { createBrowserRouter } from "react-router-dom";
import Login from "./pages/Authentication/Login";
import Layout from "./components";
import Vendor from "./pages/Vendor";
import Customer from "./pages/Customer";
import Coupon from "./pages/Coupon";
import Driver from "./pages/Driver";
import Order from "./pages/Order";
import Dashboard from "./pages/Dashboard";
import Wallet from "./pages/Wallet";
import Settings from "./pages/Settings";
// import Profile from "./pages/Settings/Profile";
import PaymentReturn from "./pages/Common/PaymentReturn";
import TermsAndConditions from "./pages/Authentication/TermsAndConditions";
import MapContainer from "./pages/CustomerLocation";
// import Test from "./pages/Test";


const App = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/termsandconditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/:selectlocation",
    element: <MapContainer />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "vendor",
        element: <Vendor />,
      },
      {
        path: "customer",
        element: <Customer />,
      },
      {
        path: "coupon",
        element: <Coupon />,
      },
      {
        path: "driver",
        element: <Driver />,
      },
      {
        path: "order",
        element: <Order />,
      },
      {
        path: "wallet",
        element: <Wallet />,
      },
      {
        path: "redirect_url/:page",
        element: <PaymentReturn />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      //     {
      //       path: "edit/profile",
      //       element: <Profile />,
      //     },
      {
        path: "driver/order",
        element: <Order />,
      },
    ],
  },
]);

export default App;
