import React, { useEffect, Fragment, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import filterImg from "../../assets/images/filter-icon.png";
import deleteImg from "../../assets/images/Delete.svg";
import editImg from "../../assets/images/Edit.svg";
import searchImg from "../../assets/images/search.svg";
import moment from "moment";
import {
  listCoupon,
  deleteCoupon,
  updateCoupon,
  resetFunction,
} from "../../store/coupon/couponState";
//Toastify
import { toast } from "react-toastify";
import { DispatchContext } from "../../context/AppProvider";
import { Button, Col, Row } from "antd";
import { Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';

const CouponList = ({ setPageType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch(),
    contextDispatch = useContext(DispatchContext);
  const couponList = useSelector((state) => state.Coupon.couponList);
  const couponError = useSelector((state) => state.Coupon.couponError);
  const deleteCouponSuccess = useSelector(
    (state) => state.Coupon.deleteCouponSuccess
  );
  const deleteCouponError = useSelector(
    (state) => state.Coupon.deleteCouponError
  );

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;
  const endOffset = itemOffset + itemsPerPage;

  let currentItems = [];
  let pageCount = 0;
  let slCount = 0;
  if (
    couponList &&
    couponList.data !== undefined &&
    Object.keys(couponList.data).length > 0
  ) {
    currentItems = couponList.data.slice(itemOffset, endOffset);
    pageCount = Math.ceil(couponList.data.length / itemsPerPage);
  }

  if (Object.keys(currentItems).length > 0) {
    const index = couponList.data.findIndex((itm) => {
      return itm._id === currentItems[0]._id;
    });
    slCount = index;
  }

  useEffect(() => {
    if (deleteCouponSuccess && deleteCouponSuccess.success === true) {
      toast(deleteCouponSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteCouponSuccess, dispatch]);

  useEffect(() => {
    if (
      deleteCouponError &&
      deleteCouponError.data &&
      deleteCouponError.data.success === false
    ) {
      toast.error(deleteCouponError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteCouponError, dispatch]);

  useEffect(() => {
    dispatch(listCoupon());
  }, [deleteCouponSuccess, dispatch]);

  useEffect(() => {
    if (couponError && couponError.data && couponError.data.success === false) {
      toast.error(couponError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [dispatch, couponError]);

  const handleSearchOnChange = (search) => {
    if (search !== "") {
      if (search.length >= 0) {
        let payload = { keyword: search };
        dispatch(listCoupon(payload));
      }
    } else {
      dispatch(listCoupon({ keyword: "" }));
    }
  };

  const deleteCouponAction = (id) => {
    dispatch(deleteCoupon(id));
  };

  const handleStatusChange = (id, status) => {
    let pLoad = { status: status };
    let payload = { couponId: id, data: pLoad };
    dispatch(updateCoupon(payload));
  };

  const handlePageClick = (event) => {
    let newOffset = 0;
    if (
      couponList &&
      couponList.data !== undefined &&
      Object.keys(couponList.data).length > 0
    ) {
      newOffset = (event.selected * itemsPerPage) % couponList.data.length;
    }
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  let tblCount = 0;

  return (
    <Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          <Row className="common-head">
            <p className="head-p">{t('All Discounts')}</p>
            <Button
              onClick={() => {
                contextDispatch({
                  type: "coupon_edit",
                  payload: {},
                });
                setPageType("add");
              }}
              type="button"
              className="top-btn btn form-btn bgcolor1 ripple1"
            >
              {t('ADD NEW DISCOUNT')}
            </Button>
          </Row>
          <Row className="common-filter">
            <Col span={24}>
              <Row className="cntnt-top">
                <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                  <div className="filter-by">
                    <img src={filterImg} alt="" />
                    <p>{t('Filter by')}</p>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className="filter-coulmn big-srch">
                    <img alt="" className="srch-icn" src={searchImg} />
                    <input
                      className="filter-input"
                      type="text"
                      placeholder={t('Search')}
                      onChange={(e) => handleSearchOnChange(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Table borderless className="coupon-tbl">
              <thead>
                <tr>
                  <th>{t('Sl No')}</th>
                  <th>{t('Title')}</th>
                  <th>{t('Vendor')}</th>
                  <th>{t('Discount Code')}</th>
                  <th>{t('Discount Value')}</th>
                  <th>{t('Discount Type')}</th>
                  <th>{t('Total Count')}</th>
                  <th>{t('Used Count')}</th>
                  <th>{t('Start Date')}</th>
                  <th>{t('End Date')}</th>
                  <th className="action-head">{t('Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {currentItems ? (
                  currentItems.map((sec, i) => {
                    slCount += 1;
                    return (
                      <tr key={i}>
                        <td className="td-white">{slCount}</td>
                        <td className="c-table__cell td-white">{sec.title}</td>
                        <td className="c-table__cell td-white">
                          {sec.vendor && sec.vendor.name}
                        </td>
                        <td className="c-table__cell td-white">
                          {sec.couponCode}
                        </td>
                        <td className="c-table__cell td-white">
                          {sec.discountValue}
                        </td>
                        <td className="c-table__cell td-white">
                          {sec.discountType}
                        </td>
                        <td className="c-table__cell td-white">
                          {sec.totalCount}
                        </td>
                        <td className="c-table__cell td-white">
                          {sec.usedCount}
                        </td>
                        <td className="c-table__cell td-white">
                          {moment(sec.startDate).format("DD-MM-YYYY")}
                        </td>
                        <td className="c-table__cell td-white">
                          {moment(sec.endDate).format("DD-MM-YYYY")}
                        </td>
                        <td className="c-table__cell td-white">
                          <div className="actions">
                            {/* <span className="blue-eye material-icons">
                        visibility
                      </span> */}
                            <span className="black-edit material-icons">
                              <img
                                src={editImg}
                                alt=""
                                onClick={() => {
                                  contextDispatch({
                                    type: "coupon_edit",
                                    payload: sec,
                                  });
                                  setPageType("edit");
                                }}
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                            {/* <span className="black-edit material-icons">
                                {sec.status === true ? (
                                  <img
                                    src={cancelImg}
                                    alt=""
                                    onClick={() => {
                                      handleStatusChange(sec._id, false);
                                    }}
                                    title="{t('DeActivate')}"
                                    style={{
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={tickImg}
                                    alt=""
                                    onClick={() => {
                                      handleStatusChange(sec._id, true);
                                    }}
                                    title="Activate"
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                              </span> */}
                            <span className="black-edit material-icons">
                              <img
                                alt=""
                                src={deleteImg}
                                onClick={() => {
                                  if (window.confirm("Delete the item?")) {
                                    deleteCouponAction(sec._id);
                                  }
                                }}
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>{t("No Records Found")}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
          <Row>
            <div className="pagination-div">
              <ReactPaginate
                activeClassName={"item active "}
                breakClassName={"item break-me "}
                breakLabel={"..."}
                containerClassName={"pagination"}
                disabledClassName={"disabled-page"}
                marginPagesDisplayed={2}
                nextClassName={"item next "}
                nextLabel={">>"}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                pageClassName={"item pagination-page "}
                pageRangeDisplayed={5}
                previousClassName={"item previous"}
                previousLabel={"<<"}
                renderOnZeroPageCount={null}
              />
            </div>
          </Row>
        </Row>
      </div>
    </Fragment>
  );
};

export default CouponList;
