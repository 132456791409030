import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    createCustomerStatus: {},
    createCustomerStatusError: {},
    isLoading: false,
    customerList: {},
    customerListError: {},
    updateCustomerStatus: {},
    updateCustomerStatusError: {},
    deleteCustomerError: {},
    deleteCustomerSuccess: {},
    customerByVendorList: {},
    customerByVendorError: {},
  },
  reducers: {
    createCustomer: (state, payload) => {
      state.isLoading = true;
      state.createCustomerStatus = {};
      state.createCustomerStatusError = {};
    },
    createCustomerSuccess: (state, action) => {
      state.isLoading = false;
      state.createCustomerStatus = action.payload;
      state.createCustomerStatusError = {};
    },
    createCustomerError: (state, action) => {
      state.isLoading = false;
      state.createCustomerStatusError = action.payload;
      state.createCustomerStatus = {};
    },
    updateCustomer: (state, action) => {
      state.updateCustomerStatus = {};
      state.updateCustomerStatusError = {};
    },
    updateCustomerSuccess: (state, action) => {
      state.updateCustomerStatus = action.payload;
      state.updateCustomerStatusError = {};
    },
    updateCustomerError: (state, action) => {
      state.updateCustomerStatus = {};
      state.updateCustomerStatusError = action.payload;
    },
    deleteCustomer: (state, action) => {
      state.deleteCustomerError = {};
      state.deleteCustomerSuccess = {};
    },
    deleteCustomerSuccess: (state, action) => {
      state.deleteCustomerError = {};
      state.deleteCustomerSuccess = action.payload;
    },
    deleteCustomerError: (state, action) => {
      state.deleteCustomerError = action.payload;
      state.deleteCustomerSuccess = {};
    },
    listCustomer: (state, payload) => {
      state.isLoading = true;
      state.customerList = {};
    },
    listCustomerSuccess: (state, action) => {
      state.isLoading = false;
      state.customerList = action.payload;
      state.customerError = {};
    },
    listCustomerError: (state, action) => {
      state.isLoading = false;
      state.customerError = action.payload;
      state.customerList = {};
    },
    listCustomerByVendor: (state, payload) => {
      state.isLoading = true;
      state.customerByVendorList = {};
    },
    listCustomerByVendorSuccess: (state, action) => {
      state.isLoading = false;
      state.customerByVendorList = action.payload;
      state.customerByVendorError = {};
    },
    listCustomerByVendorError: (state, action) => {
      state.isLoading = false;
      state.customerByVendorError = action.payload;
      state.customerByVendorList = {};
    },
    resetFunction: (state, action) => {
      state.createCustomerStatus = {};
      state.createCustomerStatusError = {};
      state.isLoading = false;
      state.customerList = {};
      state.customerListError = {};
      state.updateCustomerStatus = {};
      state.updateCustomerStatusError = {};
      state.deleteCustomerError = {};
      state.deleteCustomerSuccess = {};
      state.customerByVendorList = {};
      state.customerByVendorError = {};
    },
  },
});

export const {
  createCustomer,
  createCustomerSuccess,
  createCustomerError,
  updateCustomer,
  updateCustomerSuccess,
  updateCustomerError,
  deleteCustomer,
  deleteCustomerSuccess,
  deleteCustomerError,
  listCustomer,
  listCustomerSuccess,
  listCustomerError,
  listCustomerByVendor,
  listCustomerByVendorSuccess,
  listCustomerByVendorError,
  resetFunction,
} = customerSlice.actions;
export default customerSlice.reducer;
