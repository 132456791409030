import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import orderService from "../../service/order/OrderService";
import {
  createOrderSuccess,
  createOrderError,
  updateOrderSuccess,
  updateOrderError,
  updateOrderStatusSuccess,
  updateOrderStatusError,
  updateOrderDriverSuccess,
  updateOrderDriverError,
  deleteOrderSuccess,
  deleteOrderError,
  listOrderSuccess,
  listOrderError,
  dashboardListOrderSuccess,
  dashboardListOrderError,
  updateCustomerLocationSuccess,
  updateCustomerLocationError,
  checkIfCustLocUpdatedSuccess,
  checkIfCustLocUpdatedError,
} from "./orderState";

function* createOrder(action) {
  const data = yield call(orderService.createOrder, action.payload);
  if (data.msg === "success") {
    yield put(createOrderSuccess(data.response));
  } else {
    yield put(createOrderError(data.response));
  }
}

function* updateOrder(action) {
  const data = yield call(orderService.updateOrder, action.payload);
  if (data.msg === "success") {
    yield put(updateOrderSuccess(data.response));
  } else {
    yield put(updateOrderError(data.response));
  }
}

function* updateOrderStatus(action) {
  const data = yield call(orderService.updateOrderStatus, action.payload);
  if (data.msg === "success") {
    yield put(updateOrderStatusSuccess(data.response));
  } else {
    yield put(updateOrderStatusError(data.response));
  }
}

function* updateOrderDriver(action) {
  const data = yield call(orderService.updateOrderDriver, action.payload);
  if (data.msg === "success") {
    yield put(updateOrderDriverSuccess(data.response));
  } else {
    yield put(updateOrderDriverError(data.response));
  }
}

function* deleteOrder(action) {
  const data = yield call(orderService.deleteOrder, action.payload);
  if (data.msg === "success") {
    yield put(deleteOrderSuccess(data.response));
  } else {
    yield put(deleteOrderError(data.response));
  }
}

function* listOrder(action) {
  const data = yield call(orderService.listOrder, action.payload);
  if (data.msg === "success") {
    yield put(listOrderSuccess(data.response));
  } else {
    yield put(listOrderError(data.response));
  }
}

function* dashboardListOrder() {
  const data = yield call(orderService.dashboardListOrder);
  if (data.msg === "success") {
    yield put(dashboardListOrderSuccess(data.response));
  } else {
    yield put(dashboardListOrderError(data.response));
  }
}

function* updateCustomerLocation(action) {
  const data = yield call(orderService.updateCustomerLocation, action.payload);
  if (data.msg === "success") {
    yield put(updateCustomerLocationSuccess(data.response));
  } else {
    yield put(updateCustomerLocationError(data.response));
  }
}

function* checkIfCustLocUpdated(action) {
  const data = yield call(orderService.checkIfCustLocUpdated, action.payload);
  if (data.msg === "success") {
    yield put(checkIfCustLocUpdatedSuccess(data.response));
  } else {
    yield put(checkIfCustLocUpdatedError(data.response));
  }
}

function* orderSaga() {
  yield takeEvery("order/createOrder", createOrder);
  yield takeLatest("order/listOrder", listOrder);
  yield takeEvery("order/updateOrder", updateOrder);
  yield takeEvery("order/updateOrderStatus", updateOrderStatus);
  yield takeEvery("order/updateOrderDriver", updateOrderDriver);
  yield takeEvery("order/deleteOrder", deleteOrder);
  yield takeEvery("order/dashboardListOrder", dashboardListOrder);
  yield takeEvery("order/updateCustomerLocation", updateCustomerLocation);
  yield takeEvery("order/checkIfCustLocUpdated", checkIfCustLocUpdated);
}

export default orderSaga;
