import axios from "axios";
import { store } from "../store";
const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  let token = state.Login.logData.vendor.token;
  config.params = config.params || {};
  config.headers["Authorization"] = "Bearer " + token;

  return config;
});

export default axiosInstance;
