import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paymentReturnStatus } from "../../store/common/commonState";
import { resetFunction } from "../../store/order/orderState";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentReturn = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [page, setPage] = useState("");
  const paymentSts = useSelector((state) => state.Common.paymentStatus);
  const rechargeOnline = useSelector((state) => state.Wallet.rechargeWallet);
  const orderSuccess = useSelector((state) => state.Order.createOrderStatus);

  useEffect(() => {
    // let url = window.location.href.split("tap_id=");
    // let tapdata = url.length === 2 ? url[1].split("&data=") : "";
    // let tapId = tapdata.length === 2 ? tapdata[0] : url[1];
    // setPage(
    //   window.location.href.includes("/wallet") === true
    //     ? "wallet"
    //     : window.location.href.includes("/order") === true
    //     ? "order"
    //     : ""
    // );
    // if (tapId !== "") {
    //   dispatch(paymentReturnStatus({ tapId: tapId }));
    // }
    if (rechargeOnline && Object.keys(rechargeOnline).length > 0) {
      let paymentId = rechargeOnline.paymentId;
      setPage(
        window.location.href.includes("/wallet") === true
          ? "wallet"
          : window.location.href.includes("/order") === true
          ? "order"
          : ""
      );
      if (paymentId !== "") {
        dispatch(paymentReturnStatus({ paymentId: paymentId }));
      }
    } else if (
      orderSuccess &&
      orderSuccess.success === true &&
      orderSuccess.message === "Payment url"
    ) {
      let paymentId = orderSuccess.paymentId;
      setPage(
        window.location.href.includes("/wallet") === true
          ? "wallet"
          : window.location.href.includes("/order") === true
          ? "order"
          : ""
      );
      if (paymentId !== "") {
        dispatch(paymentReturnStatus({ paymentId: paymentId }));
      }
    }
  }, [rechargeOnline, orderSuccess]);

  useEffect(() => {
    if (paymentSts && Object.keys(paymentSts).length > 0) {
      if (page === "wallet") {
        history("/wallet");
      } else if (page === "order") { 
        dispatch(resetFunction());
        history("/order");
      }
    }
  }, [paymentSts]);

  return <ToastContainer />;
};
export default PaymentReturn;
