import { combineReducers } from "redux";
// Authentication
import Login from "./auth/login/loginState";
// VendorCreation
import Vendor from "./vendor/vendorState";
// VendorCreation
import Driver from "./driver/driverState";
// Image Upload
import Common from "./common/commonState";
import Customer from "./customer/customerState";
import Coupon from "./coupon/couponState";
import Order from "./order/orderState";
import Wallet from "./wallet/walletState";
const rootReducer = combineReducers({
  // public
  Login,
  Vendor,
  Common,
  Customer,
  Coupon,
  Driver,
  Order,
  Wallet,
});

export default rootReducer;
