import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listOrder,
  deleteOrder,
  resetFunction,
} from "../../store/order/orderState";
import { listDriver } from "../../store/driver/driverState";
import { listVendor } from "../../store/vendor/vendorState";
import { Button, Col, Row, Space, Tabs } from "antd";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import { getCurrentDate } from "../../utils";
import excelImg from "../../assets/images/excel-file.png";
import classnames from "classnames";
import OrderTableData from "./TableData";
import moment from "moment";
import { DispatchContext, StateContext } from "../../context/AppProvider";
import { downloadExcel } from "react-export-table-to-excel";
import { useTranslation } from "react-i18next";
import { Download, Brightness1, HighlightOff } from "@mui/icons-material";
import { read, utils, writeFile } from "xlsx";
import { Input, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import axios from "axios";

const OrderList = ({ setPageType, urlFrom }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contextDispatch = useContext(DispatchContext);
  const contData = useContext(StateContext);
  const filterData = contData.orderFilterData;
  const [customerName, setCustomerName] = useState("");
  const [driver, setDriver] = useState({
    label: t("Select Driver"),
    value: "",
  });
  const [vendorSelect, setVendorSelect] = useState({
    label: t("Select Vendor"),
    value: "",
  });
  const [orderNumber, setOrderNumber] = useState("");
  const [month, setMonth] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [customActiveTab, setCustomActiveTab] = useState(
    filterData.customActiveTab === "" ||
      filterData.customActiveTab === undefined
      ? "1"
      : filterData.customActiveTab
  );
  const [selectDriverOptions, setSelectDriverOptions] = useState([]);
  const [selectVendorOptions, setSelectVendorOptions] = useState([]);
  const [excelImportData, setExcelImportdata] = useState([]);
  const [excelImport, setExcelImport] = useState(false);
  const [excelImportMsg, setExcelImportMsg] = useState("");
  const [showSpin, setShowSpin] = useState(false);

  const deleteOrderSuccess = useSelector(
    (state) => state.Order.deleteOrderSuccess
  );
  const listDriverSuccess = useSelector((state) => state.Driver.driverList);
  const deleteOrderError = useSelector((state) => state.Order.deleteOrderError);
  const updateStatusSuccess = useSelector(
    (state) => state.Order.updateOrderStatus
  );
  const updateStatusError = useSelector(
    (state) => state.Order.updateOrderStatusError
  );
  const updateDriverSuccess = useSelector(
    (state) => state.Order.updateOrderDriver
  );
  const updateDriverError = useSelector(
    (state) => state.Order.updateOrderDriverError
  );
  const vendorLists = useSelector((state) => state.Vendor.vendorList);
  const login = useSelector((state) => state.Login.logData);

  useEffect(() => {
    dispatch(listDriver());
    dispatch(listVendor());
  }, [dispatch]);

  const toggle = () => {
    setExcelImport(!excelImport);
    if (!excelImport === false) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (
      Object.keys(filterData).length > 0 &&
      Object.values(filterData).every(
        (x) => x !== null || x !== "" || x !== undefined
      )
    ) {
      setCustomerName(filterData.customerName);
      if (typeof filterData.driver === "string") {
        filterData.driver = { label: t("Select Driver"), value: "" };
      }
      setDriver(filterData.driver);
      setOrderNumber(filterData.orderNumber);
      setMonth(filterData.month);
      setVendorSelect(filterData.vendorSelect);
      setFromDate(filterData.fromDate);
      setToDate(filterData.toDate);
      filterData.customActiveTab === ""
        ? setCustomActiveTab("1")
        : setCustomActiveTab(filterData.customActiveTab);
    }
  }, [filterData]);

  useEffect(() => {
    contextDispatch({
      type: "order_filter",
      payload: {
        customerName: customerName,
        orderNumber: orderNumber,
        month: month,
        fromDate: fromDate,
        toDate: toDate,
        customActiveTab: customActiveTab,
        driver: driver,
        vendorSelect: vendorSelect,
      },
    });
    let payloadOrder = getOrderStatus(customActiveTab);

    let payload = {
      customerName: customerName,
      orderNumber: orderNumber,
      orderStatus: payloadOrder,
      driver: driver ? driver.value : "",
      toDate:
        toDate !== null
          ? moment.utc(toDate).local().format("YYYY-MM-DD")
          : null,
      fromDate:
        fromDate !== null
          ? moment.utc(fromDate).local().format("YYYY-MM-DD")
          : null,
      month:
        month !== null ? moment.utc(month).local().format("YYYY-MM") : null,
      vendor: vendorSelect ? vendorSelect.value : "",
      urlFrom: urlFrom,
    };
    dispatch(listOrder(payload));
  }, [urlFrom]);

  useEffect(() => {
    if (deleteOrderSuccess && deleteOrderSuccess.success === true) {
      toast(deleteOrderSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteOrderSuccess, dispatch]);

  useEffect(() => {
    if (
      deleteOrderError &&
      deleteOrderError.data &&
      deleteOrderError.data.success === false
    ) {
      toast.error(deleteOrderError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteOrderError, dispatch]);

  let excel = [];
  const orderList = useSelector((state) => state.Order.orderList);
  const handleDownloadExcel = () => {
    let header = [
      "Order ID",
      "Vendor",
      "Delivery Date",
      "Package Charge",
      "Package Payment Type",
      "D Charge",
      "COD",
      "Payment Type",
      "Delivery Area",
      "Driver",
      "Status",
    ];
    if (orderList && orderList.success === true) {
      let data = orderList.data;
      excel = data.map((item) => ({
        id: item.orderNumber,
        vendor: item.vendor && item.vendor.name,
        deliveryDate: moment(item.deliveryDate).format("YYYY-MM-DD"),
        packageCharge: item.amountToBeCollected,
        packagePaymentType: item.packagePaymentType,
        deliveryCharge: item.deliveryCharge,
        driverAmountCollected: item.driverAmountCollected,
        paymentType: item.paymentType,
        deliveryArea: item.dropoffAddress && item.dropoffAddress.locationName,
        driver: item.driver && item.driver.name,
        status: item.status,
      }));
      downloadExcel({
        fileName: "orders",
        sheet: "react-export-table-to-excel",
        tablePayload: {
          header,
          // accept two different data structures
          body: excel,
        },
      });
    }
  };

  useEffect(() => {
    let msg = "";
    if (updateStatusSuccess && updateStatusSuccess.success === true) {
      msg = updateStatusSuccess.message;
    }
    if (updateDriverSuccess && updateDriverSuccess.success === true) {
      msg = updateDriverSuccess.message;
    }
    if (msg !== "") {
      toast(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());

      let payloadOrder = getOrderStatus(customActiveTab);
      let payload = {
        customerName: customerName,
        orderNumber: orderNumber,
        orderStatus: payloadOrder,
        driver: driver ? driver.value : "",
        toDate:
          toDate !== null
            ? moment.utc(toDate).local().format("YYYY-MM-DD")
            : null,
        fromDate:
          fromDate !== null
            ? moment.utc(fromDate).local().format("YYYY-MM-DD")
            : null,
        month:
          month !== null ? moment.utc(month).local().format("YYYY-MM") : null,
        vendor: vendorSelect ? vendorSelect.value : "",
        urlFrom: urlFrom,
      };
      dispatch(listOrder(payload));
    }
    setPageType("list");
  }, [updateStatusSuccess, updateDriverSuccess]);

  useEffect(() => {
    let msg = "";
    if (
      updateStatusError &&
      updateStatusError.data &&
      updateStatusError.data.success === false
    ) {
      msg = updateStatusError.data.message;
    }
    if (
      updateDriverError &&
      updateDriverError.data &&
      updateDriverError.data.success === false
    ) {
      msg = updateDriverError.data.message;
    }
    if (msg !== "") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
      let payloadOrder = getOrderStatus(customActiveTab);
      let payload = {
        customerName: customerName,
        orderNumber: orderNumber,
        orderStatus: payloadOrder,
        driver: driver ? driver.value : "",
        toDate:
          toDate !== null
            ? moment.utc(toDate).local().format("YYYY-MM-DD")
            : null,
        fromDate:
          fromDate !== null
            ? moment.utc(fromDate).local().format("YYYY-MM-DD")
            : null,
        month:
          month !== null ? moment.utc(month).local().format("YYYY-MM") : null,
        vendor: vendorSelect ? vendorSelect.value : "",
        urlFrom: urlFrom,
      };
      dispatch(listOrder(payload));
    }
  }, [updateDriverError, updateStatusError]);

  useEffect(() => {
    if (
      listDriverSuccess &&
      listDriverSuccess &&
      listDriverSuccess.success === true
    ) {
      let data = listDriverSuccess.data;
      const filteredData = data.filter((item) => item.status === true);
      const driverOptions = filteredData.map((item) => ({
        label: item.name, // <-- input values you are matching
        value: item._id,
      }));
      setSelectDriverOptions(driverOptions);
    }
  }, [dispatch, listDriverSuccess]);

  useEffect(() => {
    if (vendorLists && vendorLists && vendorLists.success === true) {
      let data = vendorLists.data;
      const filteredData = data.filter((item) => item.status === true);
      const vendorOptions = filteredData.map((item) => ({
        label: item.name, // <-- input values you are matching
        value: item._id,
      }));
      setSelectVendorOptions(vendorOptions);
    }
  }, [dispatch, vendorLists]);

  //Tab Content and onChange
  const items = [
    {
      key: "1",
      label: t("All Orders"),
      children: (
        <OrderTableData
          customerName={customerName}
          setCustomerName={setCustomerName}
          orderNumber={orderNumber}
          setOrderNumber={setOrderNumber}
          driver={driver}
          setDriver={setDriver}
          selectDriverOptions={selectDriverOptions}
          selectVendorOptions={selectVendorOptions}
          month={month}
          setMonth={setMonth}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          setPageType={setPageType}
          vendorSelect={vendorSelect}
          setVendorSelect={setVendorSelect}
          orderStatus={[
            "pending",
            "packed",
            "outForDelivery",
            "delivered",
            "cancelled",
          ]}
          urlFrom={urlFrom}
        />
      ),
    },
    {
      key: "2",
      label: t("On-Going"),
      children: (
        <OrderTableData
          customerName={customerName}
          setCustomerName={setCustomerName}
          orderNumber={orderNumber}
          setOrderNumber={setOrderNumber}
          driver={driver}
          setDriver={setDriver}
          selectDriverOptions={selectDriverOptions}
          selectVendorOptions={selectVendorOptions}
          month={month}
          setMonth={setMonth}
          vendorSelect={vendorSelect}
          setVendorSelect={setVendorSelect}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          setPageType={setPageType}
          orderStatus={["packed", "outForDelivery"]}
          urlFrom={urlFrom}
        />
      ),
    },
    {
      key: "3",
      label: t("Pending"),
      children: (
        <OrderTableData
          customerName={customerName}
          setCustomerName={setCustomerName}
          orderNumber={orderNumber}
          setOrderNumber={setOrderNumber}
          driver={driver}
          setDriver={setDriver}
          selectDriverOptions={selectDriverOptions}
          selectVendorOptions={selectVendorOptions}
          month={month}
          setMonth={setMonth}
          vendorSelect={vendorSelect}
          setVendorSelect={setVendorSelect}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          setPageType={setPageType}
          orderStatus={["pending"]}
          urlFrom={urlFrom}
        />
      ),
    },
    {
      key: "4",
      label: t("Completed"),
      children: (
        <OrderTableData
          customerName={customerName}
          setCustomerName={setCustomerName}
          orderNumber={orderNumber}
          setOrderNumber={setOrderNumber}
          driver={driver}
          setDriver={setDriver}
          selectDriverOptions={selectDriverOptions}
          selectVendorOptions={selectVendorOptions}
          month={month}
          setMonth={setMonth}
          vendorSelect={vendorSelect}
          setVendorSelect={setVendorSelect}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          setPageType={setPageType}
          orderStatus={["delivered"]}
          urlFrom={urlFrom}
        />
      ),
    },
  ];
  const onChange = (key) => {
    setCustomActiveTab(key);
    let payloadOrder = getOrderStatus(key);
    let payload = {
      customerName: customerName,
      orderNumber: orderNumber,
      orderStatus: payloadOrder,
      driver: driver ? driver.value : "",
      toDate:
        toDate !== null
          ? moment.utc(toDate).local().format("YYYY-MM-DD")
          : null,
      fromDate:
        fromDate !== null
          ? moment.utc(fromDate).local().format("YYYY-MM-DD")
          : null,
      month:
        month !== null ? moment.utc(month).local().format("YYYY-MM") : null,
      vendor: vendorSelect ? vendorSelect.value : "",
      urlFrom: urlFrom,
    };
    dispatch(listOrder(payload));

    contextDispatch({
      type: "order_filter",
      payload: {
        customerName: customerName,
        orderNumber: orderNumber,
        month: month,
        fromDate: fromDate,
        toDate: toDate,
        orderStatus: payloadOrder,
        customActiveTab: key,
        driver: driver,
        vendorSelect: vendorSelect,
      },
    });
  };

  const getOrderStatus = (key) => {
    if (key === "1") {
      return ["pending", "packed", "outForDelivery", "delivered", "cancelled"];
    } else if (key === "2") {
      return ["packed", "outForDelivery"];
    } else if (key === "3") {
      return ["pending"];
    } else if (key === "4") {
      return ["delivered"];
    }
  };
  // End

  const handleExportHeader = () => {
    const headings = [
      [
        "Customer Name",
        "Customer Phone(with countrycode)",
        "Send a link to the customer to update the address (yes/no)",
        "Location Name",
        "Building Number",
        "Street Number",
        "Zone Number",
        "Package Weight (in kg)",
        "Package Payment Type (COD/Online)",
        "Amount to be collected (If COD)",
        "Vehicle (car/pickup/twowheeler)",
        "Payment Type (COD/Wallet)",
        "Additional Info",
      ],
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    // utils.sheet_add_json(ws, excelImport, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, "Order_" + Date() + ".xlsx");
  };

  const handleImport = async ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setShowSpin(true);
          let exclData = [...excelImportData];
          // rows.map(async (item, i) => {
          let i = 0;
          for (const item of rows) {
            const myPromise = await Promise.resolve().then(async () => {
              i = i + 1;
              let data = {
                customerName: "",
                customerPhone: "",
                deliveryDate: new Date(),
                packageWeight: 0,
                pickUpVehicle: "car",
                amountToBeCollected: 0,
                deliveryType: "Normal",
                packagePaymentType: "COD",
                dropoffAddress: {
                  locationName: "",
                  latitude: "",
                  longitude: "",
                  buildingNumber: "",
                  streetNumber: "",
                  zoneNumber: "",
                  mapLink: "",
                  muncipality: "",
                  isBlueBoard: true,
                },
                platform: "Web",
                addInfo: "",
                paymentType: "COD",
                deliveryCharge: 0,
                uploadstatus: 0,
                uploadmsg: "",
                index: i,
                customerMsg: false,
              };

              let keyItm = Object.keys(item);
              let valItm = Object.values(item);

              let custIndex = keyItm.findIndex(
                (itm) => itm === "Customer Name"
              );
              data.customerName =
                valItm[custIndex] !== undefined ? valItm[custIndex] : "";

              let custPhIndex = keyItm.findIndex(
                (itm) => itm === "Customer Phone(with countrycode)"
              );
              data.customerPhone =
                valItm[custPhIndex] !== undefined
                  ? valItm[custPhIndex].toString().trim()
                  : "";

              let locIndex = keyItm.findIndex((itm) => itm === "Location Name");
              data.dropoffAddress.locationName =
                valItm[locIndex] !== undefined ? valItm[locIndex] : "";

              let buildIndex = keyItm.findIndex(
                (itm) => itm === "Building Number"
              );
              data.dropoffAddress.buildingNumber =
                valItm[buildIndex] !== undefined
                  ? valItm[buildIndex].toString()
                  : "";

              let streetIndex = keyItm.findIndex(
                (itm) => itm === "Street Number"
              );
              data.dropoffAddress.streetNumber =
                valItm[streetIndex] !== undefined
                  ? valItm[streetIndex].toString()
                  : "";

              let zoneIndex = keyItm.findIndex((itm) => itm === "Zone Number");
              data.dropoffAddress.zoneNumber =
                valItm[zoneIndex] !== undefined
                  ? valItm[zoneIndex].toString()
                  : "";

              let weightIndex = keyItm.findIndex(
                (itm) => itm === "Package Weight (in kg)"
              );
              data.packageWeight =
                valItm[weightIndex] !== undefined ? valItm[weightIndex] : 0;

              let packagePaymentIndex = keyItm.findIndex(
                (itm) => itm === "Package Payment Type (COD/Online)"
              );
              data.packagePaymentType =
                valItm[packagePaymentIndex] !== undefined
                  ? valItm[packagePaymentIndex]
                  : "COD";

              let amountIndex = keyItm.findIndex(
                (itm) => itm === "Amount to be collected (If COD)"
              );
              data.amountToBeCollected =
                valItm[amountIndex] !== undefined ? valItm[amountIndex] : 0;

              let vehicleIndex = keyItm.findIndex(
                (itm) => itm === "Vehicle (car/pickup/twowheeler)"
              );
              data.pickUpVehicle =
                valItm[vehicleIndex] !== undefined
                  ? valItm[vehicleIndex]
                  : "car";

              let paymentTypeIndex = keyItm.findIndex(
                (itm) => itm === "Payment Type (COD/Wallet)"
              );
              data.paymentType =
                valItm[paymentTypeIndex] !== undefined
                  ? valItm[paymentTypeIndex]
                  : "COD";

              let infoIndex = keyItm.findIndex(
                (itm) => itm === "Additional Info"
              );
              data.addInfo =
                valItm[infoIndex] !== undefined ? valItm[infoIndex] : "";

              let msgIndex = keyItm.findIndex(
                (itm) =>
                  itm ===
                  "Send a link to the customer to update the address (yes/no)"
              );
              let custSendMssg =
                valItm[msgIndex] !== undefined ? valItm[msgIndex] : false;

              if (custSendMssg.toLowerCase() === "yes") {
                data.customerMsg = true;
              }

              let adrs = {};
              if (
                data.dropoffAddress.buildingNumber &&
                data.dropoffAddress.streetNumber &&
                data.dropoffAddress.zoneNumber
              ) {
                adrs = await handleUpload(
                  data.dropoffAddress.buildingNumber,
                  data.dropoffAddress.streetNumber,
                  data.dropoffAddress.zoneNumber
                );
                if (Object.keys(adrs).length > 0) {
                  data.dropoffAddress.mapLink = adrs.mapLink;
                  data.dropoffAddress.latitude = adrs.latitude.toString();
                  data.dropoffAddress.longitude = adrs.longitude.toString();
                }
              }

              if (data.pickUpVehicle === "car")
                data.deliveryCharge =
                  login.vendor &&
                  login.vendor.vehicleDetails &&
                  login.vendor.vehicleDetails.carPrice;
              else if (data.pickUpVehicle === "pickup")
                data.deliveryCharge =
                  login.vendor &&
                  login.vendor.vehicleDetails &&
                  login.vendor.vehicleDetails.pickupPrice;
              else if (data.pickUpVehicle === "twowheeler")
                data.deliveryCharge =
                  login.vendor &&
                  login.vendor.vehicleDetails &&
                  login.vendor.vehicleDetails.twowheelerPrice;
              else data.deliveryCharge = 20;
              data.deliverySlot = "10 AM to 12 PM";
              let cnnt = i + 1;
              if (
                data.customerName != "" &&
                data.customerPhone != "" &&
                (data.pickUpVehicle === "car" ||
                  data.pickUpVehicle === "pickup" ||
                  data.pickUpVehicle === "twowheeler")
              ) {
                if (
                  data.customerMsg === false &&
                  data.dropoffAddress.mapLink != ""
                ) {
                  data.uploadstatus = 1;
                  data.uploadmsg = "";
                } else if (data.customerMsg === true) {
                  data.uploadstatus = 1;
                  data.uploadmsg = "";
                }
              } else {
                if (data.customerName === "" || data.customerPhone === "") {
                  data.uploadstatus = 0;
                  data.uploadmsg =
                    "Order in row " +
                    cnnt +
                    " is failed due to missing/invalid value for customer name/customer phone";
                }
                if (
                  data.customerMsg === false &&
                  data.dropoffAddress.mapLink === ""
                ) {
                  data.uploadstatus = 0;
                  data.uploadmsg =
                    "Order in row " +
                    cnnt +
                    " is failed..! Unable to find the location";
                }
                if (
                  data.pickUpVehicle !== "car" &&
                  data.pickUpVehicle !== "pickup" &&
                  data.pickUpVehicle !== "twowheeler"
                ) {
                  data.uploadstatus = 0;
                  data.uploadmsg =
                    "Order in row " +
                    cnnt +
                    " is failed..! Please choose vehicle type from car/pickup/twowheeler";
                }
              }
              return data;
            });
            exclData.push(myPromise);
          }
          setExcelImportdata(exclData);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    async function insertExcelData() {
      excelImportData.sort((a, b) => a.index - b.index);
      if (excelImportData.length > 0) {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + login.vendor.token,
        };
        const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

        const respnse = await axios
          .post(`${BASE_URL}vendor/order/import`, excelImportData, {
            headers: headers,
          })
          .then((response) => {
            return {
              response: response.data.result,
            };
          })
          .catch((error) => {
            if (error.response) {
              return {
                response: error.response,
              };
            } else {
              return {
                response: "Lost Connection..! Network Error",
              };
            }
          });
        setShowSpin(false);
        setExcelImport(true);
        setExcelImportMsg(respnse);
      }
    }

    insertExcelData();
  }, [excelImportData]);

  const handleUpload = async (buildingNumber, streetNumber, zoneNumber) => {
    let adrs = {};
    let response = await axios.get(
      `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS_wgs84/MapServer/0/query?where=zone_no%3D${zoneNumber}+and+street_no%3D${streetNumber}+and+building_no%3D${buildingNumber}&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=X_COORD%2CY_COORD&returnGeometry=false&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&having=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&f=pjson`
    );
    if (
      response.data &&
      Object.keys(response.data).length > 0 &&
      response.data.features &&
      response.data.features.length > 0 &&
      response.data.features[0].attributes &&
      Object.keys(response.data.features[0].attributes).length > 0
    ) {
      adrs.longitude = response.data.features[0].attributes.X_COORD;
      adrs.latitude = response.data.features[0].attributes.Y_COORD;
      adrs.mapLink = `http://maps.google.com/maps?z=15&t=m&q=loc:${adrs.latitude}+${adrs.longitude}`;
      return adrs;
    } else {
      return adrs;
    }
  };

  return (
    <Fragment>
      <div className="hd-sec">
        <Row className="order-listpage">
          <Col span={24}>
            <div className="page-header">
              {login.vendor && login.vendor.is_admin === false && (
                <>
                  <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                    <Input
                      id="docName"
                      name="file"
                      type="file"
                      onChange={handleImport}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </Col>
                  <Button
                    className="flex outline-btn"
                    color="info"
                    onClick={handleExportHeader}
                  >
                    <p>Export</p>
                    <Download />
                  </Button>
                </>
              )}
              <Button
                className="flex outline-btn"
                color="info"
                onClick={handleDownloadExcel}
              >
                <p>{t("Download As Excel")}</p>
                <img src={excelImg} alt="" />
              </Button>
              {urlFrom === "main" && (
                <Button
                  className="top-btn btn form-btn bgcolor1 ripple1"
                  onClick={() => {
                    setPageType("add");
                    contextDispatch({
                      type: "order_edit",
                      payload: {},
                    });
                  }}
                >
                  {t("ADD NEW ORDER")}
                </Button>
              )}
            </div>
            {showSpin === true && (
              <Spinner color="primary" className="spinner-style" type="grow" />
            )}
          </Col>

          <Tabs
            defaultActiveKey={customActiveTab}
            items={items}
            onChange={onChange}
          />
        </Row>
      </div>

      <Modal isOpen={excelImport} className="excel-modal" toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Bulk Import Status")}</ModalHeader>
        <ModalBody>
          <div className="modal-cntnt">
            <div className="row">
              <ol>
                {excelImportMsg != "" &&
                Array.isArray(excelImportMsg.response) === true ? (
                  excelImportMsg.response.length > 0 &&
                  excelImportMsg.response.map((mesg, i) => {
                    return (
                      <li key={i} className="mb-2">
                        {mesg.success === true ? (
                          <Brightness1
                            style={{
                              fontSize: "1rem",
                              marginRight: "5px",
                              color: "green",
                            }}
                          />
                        ) : (
                          <HighlightOff
                            style={{
                              fontSize: "1rem",
                              marginRight: "5px",
                              color: "red",
                            }}
                          />
                        )}
                        {t(mesg.message)}
                      </li>
                    );
                  })
                ) : (
                  <li key={1} className="mb-2">
                    {
                      <HighlightOff
                        style={{
                          fontSize: "1rem",
                          marginRight: "5px",
                          color: "red",
                        }}
                      />
                    }
                    {t(excelImportMsg.response)}
                  </li>
                )}
              </ol>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default OrderList;
