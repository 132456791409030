import React from "react";
// import { Layout } from "antd";
// import logo from "../../assets/images/logo-login.png";
import "../../assets/css/terms.scss";

// const { Header, Footer, Content } = Layout;

const TermsAndCondition = () => {
  return (
    <div className="terms">
      <header className="appie-header-area appie-sticky">
        <div className="container">
          <div className="header-nav-box">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-4 col-sm-5 col-4 order-1 order-sm-1">
                <div className="appie-logo-box">
                  <a href="https://godriwell.com/index.html">
                    <span className="logo-bground"></span>
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                <div className="appie-header-main-menu">
                  <ul>
                    <li>
                      <a href="https://godriwell.com/index.html"> </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4  col-md-7 col-sm-6 col-8 order-2 order-sm-3">
                <div className="appie-btn-box text-right">
                  <a className="animated_btn ml-30" href="/">
                    Back to Login
                  </a>
                  <div className="toggle-btn ml-30 canvas_open d-lg-none d-block"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="appie-hero-area">
        <div className="container">
          <h3 style={{ textAlign: "center", padding: "2rem 0rem" }}>
            Terms & Conditions
          </h3>
          <p>
            By downloading or using the app, these terms will automatically
            apply to you – you should make sure therefore that you read them
            carefully before using the app. You’re not allowed to copy or modify
            the app, any part of the app, or our trademarks in any way. You’re
            not allowed to attempt to extract the source code of the app, and
            you also shouldn’t try to translate the app into other languages or
            make derivative versions. The app itself, and all the trademarks,
            copyright, database rights, and other intellectual property rights
            related to it, still belong to GoDriwell for Trade and Transport.
            GoDriwell for Trade and Transport is committed to ensuring that the
            app is as useful and efficient as possible. For that reason, we
            reserve the right to make changes to the app or to charge for its
            services, at any time and for any reason. We will never charge you
            for the app or its services without making it very clear to you
            exactly what you’re paying for. The GoDriwell app stores and
            processes personal data that you have provided to us, to provide our
            Service. It’s your responsibility to keep your phone and access to
            the app secure. We therefore recommend that you do not jailbreak or
            root your phone, which is the process of removing software
            restrictions and limitations imposed by the official operating
            system of your device. It could make your phone vulnerable to
            malware/viruses/malicious programs, compromise your phone’s security
            features and it could mean that the GoDriwell app won’t work
            properly or at all. The app does use third-party services that
            declare their Terms and Conditions. Link to Terms and Conditions of
            third-party service providers used by the app
            <br />
            <br />
            <a href="https://www.google.com/policies/privacy/">
              Google Play Services
            </a>
            <br />
            <a href="https://www.facebook.com/about/privacy/update/printable">
              Facebook
            </a>
            <br />
            <br />
          </p>
          <p>
            You should be aware that there are certain things that GoDriwell for
            Trade and Transport will not take responsibility for. Certain
            functions of the app will require the app to have an active internet
            connection. The connection can be Wi-Fi or provided by your mobile
            network provider, but GoDriwell for Trade and Transport cannot take
            responsibility for the app not working at full functionality if you
            don’t have access to Wi-Fi, and you don’t have any of your data
            allowance left. If you’re using the app outside of an area with
            Wi-Fi, you should remember that the terms of the agreement with your
            mobile network provider will still apply. As a result, you may be
            charged by your mobile provider for the cost of data for the
            duration of the connection while accessing the app, or other
            third-party charges. In using the app, you’re accepting
            responsibility for any such charges, including roaming data charges
            if you use the app outside of your home territory (i.e. region or
            country) without turning off data roaming. If you are not the bill
            payer for the device on which you’re using the app, please be aware
            that we assume that you have received permission from the bill payer
            for using the app. Along the same lines, GoDriwell for Trade and
            Transport cannot always take responsibility for the way you use the
            app i.e. You need to make sure that your device stays charged – if
            it runs out of battery and you can’t turn it on to avail the
            Service, GoDriwell for Trade and Transport cannot accept
            responsibility. With respect to GoDriwell for Trade and Transport’s
            responsibility for your use of the app, when you’re using the app,
            it’s important to bear in mind that although we endeavor to ensure
            that it is updated and correct at all times, we do rely on third
            parties to provide information to us so that we can make it
            available to you. GoDriwell for Trade and Transport accepts no
            liability for any loss, direct or indirect, you experience as a
            result of relying wholly on this functionality of the app. At some
            point, we may wish to update the app. The app is currently available
            on Android & iOS – the requirements for the both systems(and for any
            additional systems we decide to extend the availability of the app
            to) may change, and you’ll need to download the updates if you want
            to keep using the app. GoDriwell for Trade and Transport does not
            promise that it will always update the app so that it is relevant to
            you and/or works with the Android & iOS version that you have
            installed on your device. However, you promise to always accept
            updates to the application when offered to you, We may also wish to
            stop providing the app, and may terminate use of it at any time
            without giving notice of termination to you. Unless we tell you
            otherwise, upon any termination, (a) the rights and licenses granted
            to you in these terms will end; (b) you must stop using the app, and
            (if needed) delete it from your device.
          </p>
          <br />
          <br />
          <h4>Changes to This Terms and Conditions</h4>
          <p>
            We may update our Terms and Conditions from time to time. Thus, you
            are advised to review this page periodically for any changes. We
            will notify you of any changes by posting the new Terms and
            Conditions on this page. These terms and conditions are effective as
            of 2023-09-01
          </p>
          <br />
          <br />
          <h4>Contact Us</h4>
          <p>
            If you have any questions or suggestions about our Terms and
            Conditions, do not hesitate to contact us at info@godriwell.com.
          </p>
        </div>
      </section>

      {/* <section className="appie-footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="#">
                    <img
                      src="https://godriwell.com/assets/images/logo.png"
                      alt=""
                      style={{ width: "70%" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li>
                    <a href="mailto:info@godriwell.com">
                      <i className="fa fa-envelope"></i> info@godriwell.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+97474472552">
                      <i className="fa fa-phone"></i>+974 7447 2552
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-map-marker"></i> Doha, Qatar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="footer-copyright d-flex align-items-center justify-content-between pt-35"
                style={{ marginTop: 20 }}
              >
                <div className="apps-download-btn">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-apple"></i> Download for iOS
                      </a>
                    </li>
                    <li>
                      <a className="item-2" href="#">
                        <i className="fa fa-google-play"></i> Download for Android
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="copyright-text">
                  <p>Copyright © 2023 GoDriwell. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default TermsAndCondition;
