import React, { useEffect, useState, useContext } from "react";
import VendorAdd from "./Form";
import VendorList from "./List";
import { resetFunction } from "../../store/vendor/vendorState";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DispatchContext } from "../../context/AppProvider";

const VendorIndex = () => {
  const [pageType, setPageType] = useState("list");
  const dispatch = useDispatch();
  const stateData = useLocation();

  const createVendorSuccess = useSelector((state) => state.Vendor.vendorData);
  const createVendorError = useSelector((state) => state.Vendor.error);
  const vendorUpdateStatus = useSelector(
    (state) => state.Vendor.updateVendorStatus
  );
  const vendorUpdateErrorStatus = useSelector(
    (state) => state.Vendor.updateVendorStatusError
  );

  const contextDispatch = useContext(DispatchContext);

  useEffect(() => {
    contextDispatch({
      type: "header_text",
      payload: {
        title: "Vendors",
      },
    });
  }, []);

  useEffect(() => {
    if (stateData.state !== null) {
      if (stateData.state.pageFrom === "sidebar") {
        setPageType("list");
      }
    }
  }, [stateData]);

  useEffect(() => {
    let msg = "";
    if (createVendorSuccess && createVendorSuccess.success === true) {
      msg = createVendorSuccess.message;
    }
    if (vendorUpdateStatus && vendorUpdateStatus.success === true) {
      msg = vendorUpdateStatus.message;
    }
    if (msg !== "") {
      toast(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
      setPageType("list");
    }
  }, [createVendorSuccess, dispatch, vendorUpdateStatus]);

  useEffect(() => {
    let msg = "";
    if (
      createVendorError &&
      createVendorError.data &&
      createVendorError.data.success === false
    ) {
      msg = createVendorError.data.message;
    }
    if (
      vendorUpdateErrorStatus &&
      vendorUpdateErrorStatus.data &&
      vendorUpdateErrorStatus.data.success === false
    ) {
      msg = vendorUpdateErrorStatus.data.message;
    }
    if (msg !== "") {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [createVendorError, dispatch, vendorUpdateErrorStatus]);

  const renderPages = () => {
    switch (pageType) {
      case "list":
        return <VendorList setPageType={setPageType} />;
        case "add":
          return <VendorAdd setPageType={setPageType} />;
        case "edit":
          return <VendorAdd setPageType={setPageType} />;
      default:
        return null;
    }
  };
  return (
    <React.Fragment>
      <div className="page">{renderPages()}</div>
      <ToastContainer />
    </React.Fragment>
  );
};
export default VendorIndex;
