// import axios from "axios";
// const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
import axiosInstance from "../ApiHelper";
import axios from "axios";
const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

class orderService {
  constructor(callback) {
    this.callback = callback;
  }

  async listOrder(payload) {
    let search = payload.date;

    const response = await axiosInstance
      .post(`order/list`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async createOrder(payload) {
    const response = await axiosInstance
      .post(`vendor/order/create`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async updateOrder(payload) {
    const response = await axiosInstance
      .post(`vendor/order/update`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async updateOrderStatus(payload) {
    const response = await axiosInstance
      .post(`update/status`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async updateOrderDriver(payload) {
    const response = await axiosInstance
      .post(`assign/driver`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async deleteOrder(payload) {
    const response = await axiosInstance
      .delete(`order/${payload}`)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async dashboardListOrder() {
    const response = await axiosInstance
      .get(`dashboard`)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async updateCustomerLocation(payload) {
    const response = await axios
      .post(`${BASE_URL}customer/order/updatelocation`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async checkIfCustLocUpdated(payload) {
    const response = await axios
      .post(`${BASE_URL}customer/order/checkif_location_updated`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }
}

export default new orderService();
