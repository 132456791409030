import React, { useEffect, Fragment, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import filterImg from "../../assets/images/filter-icon.png";
import deleteImg from "../../assets/images/Delete.svg";
import editImg from "../../assets/images/Edit.svg";
import cancelImg from "../../assets/images/cancel.svg";
import searchImg from "../../assets/images/search.svg";
import tickImg from "../../assets/images/tick.svg";
import {
  listVendor,
  deleteVendor,
  updateVendor,
  resetPackageAmount,
  resetFunction,
} from "../../store/vendor/vendorState";
//Toastify
import { toast } from "react-toastify";
import { DispatchContext } from "../../context/AppProvider";
import { Button, Col, Row } from "antd";
import { Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';

const VendorList = ({ setPageType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch(),
    contextDispatch = useContext(DispatchContext);
  const vendorList = useSelector((state) => state.Vendor.vendorList);
  const vendorError = useSelector((state) => state.Vendor.vendorError);
  const login = useSelector((state) => state.Login.logData);
  const deleteVendorSuccess = useSelector(
    (state) => state.Vendor.deleteVendorSuccess
  );
  const deleteVendorError = useSelector(
    (state) => state.Vendor.deleteVendorError
  );

  const resetPackageAmountSuccess = useSelector(
    (state) => state.Vendor.resetPackageAmountSuccess
  );
  const resetPackageAmountError = useSelector(
    (state) => state.Vendor.resetPackageAmountError
  );

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;

  let currentItems = [];
  let pageCount = 0;
  let slCount = 0;
  if (
    vendorList &&
    vendorList.data !== undefined &&
    Object.keys(vendorList.data).length > 0
  ) {
    currentItems = vendorList.data.slice(itemOffset, endOffset);
    pageCount = Math.ceil(vendorList.data.length / itemsPerPage);
  }

  if (Object.keys(currentItems).length > 0) {
    const index = vendorList.data.findIndex((itm) => {
      return itm._id === currentItems[0]._id;
    });
    slCount = index;
  }

  useEffect(() => {
    if (deleteVendorSuccess && deleteVendorSuccess.success === true) {
      toast(deleteVendorSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteVendorSuccess, dispatch]);

  useEffect(() => {
    if (
      deleteVendorError &&
      deleteVendorError.data &&
      deleteVendorError.data.success === false
    ) {
      toast.error(deleteVendorError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [deleteVendorError, dispatch]);

  useEffect(() => {
    dispatch(listVendor());
  }, [deleteVendorSuccess, dispatch]);

  useEffect(() => {
    if (vendorError && vendorError.data && vendorError.data.success === false) {
      toast.error(vendorError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [dispatch, vendorError]);

  useEffect(() => {
    if (resetPackageAmountSuccess && resetPackageAmountSuccess.success === true) {
      toast(resetPackageAmountSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [resetPackageAmountSuccess, dispatch]);

  useEffect(() => {
    if (
      resetPackageAmountError &&
      resetPackageAmountError.data &&
      resetPackageAmountError.data.success === false
    ) {
      toast.error(resetPackageAmountError.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [resetPackageAmountError, dispatch]);

  const handleSearchOnChange = (search) => {
    if (search !== "") {
      if (search.length >= 0) {
        let payload = { keyword: search };
        dispatch(listVendor(payload));
      }
    } else {
      dispatch(listVendor({ keyword: "" }));
    }
  };

  const deleteVendorAction = (id) => {
    dispatch(deleteVendor(id));
  };

  const handleStatusChange = (id, status) => {
    let pLoad = { status: status };
    let payload = { vendorId: id, data: pLoad };
    dispatch(updateVendor(payload));
  };

  const handlePageClick = (event) => {
    let newOffset = 0;
    if (
      vendorList &&
      vendorList.data !== undefined &&
      Object.keys(vendorList.data).length > 0
    ) {
      newOffset = (event.selected * itemsPerPage) % vendorList.data.length;
    }
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const resetPackageAmountCollected = (id) => {
    console.log("id",id)
    let payload = { id: id };
    dispatch(resetPackageAmount(payload));
  };

  let tblCount = 0;

  return (
    <Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          <Row className="common-head">
            <p className="head-p">{t('All Vendors')}</p>
            <Button
              onClick={() => {
                contextDispatch({
                  type: "vendor_edit",
                  payload: {},
                });
                setPageType("add");
              }}
              type="button"
              className="top-btn btn form-btn bgcolor1 ripple1"
            >
              {t('ADD NEW VENDOR')}
            </Button>
          </Row>
          <Row className="common-filter">
            <Col span={24}>
              <Row className="cntnt-top">
                <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                  <div className="filter-by">
                    <img src={filterImg} alt="" />
                    <p>{t('Filter by')}</p>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className="filter-coulmn big-srch">
                    <img alt="" className="srch-icn" src={searchImg} />
                    <input
                      className="filter-input"
                      type="text"
                      placeholder={t('Search')}
                      onChange={(e) => handleSearchOnChange(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Table borderless>
              <thead>
                <tr>
                  <th>{t('Sl No')}</th>
                  <th>{t('Name')}</th>
                  <th>{t('Email')}</th>
                  <th>{t('Phone')}</th>
                  <th>{t('Wallet Amount')}</th>
                  <th>{t('Package Amount')}</th>
                  <th>{t('Address')}</th>
                  {/* <th>{t('Map Link')}</th> */}
                  <th className="action-head">{t('Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {currentItems ? (
                  currentItems.map((sec, i) => {
                    slCount += 1;
                    return (
                      <tr key={i}>
                        <td className="td-white">{slCount}</td>
                        <td className="c-table__cell td-white">{sec.name}</td>
                        <td className="c-table__cell td-white">{sec.email.length > 15 ?sec.email.substring(0, 15)+"..":sec.email}</td>
                        <td className="c-table__cell td-white">{sec.phone}</td>
                        <td className="c-table__cell td-white">{sec.wallet}</td>
                        <td className="c-table__cell td-white">{sec.packageAmountCollected}
                        {sec.packageAmountCollected > 0 && login.vendor && login.vendor.is_admin === true ? (
                              <Button
                                onClick={() => {
                                  if (window.confirm("Reset amount to 0 ?")) {
                                    resetPackageAmountCollected(sec._id);
                                  }
                                }}
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  marginLeft:"10px",
                                }}
                                type="button"
                                className="top-btn btn form-btn bgcolor1 ripple1"
                              >
                                {t('Reset')}
                              </Button>
                              ):""}</td>
                        <td className="c-table__cell td-white">
                          {/* Location : {sec.address && sec.address.locationName}
                          <br />
                          Building Num :{" "}
                          {sec.address && sec.address.buildingNumber}
                          <br />
                          Street Num : {sec.address && sec.address.streetNumber}
                          <br />
                          Municipality:{" "}
                          {sec.address && sec.address.municipality}
                          <br />
                          Zone Num: {sec.address && sec.address.zoneNumber} */}
                          {sec.address &&
                            sec.address.locationName + ","}
                          {sec.address && sec.address.buildingNumber}
                          <br />
                          {sec.address &&
                            sec.address.streetNumber + ","}
                          {sec.address && sec.address.zoneNumber}
                        </td>
                        {/* <td className="c-table__cell td-white">
                          <p className="link-txt-stl">
                            {sec.address && sec.address.mapLink}
                          </p>
                        </td> */}
                        <td className="c-table__cell td-white">
                          <div className="actions">
                            {/* <span className="blue-eye material-icons">
                            visibility
                          </span> */}
                            <span className="black-edit material-icons">
                              <img
                                src={editImg}
                                alt=""
                                onClick={() => {
                                  contextDispatch({
                                    type: "vendor_edit",
                                    payload: sec,
                                  });
                                  setPageType("edit");
                                }}
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                            <span className="black-edit material-icons">
                              {sec.status === true ? (
                                <img
                                  src={cancelImg}
                                  alt=""
                                  onClick={() => {
                                    handleStatusChange(sec._id, false);
                                  }}
                                  title="{t('DeActivate')}"
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                />
                              ) : (
                                <img
                                  src={tickImg}
                                  alt=""
                                  onClick={() => {
                                    handleStatusChange(sec._id, true);
                                  }}
                                  title="Activate"
                                  style={{
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </span>
                            <span className="black-edit material-icons">
                              <img
                                alt=""
                                src={deleteImg}
                                onClick={() => {
                                  if (window.confirm("Delete the item?")) {
                                    deleteVendorAction(sec._id);
                                  }
                                }}
                                style={{ color: "red", cursor: "pointer" }}
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>{t("No Records Found")}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
          <Row>
            <div className="pagination-div">
              <ReactPaginate
                activeClassName={"item active "}
                breakClassName={"item break-me "}
                breakLabel={"..."}
                containerClassName={"pagination"}
                disabledClassName={"disabled-page"}
                marginPagesDisplayed={2}
                nextClassName={"item next "}
                nextLabel={">>"}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                pageClassName={"item pagination-page "}
                pageRangeDisplayed={5}
                previousClassName={"item previous"}
                previousLabel={"<<"}
                renderOnZeroPageCount={null}
              />
            </div>
          </Row>
        </Row>
      </div>
    </Fragment>
  );
};

export default VendorList;
