import { createSlice } from "@reduxjs/toolkit";

export const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    rechargeWallet: {},
    rechargeWalletError: {},
    walletHistory: {},
    walletHistoryError: {},
  },
  reducers: {
    walletRechargeOnline: (state, payload) => {
      state.rechargeWallet = {};
      state.rechargeWalletError = {};
    },
    walletRechargeOnlineSuccess: (state, action) => {
      state.rechargeWallet = action.payload;
      state.rechargeWalletError = {};
    },
    walletRechargeOnlineError: (state, action) => {
      state.rechargeWalletError = action.payload;
      state.rechargeWallet = {};
    },
    walletStatementHistory: (state, action) => {
      state.walletHistory = {};
      state.walletHistoryError = {};
    },
    walletStatementHistorySuccess: (state, action) => {
      state.walletHistory = action.payload;
      state.walletHistoryError = {};
    },
    walletStatementHistoryError: (state, action) => {
      state.walletHistory = {};
      state.walletHistoryError = action.payload;
    },
    resetWalletRechargeOnline: (state, action) => {
      state.rechargeWalletError = {};
      state.rechargeWallet = {};
      state.walletHistory = {};
      state.walletHistoryError = {};
    },
  },
});

export const {
  walletRechargeOnline,
  walletRechargeOnlineSuccess,
  walletRechargeOnlineError,
  resetWalletRechargeOnline,
  walletStatementHistory,
  walletStatementHistorySuccess,
  walletStatementHistoryError,
} = walletSlice.actions;
export default walletSlice.reducer;
