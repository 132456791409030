import React, { useState, useEffect, useContext } from "react";
//React Hook Form cleanly grabs the input values and returns them in a handleSubmit function.
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Redux
import {
  createCoupon,
  resetFunction,
  updateCoupon,
} from "../../store/coupon/couponState";
import { listVendor } from "../../store/vendor/vendorState";
import { useDispatch, useSelector } from "react-redux";
import { StateContext } from "../../context/AppProvider";
import Select from "react-select";
import DatePicker from "react-datepicker";
import calendarImg from "../../assets/images/VectorCalendar.svg"; // Reactstrap tabs
import { Button, Card, Col, Row } from "antd";
import { useTranslation } from 'react-i18next';

const CouponAdd = ({ pageType, setPageType }) => {
  const { t } = useTranslation();
  const vendorList = useSelector((state) => state.Vendor.vendorList);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });
  const dispatch = useDispatch();
  const contData = useContext(StateContext);
  const editData = contData.couponEditData;

  const [selectVendorOptions, setSelectVendorOptions] = useState([]);
  const [discountType, setDiscountType] = useState("amount");
  const [vendorSelected, setVendorSelected] = useState({
    label: t("Select Vendor"),
    value: "",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [couponGen, setCouponGen] = useState("");

  const [errorValidation, setErrorValidation] = useState({
    vendor: 0,
    startDate: 0,
    endDate: 0,
    coupon: 0,
  });

  useEffect(() => {
    dispatch(listVendor());
  }, [dispatch]);

  const handleDiscountType = (event) => {
    setDiscountType(event.target.value);
  };

  const generateCouponCode = () => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 9) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    setCouponGen(result);
  };

  const submitForm = (data) => {
    let valid = 0;
    let validStartDate = 0;
    let validEndDate = 0;
    let validVendor = 0;
    let validCoupon = 0;

    if (startDate === "") {
      valid = 1;
      validStartDate = 1;
    }

    if (endDate === "") {
      valid = 1;
      validEndDate = 1;
    }

    if (couponGen === "") {
      valid = 1;
      validCoupon = 1;
    }

    if (vendorSelected.value === "") {
      valid = 1;
      validVendor = 1;
    }
    setErrorValidation({
      ...errorValidation,
      startDate: validStartDate,
      endDate: validEndDate,
      vendor: validVendor,
      coupon: validCoupon,
    });
    if (valid === 0) {
      data.vendor = vendorSelected.value;
      data.startDate = startDate;
      data.endDate = endDate;
      data.discountType = discountType;
      data.couponCode = couponGen;
      if (Object.keys(editData).length === 0) {
        dispatch(createCoupon(data));
      } else {
        let payload = { couponId: editData._id, data: data };
        dispatch(updateCoupon(payload));
      }
    }
  };
  const coupon = useSelector((state) => state.Coupon.createCouponStatus);
  const error = useSelector((state) => state.Coupon.createCouponStatusError);

  useEffect(() => {
    if (coupon && coupon.success === true) {
      toast(coupon.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
      //setPageType("list");
    }
  }, [coupon]);

  useEffect(() => {
    if (error && error.data && error.data.success === false) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [error]);

  useEffect(() => {
    if (vendorList && vendorList && vendorList.success === true) {
      let data = vendorList.data;
      const vendorOptions = data.map((item) => ({
        label: item.name + "(" + item.phone + ")", // <-- input values you are matching
        value: item._id,
      }));
      setSelectVendorOptions(vendorOptions);

      if (Object.keys(editData).length > 0) {
        if (editData.vendor) {
          let v = {
            label: editData.vendor.name + "(" + editData.vendor.phone + ")", // <-- input values you are matching
            value: editData.vendor._id,
          };
          setVendorSelected(v);
        }
      }
    }
  }, [dispatch, vendorList]);

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      if (editData.vendor) {
        let v = {
          label: editData.vendor.name + "(" + editData.vendor.phone + ")", // <-- input values you are matching
          value: editData.vendor._id,
        };
        setVendorSelected(v);
      }
      if (editData.startDate) setStartDate(new Date(editData.startDate));
      if (editData.endDate) setEndDate(new Date(editData.endDate));
      if (editData.discountType) setDiscountType(editData.discountType);
      if (editData.couponCode) setCouponGen(editData.couponCode);
    }
  }, [editData]);

  const handleVendorChange = (val) => {
    setVendorSelected(val);
  };

  return (
    <React.Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          <Row className="common-head">
            <p className="head-p">
              {Object.keys(editData).length > 0 ? "Edit" : "Add"} Discount
            </p>
            <Button
              onClick={() => setPageType("list")}
              type="button"
              className="top-btn btn form-btn bgcolor1 ripple1"
            >
              {t('LIST DISCOUNTS')}
            </Button>
          </Row>

          <Row className="com-cntnt">
            <form onSubmit={handleSubmit(submitForm)}>
              <Card className="form-card" bordered={false}>
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Vendor')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <Select
                            value={vendorSelected}
                            onChange={(selected) =>
                              handleVendorChange(selected)
                            }
                            options={selectVendorOptions}
                            classNamePrefix="select2-selection"
                          />

                          {errorValidation.vendor === 1 && (
                            <p className="val-error">Please select vendor.</p>
                          )}
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Title')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="text"
                            placeholder={t("Title")}
                            defaultValue={
                              Object.keys(editData).length > 0
                                ? editData.title
                                : ""
                            }
                            {...register("title", {
                              required: "Please enter title.",
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="row new-field">
                      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <p className="label-text">{t('Discount Code')}</p>
                      </Col>
                      <Col xs={24} sm={12} md={16} lg={16} xl={16}>
                        <Row>
                          <Col xs={24} sm={12} md={12} lg={24} xl={24}>
                            <div className="field-coulmn  mr-5 mb-2">
                              <input
                                className="field-input"
                                type="text"
                                placeholder={t("Discount Code")}
                                value={couponGen}
                                onChange={(e) => setCouponGen(e.target.value)}
                              />

                              {errorValidation.coupon === 1 && (
                                <p className="val-error">
                                  Please enter Discount code.
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={24} xl={24}>
                            <Button
                              className="flex outline-btn"
                              color="info"
                              onClick={generateCouponCode}
                            >
                              <p>{t("Generate Code")}</p>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Discount Value')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="number"
                            step="0.01"
                            placeholder={t("Discount Value")}
                            defaultValue={
                              Object.keys(editData).length
                                ? editData.discountValue
                                : ""
                            }
                            {...register("discountValue", {
                              required: "Please enter discount value.",
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="discountValue"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Discount Type')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="vehicle-driver">
                          <div className="radio">
                            <input
                              type="radio"
                              value="amount"
                              checked={discountType === "amount" ? true : false}
                              onChange={handleDiscountType}
                            />
                            <label forhtml="html" className="field-icn">
                              {t('Amount')}
                            </label>
                          </div>
                        </div>
                        <div className="vehicle-driver">
                          <div className="radio">
                            <input
                              type="radio"
                              value="percentage"
                              checked={
                                discountType === "percentage" ? true : false
                              }
                              onChange={handleDiscountType}
                            />
                            <label forhtml="html" className="field-icn">
                              {t('Percentage')}
                            </label>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">
                          {t('Total no. of times to be used')}
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <input
                            className="field-input"
                            type="number"
                            placeholder={t("Total Count")}
                            defaultValue={
                              Object.keys(editData).length
                                ? editData.totalCount
                                : ""
                            }
                            {...register("totalCount", {
                              required: "Please enter totalCount.",
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="totalCount"
                            render={({ message }) => (
                              <p className="val-error">{message}</p>
                            )}
                          />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('Start Date')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <div className="filter-coulmn sizer-1">
                            <img
                              alt=""
                              className="black-clendar"
                              src={calendarImg}
                            />
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => {
                                setStartDate(date);
                              }}
                              placeholderText={t("Select Date")}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                          {errorValidation.startDate === 1 && (
                            <p className="val-error">
                              Please enter start date.
                            </p>
                          )}
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className="row new-field">
                      <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                        <p className="label-text">{t('End Date')}</p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                        <div className="field-coulmn">
                          <div className="filter-coulmn sizer-1">
                            <img
                              alt=""
                              className="black-clendar"
                              src={calendarImg}
                            />
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => {
                                setEndDate(date);
                              }}
                              placeholderText={t("Select Date")}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                          {errorValidation.endDate === 1 && (
                            <p className="val-error">Please enter end date.</p>
                          )}
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>

                <div className="row btn-float-right">
                  <div className=" btn-field">
                    <button
                      type="submit"
                      className="btn form-btn bgcolor1 ripple1 mr-5"
                    >
                      {t("Save")}
                    </button>
                    <button
                      onClick={() => setPageType("list")}
                      type="button"
                      className="btn  form-btn bgcolor3 ripple3"
                    >
                      {t('Cancel')}
                    </button>
                  </div>
                </div>
              </Card>
            </form>
          </Row>
        </Row>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default CouponAdd;
