import React, { useEffect, useState } from "react";
import MapContainer from "./GoogleMapContainer";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import ErrorPage from "./ErrorPage";
import SuccessPage from "./SuccessPage";
import { useSelector } from "react-redux";
import {
  resetFunction,
  checkIfCustLocUpdated,
} from "../../store/order/orderState";
import { useDispatch } from "react-redux";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MapContainerIndex = () => {
  const currentUrl = useLocation();
  const dispatch = useDispatch();
  const [defaultLocation, setDefaultLocation] = useState({ lat: 0, lng: 0 });
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [orderId, setOrderId] = useState("0");
  const [checkAlreadyUpdated, setCheckAlreadyUpdated] = useState(0);
  const locationSuccess = useSelector((state) => state.Order.customerLocation);
  const checkCustLoc = useSelector((state) => state.Order.checkCustLocUpdated);

  useEffect(() => {
    dispatch(resetFunction());
    navigator.geolocation.getCurrentPosition(
      (position) => {
        currentLocationSuccess(position);
      },
      (error) => {
        currentLocationError();
        console.log("Geolocation not supported");
      }
    );

    if (currentUrl.pathname.includes("/") === true) {
      let encryptedText = currentUrl.pathname.split("/");
      const key = "qlzhcguzfksdmlygflhjujwcsuviwjlf";
      var reb64 = CryptoJS.enc.Hex.parse(encryptedText[1].toString());
      var bytes = reb64.toString(CryptoJS.enc.Base64);
      var decrypt = CryptoJS.AES.decrypt(bytes, key);
      var uurl = decrypt.toString(CryptoJS.enc.Utf8);
      let id = uurl;
      if (uurl && uurl.includes('"') === true) {
        let orgUrl = uurl.split('"');
        id = orgUrl[1];
      } else if (uurl && uurl.includes("'") === true) {
        let orgUrl = uurl.split("'");
        id = orgUrl[1];
      }
      setOrderId(id);
    }
  }, [dispatch,currentUrl]);

  useEffect(() => {
    if (locationSuccess && locationSuccess.success === true) {
      if (orderId != "" && orderId != "0") {
        dispatch(checkIfCustLocUpdated({ order: orderId }));
      }
    } else if (locationSuccess && locationSuccess.success === false) {
      toast.error(locationSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [locationSuccess]);

  useEffect(() => {
    if (checkCustLoc && Object.keys(checkCustLoc).length > 0) {
      if (checkCustLoc.locationByCustomer === true) {
        setCheckAlreadyUpdated(1);
      }
    } else {
      setCheckAlreadyUpdated(0);
    }
  }, [checkCustLoc]);

  function currentLocationSuccess(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log(latitude,longitude);
    setCurrentLocation({ lat: latitude, lng: longitude });
    setDefaultLocation({ lat: latitude, lng: longitude });
  }

  function currentLocationError() {
    setCurrentLocation({ lat: 25.286106, lng: 51.534817 });
    setDefaultLocation({ lat: 25.286106, lng: 51.534817 });
  }
  return (
    <>
      {orderId !== "" && orderId !== "0" ? (
        <>
          {checkAlreadyUpdated === 0 ? (
            <>
              {defaultLocation.lat !== 0 && defaultLocation.lng !== 0 && (
                <MapContainer
                  DefaultLoc={currentLocation}
                  defaultLocation={defaultLocation}
                  setDefaultLocation={setDefaultLocation}
                  orderId={orderId}
                />
              )}
            </>
          ) : (
            <SuccessPage />
          )}
        </>
      ) : (
        <ErrorPage />
      )}
      <ToastContainer />
    </>
  );
};

export default MapContainerIndex;
