import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
//For Redux
import { Provider } from "react-redux";
//Load external files
import App from "./App";
// import store from "./store";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../src/assets/css/print.css";
import "./index.css";
import "./assets/css/style.scss";
import "./assets/css/custom-style.css";
import { AppProvider } from "./context/AppProvider";
import moment from "moment-timezone";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

// Import translations
import enTranslation from "./assets/lang/en.json";
import arTranslation from "./assets/lang/ar.json";

// Initialize i18next
i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en", // Default language fallback
    resources: {
      en: { translation: enTranslation },
      ar: { translation: arTranslation },
      // Add more translations for other languages if needed
    },
  });

//Set default Timezone
moment.tz.setDefault("Asia/Qatar");

ReactDOM.createRoot(document.getElementById("root")).render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <AppProvider>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={App} />
        </PersistGate>
      </AppProvider>
    </Provider>
  </I18nextProvider>
);
