import axios from "axios";
const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

class authService {
  constructor(callback) {
    this.callback = callback;
  }

  async loginUser(payload) {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios
      .post(`${BASE_URL}admin/login`, payload, {
        headers: headers,
      })
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }
}

export default new authService();
