import { createSlice } from "@reduxjs/toolkit";

export const vendorSlice = createSlice({
  name: "vendor",
  initialState: {
    vendorData: {},
    error: {},
    isLoading: false,
    vendorList: {},
    vendorError: {},
    profile: {},
    profileError: {},
    updateVendorStatus: {},
    updateVendorStatusError: {},
    deleteVendorError: {},
    deleteVendorSuccess: {},
    resetPackageAmountSuccess: {},
    resetPackageAmountError: {},
  },
  reducers: {
    createVendor: (state, payload) => {
      state.isLoading = true;
      state.vendorData = {};
      state.error = {};
    },
    createVendorSuccess: (state, action) => {
      state.isLoading = false;
      state.vendorData = action.payload;
      state.error = {};
    },
    createVendorError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.vendorData = {};
    },
    updateVendor: (state, action) => {
      state.updateVendorStatus = {};
      state.updateVendorStatusError = {};
    },
    updateVendorSuccess: (state, action) => {
      state.updateVendorStatus = action.payload;
      state.updateVendorStatusError = {};
    },
    updateVendorError: (state, action) => {
      state.updateVendorStatus = {};
      state.updateVendorStatusError = action.payload;
    },
    deleteVendor: (state, action) => {
      state.deleteVendorError = {};
      state.deleteVendorSuccess = {};
    },
    deleteVendorSuccess: (state, action) => {
      state.deleteVendorError = {};
      state.deleteVendorSuccess = action.payload;
    },
    deleteVendorError: (state, action) => {
      state.deleteVendorError = action.payload;
      state.deleteVendorSuccess = {};
    },
    listVendor: (state, payload) => {
      state.isLoading = true;
      state.vendorList = {};
    },
    listVendorSuccess: (state, action) => {
      state.isLoading = false;
      state.vendorList = action.payload;
      state.vendorError = {};
    },
    listVendorError: (state, action) => {
      state.isLoading = false;
      state.vendorError = action.payload;
      state.vendorList = {};
    },
    profileVendor: (state, payload) => {
      state.isLoading = true;
      state.profile = {};
    },
    profileVendorSuccess: (state, action) => {
      state.isLoading = false;
      state.profile = action.payload;
      state.profileError = {};
    },
    profileVendorError: (state, action) => {
      state.isLoading = false;
      state.profileError = action.payload;
      state.profile = {};
    },
    resetFunction: (state, action) => {
      state.vendorData = {};
      state.error = {};
      state.vendorError = {};
      state.updateVendorStatus = {};
      state.updateVendorStatusError = {};
      state.deleteVendorError = {};
      state.deleteVendorSuccess = {};
      state.changePasswordSuccess = {};
      state.changePasswordError = {};
      state.profileVendorSuccess = {};
      state.profileVendorError = {};
      state.resetPackageAmountSuccess = {};
      state.resetPackageAmountError = {};
    },
    changePassword: (state, payload) => {
      state.isLoading = true;
      state.changePasswordSuccess = {};
    },
    changePasswordSuccess: (state, action) => {
      state.isLoading = false;
      state.changePasswordSuccess = action.payload;
      state.changePasswordError = {};
    },
    changePasswordError: (state, action) => {
      state.isLoading = false;
      state.changePasswordSuccess = {};
      state.changePasswordError = action.payload;
    },
    resetPackageAmount: (state, payload) => {
      console.log("payload", payload);
      state.isLoading = true;
      state.resetPackageAmountSuccess = {};
    },
    resetPackageAmountSuccess: (state, action) => {
      state.isLoading = false;
      state.resetPackageAmountSuccess = action.payload;
      state.resetPackageAmountError = {};
    },
    resetPackageAmountError: (state, action) => {
      state.isLoading = false;
      state.resetPackageAmountSuccess = {};
      state.resetPackageAmountError = action.payload;
    },
  },
});

export const {
  createVendor,
  createVendorSuccess,
  createVendorError,
  updateVendor,
  updateVendorSuccess,
  updateVendorError,
  deleteVendor,
  deleteVendorSuccess,
  deleteVendorError,
  listVendor,
  listVendorSuccess,
  listVendorError,
  profileVendor,
  profileVendorSuccess,
  profileVendorError,
  resetFunction,
  changePassword,
  changePasswordSuccess,
  changePasswordError,
  resetPackageAmount,
  resetPackageAmountError,
  resetPackageAmountSuccess,
} = vendorSlice.actions;
export default vendorSlice.reducer;
