import React, { Fragment, useContext, useEffect, useState } from "react";
import logoImg from "../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { userLogout } from "../store/auth/login/loginState";
import { useDispatch, useSelector } from "react-redux";
import { DispatchContext, StateContext } from "../context/AppProvider";
import { Nav, NavItem, Navbar, NavbarBrand, Collapse } from "reactstrap";
import arrowImg from "../assets/images/drop-down-arrow.svg";
import { Drawer, Layout } from "antd";
import { useTranslation } from 'react-i18next';
const { Sider } = Layout;
const Sidebar = ({
  menuCollapse,
  setMenuCollapse,
  mediaSize,
  setMediaSize,
}) => {
  const dispatch = useDispatch();
  const contextDispatch = useContext(DispatchContext);
  let pathname = window.location.pathname;
  const [activeMenu, setActiveMenu] = useState(pathname);
  const login = useSelector((state) => state.Login.logData);
  const [subCollapsed, setSubCollapsed] = useState(true);
  const contData = useContext(StateContext);
  const isLoaded = contData.dashboardOrder;

  useEffect(() => {
    if (activeMenu === "/driver/order" || activeMenu === "/driver") {
      setSubCollapsed(false);
    }
    if (window.innerWidth <= 987) {
      setMediaSize("small");
    }
  }, []);

  useEffect(() => {
    setActiveMenu(pathname);
  }, [pathname]);

  useEffect(() => {
    if (isLoaded.isLoad === true) {
      setActiveMenu("/order");
    }
  }, [isLoaded]);
  const { t } = useTranslation();

  const toggleNavbar = () => setSubCollapsed(!subCollapsed);
  const renderMenu = () => {
    return (
      <>
        <div className="logo">
          <figure>
            <img src={logoImg} alt="" />
          </figure>
        </div>
        <div className="menu-sec">
          <ul className="menu-scroll-div">
            <li className={activeMenu === "/dashboard" ? "active" : ""}>
              <NavLink
                exact="true"
                to="/dashboard"
                state={{ pageFrom: "sidebar" }}
                onClick={() => {
                  setActiveMenu("/dashboard");
                  setSubCollapsed(true);
                  if (mediaSize === "small" && menuCollapse === false) {
                    setMenuCollapse(true);
                  }
                }}
              >
                <span className="menu-dicon src-dashboard"></span>
                <span className="menu-wicon src-dashboard-s"></span>
                {t('Dashboard')}
              </NavLink>
            </li>
            {login.vendor && login.vendor.is_admin === true && (
              <>
                <li className={activeMenu === "/vendor" ? "active" : ""}>
                  <NavLink
                    exact="true"
                    to="/vendor"
                    state={{ pageFrom: "sidebar" }}
                    onClick={() => {
                      setActiveMenu("/vendor");
                      setSubCollapsed(true);
                      if (mediaSize === "small" && menuCollapse === false) {
                        setMenuCollapse(true);
                      }
                    }}
                  >
                    <span className="menu-dicon src-vendor"></span>
                    <span className="menu-wicon src-vendor-s"></span>
                    {t('Vendor')}
                  </NavLink>
                </li>
                {/* <li className={activeMenu === "/driver" ? "active" : ""}>
                  <NavLink
                    exact="true"
                    to="/driver"
                    state={{ pageFrom: "sidebar" }}
                    onClick={() => {
                      setActiveMenu("/driver");
                    }}
                  >
                    <span className="menu-dicon src-driver"></span>
                    <span className="menu-wicon src-driver-s"></span>
                    {t('Drivers')}
                  </NavLink>
                </li> */}
                <li className="submenu-li">
                  <Navbar color="faded" light>
                    <NavbarBrand
                      href="#"
                      className="me-auto"
                      onClick={toggleNavbar}
                    >
                      <span className="menu-dicon src-driver"></span>
                      <span className="menu-wicon src-driver-s"></span>
                      {t('Driver Management')}
                      <img src={arrowImg} alt="" className="down-arrow" />
                    </NavbarBrand>
                    <Collapse isOpen={!subCollapsed} navbar>
                      <Nav navbar className="sidemenu-nav">
                        <NavItem
                          className={activeMenu === "/driver" ? "active" : ""}
                        >
                          <NavLink
                            exact="true"
                            to="/driver"
                            state={{ pageFrom: "sidebar" }}
                            onClick={() => {
                              setActiveMenu("/driver");
                              if (
                                mediaSize === "small" &&
                                menuCollapse === false
                              ) {
                                setMenuCollapse(true);
                              }
                            }}
                          >
                            <span className="menu-dicon src-sub-driver"></span>
                            <span className="menu-wicon src-sub-driver-s"></span>
                            {t('Drivers')}
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={
                            activeMenu === "/driver/order" ? "active" : ""
                          }
                        >
                          <NavLink
                            exact="true"
                            to="/driver/order"
                            state={{ pageFrom: "sidebar" }}
                            onClick={() => {
                              setActiveMenu("/driver/order");
                              if (
                                mediaSize === "small" &&
                                menuCollapse === false
                              ) {
                                setMenuCollapse(true);
                              }
                              contextDispatch({
                                type: "order_filter",
                                payload: {
                                  customerName: "",
                                  orderNumber: "",
                                  month: null,
                                  fromDate: null,
                                  toDate: null,
                                  customActiveTab: "",
                                  driver: "",
                                },
                              });
                            }}
                          >
                            <span className="menu-dicon src-sub-order"></span>
                            <span className="menu-wicon src-sub-order-s"></span>
                            {t('Orders')}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Collapse>
                  </Navbar>
                </li>
              </>
            )}
            <li className={activeMenu === "/order" ? "active" : ""}>
              <NavLink
                exact="true"
                to="/order"
                state={{ pageFrom: "sidebar" }}
                onClick={() => {
                  setActiveMenu("/order");
                  setSubCollapsed(true);
                  if (mediaSize === "small" && menuCollapse === false) {
                    setMenuCollapse(true);
                  }
                  contextDispatch({
                    type: "order_filter",
                    payload: {
                      customerName: "",
                      orderNumber: "",
                      month: null,
                      fromDate: null,
                      toDate: null,
                      customActiveTab: "",
                      driver: "",
                    },
                  });
                }}
              >
                <span className="menu-dicon src-order"></span>
                <span className="menu-wicon src-order-s"></span>
                {t('Order Management')}
              </NavLink>
            </li>
            <li className={activeMenu === "/customer" ? "active" : ""}>
              <NavLink
                exact="true"
                to="/customer"
                state={{ pageFrom: "sidebar" }}
                onClick={() => {
                  setActiveMenu("/customer");
                  setSubCollapsed(true);
                  if (mediaSize === "small" && menuCollapse === false) {
                    setMenuCollapse(true);
                  }
                }}
              >
                <span className="menu-dicon src-customer"></span>
                <span className="menu-wicon src-customer-s"></span>
                {t('Customer Management')}
              </NavLink>
            </li>
            {login.vendor && login.vendor.is_admin === true ? (
              <>
                <li className={activeMenu === "/coupon" ? "active" : ""}>
                  <NavLink
                    exact="true"
                    to="/coupon"
                    state={{ pageFrom: "sidebar" }}
                    onClick={() => {
                      setActiveMenu("/coupon");
                      setSubCollapsed(true);
                      if (mediaSize === "small" && menuCollapse === false) {
                        setMenuCollapse(true);
                      }
                    }}
                  >
                    <span className="menu-dicon src-coupon"></span>
                    <span className="menu-wicon src-coupon-s"></span>
                    {t('Discount Management')}
                  </NavLink>
                </li>
              </>
            ) : (
              ""
            )}
            <li className={activeMenu === "/wallet" ? "active" : ""}>
              <NavLink
                exact="true"
                to="/wallet"
                state={{ pageFrom: "sidebar" }}
                onClick={() => {
                  setActiveMenu("/wallet");
                  setSubCollapsed(true);
                  if (mediaSize === "small" && menuCollapse === false) {
                    setMenuCollapse(true);
                  }
                  contextDispatch({
                    type: "header_text",
                    payload: {
                      title: t('Wallet'),
                    },
                  });
                }}
              >
                <span className="menu-dicon src-wallet"></span>
                <span className="menu-wicon src-wallet-s"></span>
                {t('Wallet')}
              </NavLink>
            </li>
            <li className={activeMenu === "/settings" ? "active" : ""}>
              <NavLink
                exact="true"
                to="/settings"
                state={{ pageFrom: "sidebar" }}
                onClick={() => {
                  setActiveMenu("/settings");
                  setSubCollapsed(true);
                  if (mediaSize === "small" && menuCollapse === false) {
                    setMenuCollapse(true);
                  }
                }}
              >
                <span className="menu-dicon src-setting"></span>
                <span className="menu-wicon src-setting-s"></span>
                {t('Settings')}
              </NavLink>
            </li>
            <li className="last">
              <NavLink
                exact="true"
                to="/"
                onClick={() => dispatch(userLogout())}
              >
                <span className="menu-dicon  src-logout"></span>
                <span className="menu-wicon src-logout-s"></span>
                {t('Logout')}
              </NavLink>
            </li>
          </ul>
        </div>
      </>
    );
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      className={
        menuCollapse === true
          ? "ant-layout-sider-collapsed ant-layout-sider-zero-width collapse-menu"
          : "expand-menu"
      }
      // className={menuCollapse === true ? "ant-layout-sider-collapsed ant-layout-sider-zero-width collapse-menu" : "expand-menu"}
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        if (collapsed === true) {
          setMenuCollapse(true);
        } else {
          setMenuCollapse(false);
        }
      }}
    >
      {renderMenu()}
    </Sider>
  );
};
export default Sidebar;
