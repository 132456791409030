import React, { useContext, useEffect, useState } from "react";
import ViewPDF from "./ViewPDF";
//Toastify
import moment from "moment";
import { StateContext } from "../../context/AppProvider";
import packageImg from "../../assets/images/package.png";
import calendarImg from "../../assets/images/calendar-v.png";
import addressImg from "../../assets/images/address.png";
import clockImg from "../../assets/images/clock.png";
import deliveryImg from "../../assets/images/delivery.png";
import OrderTrackLine from "./OrderTrack";
import { Button, Card, Col, Row } from "antd";
import { useTranslation } from 'react-i18next';
const OrderView = ({ setPageType }) => {
  const { t } = useTranslation();
  const contData = useContext(StateContext);
  const orderEditData = contData.orderEditData;
  const [viewModal, setViewModal] = useState(false);
  const showModal = () => setViewModal(!viewModal);

  useEffect(() => {
    if (Object.keys(orderEditData).length === 0) {
      setPageType("list");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          <Row className="common-head">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <p className="head-p label-text">
                Order Details #{orderEditData.orderNumber}
              </p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="page-header">
                <Button
                  color="info"
                  className="flex outline-btn"
                  onClick={showModal}
                >
                  View As PDF
                </Button>
                <Button
                  onClick={() => setPageType("list")}
                  type="button"
                  className="top-btn btn form-btn bgcolor1 ripple1"
                >
                  {t('LIST ORDERS')}
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="com-cntnt">
            <Card className="form-card view-card" bordered={false}>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      className="collected-cash"
                    >
                      <h5 className="mr-5">Cash to be collected: </h5>
                      <h5>
                        QAR {orderEditData.driverAmountCollected}
                        {/* {orderEditData.packagePaymentType === "COD" &&
                        orderEditData.paymentType === "COD"
                          ? (
                              parseFloat(orderEditData.deliveryCharge) +
                              parseFloat(orderEditData.amountToBeCollected)
                            ).toFixed(2)
                          : orderEditData.packagePaymentType === "Online" &&
                            orderEditData.paymentType === "COD"
                          ? orderEditData.deliveryCharge
                          : orderEditData.packagePaymentType === "COD" &&
                            orderEditData.paymentType === "Online"
                          ? orderEditData.amountToBeCollected
                          : 0} */}
                      </h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                  <Card className="form-card" bordered={false}>
                    <div className="wt-bx-hd">
                      <img src={addressImg} alt="" />
                      <p>Customer details</p>
                    </div>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Col span={24}>
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p className="txt-hd">{t('Name')}</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p>
                                {orderEditData && orderEditData.customerName}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p className="txt-hd">Phone No</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p>
                                {orderEditData && orderEditData.customerPhone}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p className="txt-hd">Area</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p>
                                {orderEditData && orderEditData.locationName}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p className="txt-hd">{t('Address')}</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p>
                                {orderEditData.dropoffAddress &&
                                  orderEditData.dropoffAddress.buildingNumber}
                                <br/>
                                {orderEditData.dropoffAddress &&
                                  orderEditData.dropoffAddress.streetNumber}
                                <br/>
                                {orderEditData.dropoffAddress &&
                                  orderEditData.dropoffAddress.zoneNumber}
                                <br/>
                                {orderEditData.dropoffAddress &&
                                  orderEditData.dropoffAddress.locationName}
                                {/* ,
                                {orderEditData.dropoffAddress &&
                                  orderEditData.dropoffAddress.municipality} */}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <iframe
                          width="100%"
                          height="100%"
                          className="iframe-props"
                          src={`https://www.google.com/maps/embed/v1/place?q=${orderEditData.dropoffAddress.latitude},${orderEditData.dropoffAddress.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                        ></iframe>
                      </Col>
                    </Row>
                  </Card>
                  <Card className="form-card" bordered={false}>
                    <div className="wt-bx-hd">
                      <img src={addressImg} alt="" />
                      <p>Vendor details</p>
                    </div>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Col span={24}>
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p className="txt-hd">{t('Name')}</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p>
                                {orderEditData &&
                                  orderEditData.vendor &&
                                  orderEditData.vendor.name}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p className="txt-hd">Phone No</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p>
                                {orderEditData &&
                                  orderEditData.vendor &&
                                  orderEditData.vendor.phone}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p className="txt-hd">Area</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p>
                                {orderEditData.pickupAddress &&
                                  orderEditData.pickupAddress.locationName}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p className="txt-hd">{t('Address')}</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p>
                                {orderEditData.pickupAddress &&
                                  orderEditData.pickupAddress.buildingNumber}
                                <br/>
                                {orderEditData.pickupAddress &&
                                  orderEditData.pickupAddress.streetNumber}
                                <br/>
                                {orderEditData.pickupAddress &&
                                  orderEditData.pickupAddress.zoneNumber}
                               <br/>
                                {orderEditData.pickupAddress &&
                                  orderEditData.pickupAddress.locationName}
                                {/* ,
                                {orderEditData.pickupAddress &&
                                  orderEditData.pickupAddress.municipality} */}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <iframe
                          width="100%"
                          height="100%"
                          className="iframe-props"
                          src={`https://www.google.com/maps/embed/v1/place?q=${orderEditData.pickupAddress.latitude},${orderEditData.pickupAddress.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                        ></iframe>
                      </Col>
                    </Row>
                  </Card>
                  <Card className="form-card" bordered={false}>
                    <div className="wt-bx-hd">
                      <img src={packageImg} alt="" />
                      <p>Package details</p>
                    </div>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <p className="txt-hd"> Package Payment Type</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <p>{orderEditData.packagePaymentType}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <p className="txt-hd">Package Charge</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <p>QAR {orderEditData.amountToBeCollected}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <p className="txt-hd">
                                  {" "}
                                  Del.Charge Payment Type
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <p>{orderEditData.paymentType}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <p className="txt-hd">Delivery Charge</p>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <p>QAR {orderEditData.deliveryCharge}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <p className="txt-hd">Weight</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <p>{orderEditData.packageWeight} Kg</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <Card className="form-card" bordered={false}>
                    <div className="wt-bx-hd">
                      <img src={deliveryImg} alt="" />
                      <p>Delivery details</p>
                    </div>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="txt-fld">
                              <img
                                src={calendarImg}
                                className="txt-hd-icn"
                                alt=""
                              />
                              <p className="txt-hd-label">Created Date</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="txt-p">
                              {moment(orderEditData.createdAt).format(
                                "DD-MM-YYYY"
                              ) +
                                " | " +
                                moment(orderEditData.createdAt).format("LT")}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="txt-fld">
                              <img
                                src={calendarImg}
                                className="txt-hd-icn"
                                alt=""
                              />
                              <p className="txt-hd-label">{t('Delivery Date')}</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="txt-p">
                              {moment(orderEditData.deliveryDate).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="txt-fld">
                              <img
                                src={clockImg}
                                className="txt-hd-icn"
                                alt=""
                              />
                              <p className="txt-hd-label">{t('Delivery Status')}</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="txt-p">
                              {orderEditData.status === "outForDelivery"
                                ? "OUT FOR DELIVERY"
                                : orderEditData.status.toUpperCase()}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="txt-fld">
                              <span className="txt-hd-icn material-icons">
                                local_shipping
                              </span>
                              <p className="txt-hd-label">Pickup Vehicle</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="txt-p">
                              {orderEditData.pickUpVehicle}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="txt-fld">
                              <span className="txt-hd-icn material-icons">
                                room_service
                              </span>
                              <p className="txt-hd-label">Delivery Type</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="txt-p">
                              {orderEditData.deliveryType}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="txt-fld">
                              <span className="txt-hd-icn material-icons">
                                credit_card
                              </span>
                              <p className="txt-hd-label">{t('Payment Type')}</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="txt-p">{orderEditData.paymentType}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="txt-fld">
                              <span className="txt-hd-icn material-icons">
                                person
                              </span>
                              <p className="txt-hd-label">{t('Driver Name')}</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="txt-p">
                              {orderEditData &&
                                orderEditData.driver &&
                                orderEditData.driver.name}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="txt-fld">
                              <span className="txt-hd-icn material-icons">
                                call
                              </span>
                              <p className="txt-hd-label">Driver Phone</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="txt-p">
                              {orderEditData &&
                                orderEditData.driver &&
                                orderEditData.driver.phone}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="txt-fld">
                              <span className="txt-hd-icn material-icons">
                                bubble_chart
                              </span>
                              <p className="txt-hd-label">Additional info</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className="txt-p">{orderEditData.addInfo}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                  <Card className="form-card" bordered={false}>
                    <div className="wt-bx-hd">
                      <p># Order Tracking</p>
                    </div>
                    <Row>
                      <Col span={24}>
                        <OrderTrackLine
                          transitDetails={
                            orderEditData && orderEditData.transitDetails
                          }
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Row>
        </Row>
      </div>
      <ViewPDF
        viewModal={viewModal}
        setViewModal={setViewModal}
        orderEditData={orderEditData}
      />
    </React.Fragment>
  );
};

export default OrderView;
