import React, { useEffect, useState, useContext } from "react";
//React Hook Form cleanly grabs the input values and returns them in a handleSubmit function.
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import calendarImg from "../../assets/images/VectorCalendar.svg";
import DatePicker from "react-datepicker";
// Redux
import { changePassword, resetFunction } from "../../store/vendor/vendorState";
import {
  fetchSettingsData,
  updatePickupTime,
  resetPickupUpdate,
  updateWeightLimit,
} from "../../store/common/commonState";
import { Button, Card, Col, Row } from "antd";
import { DispatchContext } from "../../context/AppProvider";
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  });

  const dispatch = useDispatch();
  const [pickupTime, setPickupTime] = useState("");
  const [weightLimit, setWeightLimit] = useState({
    bikeFrom: "",
    bikeTo: "",
    carFrom: "",
    carTo: "",
    truckFrom: "",
    truckTo: "",
  });
  const [errorWeightLimit, setErrorWeightLimit] = useState({
    bikeFrom: { msg: "", val: 0 },
    bikeTo: 0,
    carFrom: 0,
    carTo: 0,
    truckFrom: 0,
    truckTo: 0,
  });

  const login = useSelector((state) => state.Login.logData);
  const success = useSelector((state) => state.Vendor.changePasswordSuccess);
  const error = useSelector((state) => state.Vendor.changePasswordError);
  const settingsDt = useSelector((state) => state.Common.settingsData);
  const updatePickupTimeData = useSelector(
    (state) => state.Common.updatePickupTimeData
  );
  const updatePickupTimeDataError = useSelector(
    (state) => state.Common.updatePickupTimeDataError
  );

  const updateWeightLimitSuccess = useSelector(
    (state) => state.Common.weightLimitSuccess
  );
  const updateWeightLimitError = useSelector(
    (state) => state.Common.weightLimitError
  );

  const contextDispatch = useContext(DispatchContext);

  useEffect(() => {
    dispatch(fetchSettingsData());
    contextDispatch({
      type: "header_text",
      payload: {
        title: "Settings",
      },
    });
  }, []);

  useEffect(() => {
    if (settingsDt && settingsDt.data) {
      setPickupTime(settingsDt.data.normalPickUpTime);
      setWeightLimit({
        ...weightLimit,
        bikeFrom: settingsDt.data.weightLimit.bikeFrom,
        bikeTo: settingsDt.data.weightLimit.bikeTo,
        carFrom: settingsDt.data.weightLimit.carFrom,
        carTo: settingsDt.data.weightLimit.carTo,
        truckFrom: settingsDt.data.weightLimit.truckFrom,
        truckTo: settingsDt.data.weightLimit.truckTo,
      });
    }
  }, [settingsDt]);

  useEffect(() => {
    if (success && success.success === true) {
      toast(success.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [success]);

  useEffect(() => {
    if (updatePickupTimeData && updatePickupTimeData.success === true) {
      toast(updatePickupTimeData.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(fetchSettingsData());
      dispatch(resetPickupUpdate());
    }
  }, [updatePickupTimeData]);

  useEffect(() => {
    if (
      updatePickupTimeDataError &&
      updatePickupTimeDataError.success === true
    ) {
      toast.error(updatePickupTimeDataError.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetPickupUpdate());
    }
  }, [updatePickupTimeDataError]);

  useEffect(() => {
    if (updateWeightLimitSuccess && updateWeightLimitSuccess.success === true) {
      toast(updateWeightLimitSuccess.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(fetchSettingsData());
      dispatch(resetPickupUpdate());
    }
  }, [updateWeightLimitSuccess]);

  useEffect(() => {
    if (updateWeightLimitError && updateWeightLimitError.success === true) {
      toast.error(updateWeightLimitError.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetPickupUpdate());
    }
  }, [updateWeightLimitError]);

  useEffect(() => {
    if (error && error.data && error.data.success === false) {
      toast.error(error.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(resetFunction());
    }
  }, [error]);

  const savePickupTime = () => {
    dispatch(updatePickupTime({ pickupTime: pickupTime }));
  };

  const validatePassword = () => {
    const { nPassword, cPassword } = getValues();
    if (nPassword !== cPassword) {
      return false;
    }
    return true;
  };

  const submitForm = (data) => {
    dispatch(changePassword(data));
  };

  const handleWeightOnChange = (targetVal, errorVal, errorMsg, stateType) => {
    if (stateType === "bikeFrom") {
      setWeightLimit({
        ...weightLimit,
        bikeFrom: targetVal,
      });
      setErrorWeightLimit({
        ...errorWeightLimit,
        bikeFrom: { val: errorVal, msg: errorMsg },
      });
    } else if (stateType === "bikeTo") {
      setWeightLimit({
        ...weightLimit,
        bikeTo: targetVal,
      });
      setErrorWeightLimit({
        ...errorWeightLimit,
        bikeTo: { val: errorVal, msg: errorMsg },
      });
    } else if (stateType === "carFrom") {
      setWeightLimit({
        ...weightLimit,
        carFrom: targetVal,
      });
      setErrorWeightLimit({
        ...errorWeightLimit,
        carFrom: { val: errorVal, msg: errorMsg },
      });
    } else if (stateType === "carTo") {
      setWeightLimit({
        ...weightLimit,
        carTo: targetVal,
      });
      setErrorWeightLimit({
        ...errorWeightLimit,
        carTo: { val: errorVal, msg: errorMsg },
      });
    } else if (stateType === "truckFrom") {
      setWeightLimit({
        ...weightLimit,
        truckFrom: targetVal,
      });
      setErrorWeightLimit({
        ...errorWeightLimit,
        truckFrom: { val: errorVal, msg: errorMsg },
      });
    } else if (stateType === "truckTo") {
      setWeightLimit({
        ...weightLimit,
        truckTo: targetVal,
      });
      setErrorWeightLimit({
        ...errorWeightLimit,
        truckTo: { val: errorVal, msg: errorMsg },
      });
    }
  };

  const saveWeightLimit = () => {
    let valid = 0;
    if (weightLimit.bikeFrom === "") {
      valid = 1;
      setErrorWeightLimit({
        ...errorWeightLimit,
        bikeFrom: { val: 1, msg: "Please enter 'Bike From' weight" },
      });
    }
    if (weightLimit.bikeTo === "") {
      valid = 1;
      setErrorWeightLimit({
        ...errorWeightLimit,
        bikeTo: { val: 1, msg: "Please enter 'Bike To' weight" },
      });
    }
    if (weightLimit.carFrom === "") {
      valid = 1;
      setErrorWeightLimit({
        ...errorWeightLimit,
        carFrom: { val: 1, msg: "Please enter 'Car From' weight" },
      });
    }
    if (weightLimit.carTo === "") {
      valid = 1;
      setErrorWeightLimit({
        ...errorWeightLimit,
        carTo: { val: 1, msg: "Please enter 'Car To' weight" },
      });
    }
    if (weightLimit.truckFrom === "") {
      valid = 1;
      setErrorWeightLimit({
        ...errorWeightLimit,
        truckFrom: { val: 1, msg: "Please enter 'Truck From' weight" },
      });
    }
    if (weightLimit.truckTo === "") {
      valid = 1;
      setErrorWeightLimit({
        ...errorWeightLimit,
        truckTo: { val: 1, msg: "Please enter 'Truck To' weight" },
      });
    }
    if (valid === 0) {
      let dt = {
        bikeFrom: Number(weightLimit.bikeFrom),
        bikeTo: Number(weightLimit.bikeTo),
        carFrom: Number(weightLimit.carFrom),
        carTo: Number(weightLimit.carTo),
        truckFrom: Number(weightLimit.truckFrom),
        truckTo: Number(weightLimit.truckTo),
      };
      let payload = { weightLimit: dt };
      dispatch(updateWeightLimit(payload));
    }
  };

  return (
    <React.Fragment>
      <div className="hd-sec">
        <Row className="common-listpage">
          {/* <Row className="common-head">
            <p className="head-p">Settings</p>
          </Row> */}

          <Row className="com-cntnt">
            <form onSubmit={handleSubmit(submitForm)}>
              <Card className="form-card" bordered={false}>
                <Card
                  title={t('Change Password')}
                  className="form-card card-title"
                  bordered={false}
                >
                  <Row>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t('Current Password')}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="password"
                                placeholder={t('Current Password')}
                                {...register("password", {
                                  required: "Please enter current password.",
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="password"
                                render={({ message }) => (
                                  <p className="val-error">{message}</p>
                                )}
                              />
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t('New Password')}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="password"
                                placeholder={t('New Password')}
                                {...register("nPassword", {
                                  required: "Please enter new password.",
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="nPassword"
                                render={({ message }) => (
                                  <p className="val-error">{message}</p>
                                )}
                              />
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="row new-field">
                          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                            <p className="label-text">{t('Confirm Password')}</p>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
                            <div className="field-coulmn">
                              <input
                                className="field-input"
                                type="password"
                                placeholder={t('Confirm Password')}
                                {...register("cPassword", {
                                  required: "Please enter confirm password.",
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="cPassword"
                                render={({ message }) => (
                                  <p className="val-error">{message}</p>
                                )}
                              />{" "}
                              {!validatePassword() && (
                                <p className="val-error">
                                  Passwords do not match
                                </p>
                              )}
                            </div>
                          </Col>
                        </div>
                      </Col>
                    </Col>
                  </Row>
                  <div className="row new-field right">
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn top-btn bgcolor1 ripple1 padding-button"
                      >
                        {t("Save")}
                      </button>
                    </div>
                  </div>
                </Card>
                {login.vendor && login.vendor.is_admin === true && (
                  <>
                    <Card
                      title={t('Normal Pick-up time')}
                      className="form-card  card-title"
                      bordered={false}
                    >
                      <Row>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                          <div className="row new-field">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <p className="label-text">{t('Normal Pick-up time')}</p>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <div className="field-coulmn">
                                <input
                                  className="field-input"
                                  type="text"
                                  placeholder={t("Normal PickUp time")}
                                  required
                                  defaultValue={pickupTime}
                                  onChange={(e) => {
                                    setPickupTime(e.target.value);
                                  }}
                                />
                              </div>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      <div className="row new-field right">
                        <div className="col-12">
                          <button
                            onClick={() => savePickupTime()}
                            type="button"
                            className="btn top-btn bgcolor1 ripple1 padding-button"
                          >
                            {t("Save")}
                          </button>
                        </div>
                      </div>
                    </Card>
                    <Card
                      title={t('Weight Limit For Transport Type')}
                      className="form-card  card-title"
                      bordered={false}
                    >
                      <Row>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                          <div className="row new-field">
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                              <p className="label-text">{t("Weight Limit")}</p>
                            </Col>
                            <Col xs={24} sm={12} md={16} lg={16} xl={16}>
                              <div className="vehicle-driver">
                                <div className="radio">
                                  <label htmlFor="html">
                                    <span className="field-icn material-icons">
                                      moped
                                    </span>
                                  </label>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mr-25 mb-3">
                                    <div className="field-coulmn mr-2">
                                      <input
                                        className="field-input"
                                        name="twowheelerWeightFrom"
                                        type="number"
                                        value={weightLimit.bikeFrom}
                                        placeholder={t("Weight From")}
                                        onChange={(e) => {
                                          setWeightLimit({
                                            ...weightLimit,
                                            bikeFrom: e.target.value,
                                          });
                                        }}
                                        onBlur={(e) => {
                                          if (weightLimit.bikeTo !== "") {
                                            if (
                                              parseFloat(weightLimit.bikeTo) >
                                              e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "bikeFrom"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight less than 'To Weight'",
                                                "bikeFrom"
                                              );
                                            }
                                          } else {
                                            handleWeightOnChange(
                                              e.target.value,
                                              0,
                                              "",
                                              "bikeFrom"
                                            );
                                          }
                                        }}
                                      />
                                      {errorWeightLimit.bikeFrom.val === 1 && (
                                        <p className="val-error">
                                          {errorWeightLimit.bikeFrom.msg}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                                    <div className="field-coulmn">
                                      <input
                                        className="field-input"
                                        name="twowheelerWeightTo"
                                        type="number"
                                        value={weightLimit.bikeTo}
                                        placeholder={t("Weight To")}
                                        onChange={(e) => {
                                          setWeightLimit({
                                            ...weightLimit,
                                            bikeTo: e.target.value,
                                          });
                                        }}
                                        onBlur={(e) => {
                                          if (
                                            weightLimit.carFrom !== "" &&
                                            weightLimit.bikeFrom !== ""
                                          ) {
                                            if (
                                              parseFloat(weightLimit.carFrom) >
                                                e.target.value &&
                                              parseFloat(weightLimit.bikeFrom) <
                                                e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "bikeTo"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight between 'Bike From' & 'Car From'",
                                                "bikeTo"
                                              );
                                            }
                                          } else if (
                                            weightLimit.carFrom !== "" &&
                                            weightLimit.bikeFrom === ""
                                          ) {
                                            if (
                                              parseFloat(weightLimit.carFrom) >
                                              e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "bikeTo"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight less than 'Car From'",
                                                "bikeTo"
                                              );
                                            }
                                          } else if (
                                            weightLimit.carFrom === "" &&
                                            weightLimit.bikeFrom !== ""
                                          ) {
                                            if (
                                              parseFloat(weightLimit.bikeFrom) <
                                              e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "bikeTo"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight greater than 'Bike From'",
                                                "bikeTo"
                                              );
                                            }
                                          } else {
                                            handleWeightOnChange(
                                              e.target.value,
                                              0,
                                              "",
                                              "bikeTo"
                                            );
                                          }
                                        }}
                                      />
                                      {errorWeightLimit.bikeTo.val === 1 && (
                                        <p className="val-error">
                                          {errorWeightLimit.bikeTo.msg}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              </div>
                              <div className="vehicle-driver">
                                <div className="radio">
                                  <label htmlFor="html">
                                    <span className="field-icn material-icons">
                                      directions_car
                                    </span>
                                  </label>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mr-25 mb-3">
                                    <div className="field-coulmn mr-2">
                                      <input
                                        className="field-input"
                                        type="number"
                                        name="carWeightFrom"
                                        value={weightLimit.carFrom}
                                        placeholder={t("Weight From")}
                                        onChange={(e) => {
                                          setWeightLimit({
                                            ...weightLimit,
                                            carFrom: e.target.value,
                                          });
                                        }}
                                        onBlur={(e) => {
                                          if (
                                            weightLimit.bikeTo !== "" &&
                                            weightLimit.carTo !== ""
                                          ) {
                                            if (
                                              parseFloat(weightLimit.carTo) >
                                                e.target.value &&
                                              parseFloat(weightLimit.bikeTo) <
                                                e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "carFrom"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight between 'Bike To' & 'Car To'",
                                                "carFrom"
                                              );
                                            }
                                          } else if (
                                            weightLimit.carTo !== "" &&
                                            weightLimit.bikeTo === ""
                                          ) {
                                            if (
                                              parseFloat(weightLimit.carTo) >
                                              e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "carFrom"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight less than 'Car To'",
                                                "carFrom"
                                              );
                                            }
                                          } else if (
                                            weightLimit.carTo === "" &&
                                            weightLimit.bikeTo !== ""
                                          ) {
                                            if (
                                              parseFloat(weightLimit.bikeTo) <
                                              e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "carFrom"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight greater than 'Bike To'",
                                                "carFrom"
                                              );
                                            }
                                          } else {
                                            handleWeightOnChange(
                                              e.target.value,
                                              0,
                                              "",
                                              "carFrom"
                                            );
                                          }
                                        }}
                                      />
                                      {errorWeightLimit.carFrom.val === 1 && (
                                        <p className="val-error">
                                          {errorWeightLimit.carFrom.msg}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                                    <div className="field-coulmn">
                                      <input
                                        className="field-input"
                                        type="number"
                                        name="carWeightTo"
                                        value={weightLimit.carTo}
                                        placeholder={t("Weight To")}
                                        onChange={(e) => {
                                          setWeightLimit({
                                            ...weightLimit,
                                            carTo: e.target.value,
                                          });
                                        }}
                                        onBlur={(e) => {
                                          if (
                                            weightLimit.truckFrom !== "" &&
                                            weightLimit.carFrom !== ""
                                          ) {
                                            if (
                                              parseFloat(
                                                weightLimit.truckFrom
                                              ) > e.target.value &&
                                              parseFloat(weightLimit.carFrom) <
                                                e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "carTo"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight between 'Car From' & 'Truck From'",
                                                "carTo"
                                              );
                                            }
                                          } else if (
                                            weightLimit.truckFrom !== "" &&
                                            weightLimit.carFrom === ""
                                          ) {
                                            if (
                                              parseFloat(
                                                weightLimit.truckFrom
                                              ) > e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "carTo"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight less than 'Truck From'",
                                                "carTo"
                                              );
                                            }
                                          } else if (
                                            weightLimit.truckFrom === "" &&
                                            weightLimit.carFrom !== ""
                                          ) {
                                            if (
                                              parseFloat(weightLimit.carFrom) <
                                              e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "carTo"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight greater than 'Car From'",
                                                "carTo"
                                              );
                                            }
                                          } else {
                                            handleWeightOnChange(
                                              e.target.value,
                                              0,
                                              "",
                                              "carTo"
                                            );
                                          }
                                        }}
                                      />
                                      {errorWeightLimit.carTo.val === 1 && (
                                        <p className="val-error">
                                          {errorWeightLimit.carTo.msg}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              </div>
                              <div className="vehicle-driver">
                                <div className="radio">
                                  <label htmlFor="html">
                                    <span className="field-icn material-icons">
                                      local_shipping
                                    </span>
                                  </label>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mr-25 mb-3">
                                    <div className="field-coulmn mr-2">
                                      <input
                                        className="field-input"
                                        name="pickupWeightFrom"
                                        type="number"
                                        value={weightLimit.truckFrom}
                                        placeholder={t("Weight From")}
                                        onChange={(e) => {
                                          setWeightLimit({
                                            ...weightLimit,
                                            truckFrom: e.target.value,
                                          });
                                        }}
                                        onBlur={(e) => {
                                          if (
                                            weightLimit.truckTo !== "" &&
                                            weightLimit.carTo !== ""
                                          ) {
                                            if (
                                              parseFloat(weightLimit.truckTo) >
                                                e.target.value &&
                                              parseFloat(weightLimit.carTo) <
                                                e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "truckFrom"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight between 'Truck To' & 'Car To'",
                                                "truckFrom"
                                              );
                                            }
                                          } else if (
                                            weightLimit.truckTo !== "" &&
                                            weightLimit.carTo === ""
                                          ) {
                                            if (
                                              parseFloat(weightLimit.truckTo) >
                                              e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "truckFrom"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight less than 'Truck To'",
                                                "truckFrom"
                                              );
                                            }
                                          } else if (
                                            weightLimit.truckTo === "" &&
                                            weightLimit.carTo !== ""
                                          ) {
                                            if (
                                              parseFloat(weightLimit.carTo) <
                                              e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "truckFrom"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight greater than 'Car To'",
                                                "truckFrom"
                                              );
                                            }
                                          } else {
                                            handleWeightOnChange(
                                              e.target.value,
                                              0,
                                              "",
                                              "truckFrom"
                                            );
                                          }
                                        }}
                                      />
                                      {errorWeightLimit.truckFrom.val === 1 && (
                                        <p className="val-error">
                                          {errorWeightLimit.truckFrom.msg}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                                    <div className="field-coulmn">
                                      <input
                                        className="field-input"
                                        name="pickupWeightTo"
                                        type="number"
                                        value={weightLimit.truckTo}
                                        placeholder={t("Weight To")}
                                        onChange={(e) => {
                                          setWeightLimit({
                                            ...weightLimit,
                                            truckTo: e.target.value,
                                          });
                                        }}
                                        onBlur={(e) => {
                                          if (weightLimit.truckFrom !== "") {
                                            if (
                                              parseFloat(
                                                weightLimit.truckFrom
                                              ) < e.target.value
                                            ) {
                                              handleWeightOnChange(
                                                e.target.value,
                                                0,
                                                "",
                                                "truckTo"
                                              );
                                            } else {
                                              handleWeightOnChange(
                                                "",
                                                1,
                                                "Please enter weight greater than 'Truck From'",
                                                "truckTo"
                                              );
                                            }
                                          } else {
                                            handleWeightOnChange(
                                              e.target.value,
                                              0,
                                              "",
                                              "truckTo"
                                            );
                                          }
                                        }}
                                      />
                                      {errorWeightLimit.truckTo.val === 1 && (
                                        <p className="val-error">
                                          {errorWeightLimit.truckTo.msg}
                                        </p>
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      <div className="row new-field right">
                        <div className="col-12">
                          <button
                            onClick={() => saveWeightLimit()}
                            type="button"
                            className="btn top-btn bgcolor1 ripple1 padding-button"
                          >
                            {t("Save")}
                          </button>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
              </Card>
            </form>
          </Row>
        </Row>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Settings;
