import { call, put, takeEvery } from "redux-saga/effects";
import commonService from "../../service/common/CommonService";
import {
  uploadImageSuccess,
  uploadImageError,
  paymentReturnStatusSuccess,
  paymentReturnStatusError,
  fetchSettingsDataSuccess,
  fetchSettingsDataError,
  updatePickupTimeSuccess,
  updatePickupTimeError,
  fetchAllDocMasterSuccess,
  fetchAllDocMasterError,
  updateWeightLimitSuccess,
  updateWeightLimitError,
} from "../common/commonState";

function* uploadImage(action) {
  //const pay = payload.formData;
  const data = yield call(commonService.uploadImage, action.payload);
  if (data.msg === "success") {
    yield put(uploadImageSuccess(data.response));
  } else {
    yield put(uploadImageError(data.response));
  }
}

function* paymentReturnStatus(action) {
  const data = yield call(commonService.paymentReturnStatus, action.payload);
  if (data.msg === "success") {
    yield put(paymentReturnStatusSuccess(data.response));
  } else {
    yield put(paymentReturnStatusError(data.response));
  }
}

function* fetchSettingsData(action) {
  const data = yield call(commonService.fetchSettingsData, action.payload);
  if (data.msg === "success") {
    yield put(fetchSettingsDataSuccess(data.response));
  } else {
    yield put(fetchSettingsDataError(data.response));
  }
}

function* updatePickupTime(action) {
  const data = yield call(commonService.updatePickupTime, action.payload);
  if (data.msg === "success") {
    yield put(updatePickupTimeSuccess(data.response));
  } else {
    yield put(updatePickupTimeError(data.response));
  }
}

function* fetchAllDocMaster(action) {
  const data = yield call(commonService.fetchAllDocMaster, action.payload);
  if (data.msg === "success") {
    yield put(fetchAllDocMasterSuccess(data.response));
  } else {
    yield put(fetchAllDocMasterError(data.response));
  }
}

function* updateWeightLimit(action) {
  const data = yield call(commonService.updateWeightLimit, action.payload);
  if (data.msg === "success") {
    yield put(updateWeightLimitSuccess(data.response));
  } else {
    yield put(updateWeightLimitError(data.response));
  }
}

function* commonSaga() {
  yield takeEvery("common/uploadImage", uploadImage);
  yield takeEvery("common/paymentReturnStatus", paymentReturnStatus);
  yield takeEvery("common/fetchSettingsData", fetchSettingsData);
  yield takeEvery("common/updatePickupTime", updatePickupTime);
  yield takeEvery("common/fetchAllDocMaster", fetchAllDocMaster);
  yield takeEvery("common/updateWeightLimit", updateWeightLimit);
}

export default commonSaga;
