// import axios from "axios";
// const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
import axiosInstance from "../ApiHelper";

class vendorService {
  constructor(callback) {
    this.callback = callback;
  }

  async createVendor(payload) {
    const response = await axiosInstance
      .post(`vendor/create`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async updateVendor(payload) {
    const response = await axiosInstance
      .put(`vendor/${payload.vendorId}`, payload.data)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async listVendor(payload) {
    const response = await axiosInstance
      .post(`vendors`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async deleteVendor(payload) {
    const response = await axiosInstance
      .delete(`vendor/${payload}`)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async changePassword(payload) {
    const response = await axiosInstance
      .post(`settings/password`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async profileVendor() {
    const response = await axiosInstance
      .get(`admin/profile`)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }

  async resetPackageAmount(payload) {
    console.log("service", payload);
    const response = await axiosInstance
      .post(`vendor/reset`, payload)
      .then((response) => {
        return { response: response.data, msg: "success" };
      })
      .catch((error) => {
        if (error.response) {
          return { response: error.response, msg: "error" };
        } else {
          return { response: "Network Error", msg: "error" };
        }
      });
    return response;
  }
}

export default new vendorService();
