import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";
import { walletRechargeOnline } from "../../store/wallet/walletState";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const WalletRecharge = ({ rechargeModal, setRechargeModal }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(0);
  const toggle = () => setRechargeModal(!rechargeModal);
  const dispatch = useDispatch();

  let rechargeOnline = useSelector((state) => state.Wallet.rechargeWallet);

  useEffect(() => {
    if (
      Object.keys(rechargeOnline).length > 0 &&
      rechargeOnline.success === true &&
      amount &&
      amount !== 0 &&
      amount !== undefined
    ) {
      window.location.assign(rechargeOnline.url);
    }
  }, [rechargeOnline]);

  const handleOnPaymentClick = () => {
    if (amount && amount !== 0 && amount !== undefined) {
      // if (amount >= 200 && amount <= 5000000) {
      let url = window.location.href.replace(
        window.location.pathname,
        "/redirect_url/wallet"
      );
      let amnt = parseFloat(amount);
      let amt = amnt.toFixed(2).toString();
      // console.log(amt);
      dispatch(walletRechargeOnline({ amount: amt, redirectUrl: url }));
      toggle();
      // } else {
      //   setErrorMessage(3);
      // }
    } else {
      setErrorMessage(1);
    }
  };

  return (
    <Modal isOpen={rechargeModal} className="recharge-modal" toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('Recharge your wallet')}</ModalHeader>
      <ModalBody>
        <div className="modal-cntnt">
          <div className="row">
            {/* <div>
              <p className="font-1">Enter amount in multiple of 100 below</p>
            </div> */}
            <div className="row new-field">
              <div className="padding-left-zero col-sm-12 col-md-12">
                <InputGroup>
                  <InputGroupText className="input-group-text">
                    QAR
                  </InputGroupText>
                  <Input
                    type="number"
                    value={amount}
                    // max={5000000}
                    // min={200}
                    className="price-input"
                    onChange={(e) => {
                      if (e.target.value !== "" && e.target.value !== 0) {
                        let multiple =
                          e.target.value.search(/\s*\d+0{2}\s*$/) === -1
                            ? false
                            : true;
                        multiple === true
                          && setErrorMessage(0)
                          // : setErrorMessage(2);
                      } else {
                        setErrorMessage(0);
                      }
                      setAmount(e.target.value);
                    }}
                  />
                </InputGroup>
                {errorMessage === 1 && <span>Please Enter Amount</span>}
                {/* {errorMessage === 2 && (
                  <span>Please enter amount in multiple of 100</span>
                )} */}
                {/* {errorMessage === 3 && (
                    <span>
                      Minimum recharge value is QAR 200. Maximum is QAR50,00,000
                    </span>
                  )} */}
              </div>
            </div>
            {/* <div>
                <p className="minimumn-charge">
                  Note : Minimum recharge value is QAR 200. Maximum is
                  QAR50,00,000
                </p>
                <br />
                Or select from below
              </div> */}
            <div className="row new-field">
              <div className="chip-class padding-left-zero col-sm-12 col-md-12">
                <Button
                  color="secondary"
                  className="btn-chip"
                  outline
                  onClick={() => setAmount(500)}
                >
                  QAR500
                </Button>{" "}
                <Button
                  color="secondary"
                  className="btn-chip"
                  outline
                  onClick={() => setAmount(1000)}
                >
                  QAR1000
                </Button>{" "}
                <Button
                  color="secondary"
                  className="btn-chip"
                  outline
                  onClick={() => setAmount(2500)}
                >
                  QAR2500
                </Button>{" "}
                <Button
                  color="secondary"
                  className="btn-chip"
                  outline
                  onClick={() => setAmount(5000)}
                >
                  QAR5000
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          className="bgcolor1"
          onClick={() => handleOnPaymentClick()}
        >
          {t('CONTINUE TO PAYMENT')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default WalletRecharge;
